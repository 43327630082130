import "date-fns";
import debounce from "lodash.debounce";
import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  useTheme,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import disableCheck from "../../../Utils/disableCheck.js";
import CustomModal from "../../../components/Modal/Modal.js";
import { dateFormat } from "../../../Utils/dateFormate.js";

import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import { InsuranceService } from "../../../services/insurance.service.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import SureToAdd from "../Alerts/SureToAdd/SureToAdd.js";
import ChooseWebLogins from "../ChooseWebLogins/ChooseWebLogins.js";
import EditWebLogin from "../EditWebLogin/EditWebLogin.js";
import AddDocument from "../AddDocument/AddDocument.js";
import EditDocument from "../EditDocument/EditDocument.js";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { getDateIgnoreTimezone } from "../../../Utils/dateHelper.js";
import { upperCaseErrorKeys } from "../../../Utils/stringManipulation.js";
import { DocumentService } from "../../../services/document.service.js";
import PasswordVerification from "../PasswordVerification/PasswordVerification.js";
import FileViewer from "../../../components/FileViewer/FileViewer.js";
import SpinnerModal from "../Alerts/SpinnerModal/SpinnerModal.js";
import AlertPopup from "../Alerts/Generic/GenericAlert.js";
import _default from "../../../config/default.js";
import AddContact from "../AddContact/AddContact.js";
import AddNewContact from "../AddNewContact/AddNewContact.js";

const provider = [
  "Aetna Group",
  "Blue Cross Blue Shield of Florida Group",
  "Blue Cross Blue Shield of Massachusetts Group",
  "Blue Cross Blue Shield of Michigan Group",
  "Blue Cross Blue Shield of New Jersey Group",
  "California Physicians' Service",
  "Cambia Health Solutions Inc.",
  "Carefirst Inc. Group",
  "Centene Corp. Group",
  "Cigna Health Group",
  "Coventry Corp. Group",
  "HCSC Group",
  "HIP Insurance Group",
  "Health Net of California, Inc.",
  "Highmark Group",
  "Humana Group",
  "Independence Blue Cross Group",
  "Kaiser Foundation Group",
  "Lifetime Healthcare Group",
  "Metropolitan Group",
  "Molina Healthcare Inc. Group",
  "UHC of California",
  "Unitedhealth Group",
  "Wellcare Group",
  "Wellpoint Inc. Group",
  "Other",
];
const timing = [
  { show: "1 week from expiration", value: "one_week" },
  { show: "2 weeks from expiration", value: "two_weeks" },
  { show: "1 month from expiration", value: "one_month" },
  { show: "3 months from expiration", value: "three_months" },
  { show: "6 months from expiration", value: "six_months" },
];

const frequancy = [
  { show: "Daily", value: "daily" },
  { show: "Every other Day", value: "every_other_day" },
  { show: "Weekly", value: "weekly" },
  { show: "Biweekly", value: "bi_weekly" },
];
const planType = ["Primary Health Insurance", "Supplementary Health Insurance"];

const stateArray = [
  "Bi-Weekly",
  "Monthly",
  "Quarterly",
  "Semi-Annually",
  "Annually",
];
const relation = [
  "Mother",
  "Father",
  "Brother",
  "Sister",
  "Grandfather (paternal)",
  "Grandfather (maternal)",
  "Grandmother (paternal)",
  "Grandmother (maternal)",
  "Son",
  "Daughter",
  "Aunt (paternal)",
  "Aunt (maternal)",
  "Uncle (paternal)",
  "Uncle (maternal)",
  "Niece (paternal)",
  "Niece (maternal)",
  "Nephew (paternal)",
  "Nephew (maternal)",
  "Cousin (paternal)",
  "Cousin (maternal)",
];

const primaryInsured = ["Self", "Other"];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles(styles);

function EditHealthInsurance(props) {
  const [editContact, setEditContact] = React.useState(false);
  const [addContact, setAddContact] = React.useState(false);
  const [contact, setContact] = React.useState(
    (props?.healthInsurance?.contacts && props?.healthInsurance?.contacts[0]) ||
      null
  );
  const classes = useStyles();
  const errRef = useRef(null);
  const [radioCheck, setRadioCheck] = useState(
    props?.healthInsurance?.active === true ? "active" : "inactive"
  );

  const insuranceService = new InsuranceService();
  const [err, setErr] = React.useState([]);
  const [link, setLink] = React.useState(null);
  const [showDoc, setShowDoc] = React.useState(false);
  const [id, setId] = React.useState(null);
  const [spinner, setSpinner] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [other, setOther] = useState(
    provider.includes(props?.healthInsurance?.provider) ? false : true
  );
  const [alert, setAlert] = React.useState({
    open: false,
    type: null,
    btnText: null,
    cancelText: null,
    heading: null,
    message: null,
  });
  const [password, setPassword] = React.useState(null);
  const [showPasswordVerification, setShowPasswordVerification] =
    React.useState(false);
  const [documentModal, setDocumentModal] = React.useState(false);
  const [editDoc, setEditDoc] = React.useState(false);
  const [currentDocument, setCurrentDocument] = React.useState(null);
  const obj = props?.healthInsurance?.associated_documents_attributes
    ? props?.healthInsurance?.associated_documents_attributes
    : [];
  const [doc, setDoc] = React.useState(
    props?.healthInsurance?.documents ? props?.healthInsurance?.documents : []
  );
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [editWebLogin, setEditWebLogin] = React.useState(false);
  const [webLogin, setWebLogin] = React.useState(
    (props?.healthInsurance?.web_logins &&
      props?.healthInsurance?.web_logins[0]) ||
      null
  );
  const [webLoginModal, setWebLoginModal] = React.useState(false);
  const [reminder, setReminder] = React.useState(
    props?.healthInsurance?.alert_attributes?.status === "active"
  );
  const [data, setData] = React.useState({
    associated_documents_attributes: obj,
    provider: props?.healthInsurance?.provider,
    relation: props?.healthInsurance?.relation,
    insurance_status: props?.healthInsurance?.insurance_status,
    social_number:
      props?.healthInsurance?.social_number ||
      props?.healthInsurance?.social_number_ciphertext,
    plan_type: props?.healthInsurance?.plan_type,
    ...(props?.healthInsurance?.alert_attributes && {
      alert_attributes: {
        timing: props?.healthInsurance?.alert_attributes?.timing,
        id: props?.healthInsurance?.alert_attributes?.id,
        frequency: props?.healthInsurance?.alert_attributes?.frequency,
        status: props?.healthInsurance?.alert_attributes?.status,
        profile_id: props?.profile?.profileInfo?.profile.id,
      },
    }),
    description: props?.healthInsurance?.description,
    active: props?.healthInsurance?.active,
    start_date: props?.healthInsurance?.start_date
      ? props?.healthInsurance?.start_date?.replaceAll("-", "/")
      : null,
    expiration_date: props?.healthInsurance?.end_date
      ? props?.healthInsurance?.end_date?.replaceAll("-", "/")
      : null,
    plan_id: props?.healthInsurance?.plan_id,
    member_id: props?.healthInsurance?.member_id,
    group_id: props?.healthInsurance?.group_id,
    premium: props?.healthInsurance?.premium
      ?.replaceAll("$", "")
      ?.replaceAll(",", ""),
    premium_frequency: props?.healthInsurance?.premium_frequency,
    primary: props?.healthInsurance?.primary,
    company_name: props?.healthInsurance?.company_name,
    payer_id: props?.healthInsurance?.payer_id,
    prescription_plan: props?.healthInsurance?.prescription_plan,
    notes: props?.healthInsurance?.notes,
    rx_bin: props?.healthInsurance?.rx_bin,
    rx_pcn: props?.healthInsurance?.rx_pcn,
    rx_group: props?.healthInsurance?.rx_group,
    associated_logins_attributes: [
      {
        web_login_id:
          props?.healthInsurance?.web_logins &&
          props?.healthInsurance?.web_logins[0]?.id,
        id:
          props?.healthInsurance?.associated_logins &&
          props?.healthInsurance?.associated_logins[0]?.id,
        _destroy: 0,
      },
    ],
    insurance_contacts_attributes: [
      {
        contact_id:
          props?.healthInsurance?.contacts &&
          props?.healthInsurance?.contacts[0]?.id,
        id:
          props?.healthInsurance?.insurance_contacts &&
          props?.healthInsurance?.insurance_contacts[0]?.id,
        _destroy: 0,
      },
    ],
  });
  const [sureToAdd, setSureToAdd] = React.useState({ open: false, str: null });

  const handleOpenSureToAdd = (str) => {
    setSureToAdd({ open: true, str: str ?? null });
  };
  const handleCloseSureToAdd = () => {
    if (sureToAdd.str !== null) {
      props?.handleClose();
    }
    setSureToAdd({ open: false, str: null });
  };

  const handleSubmit = () => {
    handleCloseSureToAdd();
    if (
      !data.associated_logins_attributes?.[0]?.web_login_id &&
      data.associated_logins_attributes?.[0]?._destroy === 0
    ) {
      delete data["associated_logins_attributes"];
    }
    if (
      !data.insurance_contacts_attributes?.[0]?.contact_id &&
      data.insurance_contacts_attributes?.[0]?._destroy === 0
    ) {
      delete data["insurance_contacts_attributes"];
    }
    const temp = data?.associated_documents_attributes?.filter(
      (e) => e.document_id !== null
    );
    if (props?.wellness?.associated_documents_attributes) {
      setData({ ...data, associated_documents_attributes: temp });
    } else {
      if (temp && temp.length > 0) {
        setData({ ...data, associated_documents_attributes: temp });
      } else {
        let temp2 = delete data["associated_documents_attributes"];
        setData(data);
      }
    }
    let payload = { ...data };
    if (payload.start_date) {
      payload = {
        ...payload,
        start_date: getDateIgnoreTimezone(new Date(payload.start_date))
          .toISOString()
          .slice(0, 10),
      };
    }
    if (payload.expiration_date) {
      payload = {
        ...payload,
        expiration_date: getDateIgnoreTimezone(
          new Date(payload.expiration_date)
        )
          .toISOString()
          .slice(0, 10),
      };
    }
    setIsLoading(true);
    insuranceService
      .editHealthInsurance(payload, props?.healthInsurance?.id)
      .then((response) => {
        setIsLoading(false);
        props.forceRerender();
        props.handleClose();

        console.log(response.data);
      })
      .catch((err) => {
        errRef.current.scrollIntoView();
        setIsLoading(false);
        if (err.response && err.response.data.message) {
          if (err.response?.data?.message === "Please enter a valid date") {
            setErr([err.response?.data?.message]);
          } else {
            let array = [];
            Object.keys(err.response.data.message).forEach((key) => {
              const arr = err.response.data.message[key];
              arr.forEach((val) => {
                array.push(`${key} ${val}`);
              });
            });
            setErr(array);
          }
        } else {
          setErr(["Internal Server Error"]);
        }
      });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChange = debounce((event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  }, 300);

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChangeCountry = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const documentService = new DocumentService();

  const show = (docId) => {
    const documentId = docId ?? id;
    setSpinner(true);
    documentService
      .show(documentId, password)
      .then((res) => {
        setSpinner(false);
        setLink({
          attachment: res.data.document.attachment,
          contentType: res.data.document.content_type,
        });
        setShowDoc(true);
        setShowPasswordVerification(false);
        setPassword(null);
      })
      .catch((err) => {
        setSpinner(false);
        setPassword(null);
        setAlert({
          type: "Error",
          open: true,
          cancelText: "CANCEL",
          heading:
            err?.response && err?.response?.status === 422
              ? "Unauthorized!"
              : "Error!",
          message:
            err?.response && err?.response?.status === 422
              ? "Wrong password! please try again."
              : "An unknown error occurred, please try later.",
        });
      });
  };

  const initialRef = useRef(null);
  React.useEffect(() => {
    initialRef.current = data;
  }, []);

  return (
    <>
      {console.log({ data })}
      <CustomModal
        visible={props.open}
        onClose={() => {
          !disableCheck(initialRef, data)
            ? handleOpenSureToAdd("exit")
            : props?.handleClose();
        }}
        width="35%"
        inset="15px!important"
      >
        {editContact && (
          <AddContact
            association={true}
            edit={true}
            open={editContact}
            setContact={setContact}
            handleClose={() => setEditContact(false)}
            contacts={contact}
          />
        )}
        {addContact && (
          <AddNewContact
            editContact={props?.healthInsurance?.contacts ? true : false}
            data={data}
            setData={setData}
            contact={contact}
            setContact={setContact}
            open={addContact}
            handleClose={() => setAddContact(false)}
            closeModal={() => setAddContact(false)}
          />
        )}
        {alert.open && (
          <AlertPopup
            btnText={alert.btnText}
            cancelText={alert.cancelText}
            open={alert.open}
            type={alert.type}
            heading={alert.heading}
            message={alert.message}
            isLoading={false}
            handleClose={() => {
              setAlert({
                open: false,
                btnText: null,
                cancelText: null,
                heading: null,
                message: null,
                type: null,
              });
            }}
            handleClick={() => {
              setAlert({
                open: false,
                btnText: null,
                cancelText: null,
                heading: null,
                message: null,
                type: null,
              });
            }}
          />
        )}
        {spinner && <SpinnerModal open={spinner} msg={"Loading"} />}
        {showDoc && (
          <FileViewer
            contentType={link?.contentType}
            link={link?.attachment}
            open={showDoc}
            handleClose={() => setShowDoc(false)}
          />
        )}
        {showPasswordVerification && (
          <PasswordVerification
            open={showPasswordVerification}
            handleClose={() => {
              setShowPasswordVerification(false);
              setId(null);
            }}
            handleChange={(e) => setPassword(e)}
            handleSubmit={() => {
              show();
            }}
          />
        )}
        <ModalHeader
          closeModal={() => {
            !disableCheck(initialRef, data)
              ? handleOpenSureToAdd("exit")
              : props?.handleClose();
          }}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={`Edit ${data.company_name || "Health Insurance"}`}
          color={color.white}
        />
        <div className={classes.modalBody} ref={errRef}>
          <div className="w-100 d-flex flex-row mb-4">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              {"Insurance > Health Insurance > Edit"}
            </Typography>
          </div>
          {err?.length > 0 && (
            <div className="w-100 d-flex flex-column mb-4">
              {err?.map((val, key) => {
                return (
                  <Typography
                    key={key}
                    variant="p"
                    component="p"
                    align="left"
                    style={{
                      color: "red",
                      fontWeight: "normal",
                      marginBottom: "2px",
                    }}
                  >
                    {val.charAt(0).toUpperCase() + val.slice(1)}
                  </Typography>
                );
              })}
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            {editDoc && (
              <EditDocument
                password_protected={currentDocument?.password_protected}
                docs={doc}
                category={currentDocument?.category}
                id={currentDocument.id}
                title={currentDocument.title}
                open={editDoc}
                setDocs={setDoc}
                handleClose={() => setEditDoc(false)}
              />
            )}
            {documentModal && (
              <AddDocument
                setData={setData}
                setDoc={setDoc}
                data={data}
                associated_documents_attributes={
                  props?.healthInsurance?.associated_documents_attributes
                    ? true
                    : false
                }
                doc={doc}
                open={documentModal}
                handleClose={() => setDocumentModal(false)}
                closeModal={() => setDocumentModal(false)}
                category={"Personal"}
              />
            )}
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Provider *"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  value={
                    other
                      ? "Other"
                      : data.provider
                      ? data.provider
                      : "Select Provider"
                  }
                  onChange={(e) => {
                    if (e.target.value === "Other") {
                      setOther(true);
                      setData({ ...data, provider: "" });
                    } else {
                      setOther(false);
                      setData({ ...data, provider: e.target.value });
                    }
                  }}
                  name="provider"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  <MenuItem disabled value={"Select Provider"}>
                    Select Provider
                  </MenuItem>
                  {provider?.map((state, index) => {
                    return (
                      <MenuItem
                        value={state}
                        key={index}
                        style={getStyles(name, personName, theme)}
                      >
                        {state}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {other && (
                <FormInput
                  name="provider"
                  onChange={handleChange}
                  label=""
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  placeholder={"Other provider"}
                  value={data.provider}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Plan Type"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  // value={data?.premium_frequency}
                  value={data?.plan_type || "Select Plan Type"}
                  onChange={handleChange}
                  name="plan_type"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  <MenuItem disabled value={"Select Plan Type"}>
                    Select Plan Type
                  </MenuItem>
                  {planType?.map((state, index) => {
                    return (
                      <MenuItem
                        value={state}
                        key={index}
                        style={getStyles(name, personName, theme)}
                      >
                        {state}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Description"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="description"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                value={data?.description}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Insurance Status"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  name="insurance_status"
                  value={data.insurance_status ? "current" : "previous"}
                  onChange={(e) => {
                    setData({
                      ...data,
                      insurance_status:
                        e.target.value === "current" ? true : false,
                    });
                  }}
                  className="flex-row"
                >
                  <FormControlLabel
                    value={"current"}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Current"
                  />
                  <FormControlLabel
                    value={"previous"}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="previous"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Status"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="active"
                  name="active"
                  value={radioCheck}
                  onChange={(e) => {
                    setData({
                      ...data,
                      active: e.target.value === "active" ? true : false,
                    });
                    setRadioCheck(
                      e.target.value === "active" ? "active" : "inactive"
                    );
                  }}
                  className="flex-row"
                >
                  <FormControlLabel
                    value="active"
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Active"
                  />
                  <FormControlLabel
                    value="inactive"
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Inactive"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Start Date"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  inputVariant="outlined"
                  format={dateFormat()}
                  id="start_date"
                  placeholder={dateFormat()?.toUpperCase()}
                  value={data?.start_date}
                  onChange={(date) => {
                    setData({
                      ...data,
                      start_date: date,
                    });
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"End Date"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  inputVariant="outlined"
                  format={dateFormat()}
                  id="start_date"
                  placeholder={dateFormat()?.toUpperCase()}
                  value={data?.expiration_date}
                  onChange={(date) => {
                    setData({
                      ...data,
                      expiration_date: date,
                    });
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
              {props?.preference?.is_active && (
                <>
                  {!reminder && (
                    <CustomButton
                      btnText={"Add Reminder"}
                      disabled={!data?.expiration_date}
                      textAlign="center"
                      display={"block"}
                      background="transparent"
                      hoverBg="#0042a8"
                      border="1px solid #0042a8"
                      borderRadius="8px"
                      color="#0042a8"
                      onClick={() => {
                        setReminder(true);
                        setData({
                          ...data,
                          alert_attributes: {
                            ...(data.alert_attributes
                              ? {
                                  ...data.alert_attributes,
                                }
                              : {
                                  timing: "one_week",
                                  frequency: "daily",

                                  profile_id:
                                    props?.profile?.profileInfo?.profile.id,
                                }),
                            status: "active",
                          },
                        });
                      }}
                    />
                  )}
                </>
              )}
            </Grid>
            {reminder && (
              <div className={"w-75 m-2 border m-5"}>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Start Date"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8} sm={8}>
                  <FormControl id="security-question" fullWidth={true}>
                    <Select
                      id="id_type"
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      value={
                        timing.filter(
                          (i) => i.value === data?.alert_attributes?.timing
                        )[0]?.value || props?.preference?.timing
                      }
                      required={true}
                      onChange={(e) => {
                        setData({
                          ...data,
                          alert_attributes: {
                            ...data.alert_attributes,
                            timing: e.target.value,
                          },
                        });
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled>Select Timing</MenuItem>
                      {timing.map((t) => {
                        return <MenuItem value={t.value}>{t.show}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Frequency"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8} sm={8}>
                  <FormControl id="security-question" fullWidth={true}>
                    <Select
                      id="id_type"
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      value={
                        frequancy.filter(
                          (i) => i.value === data?.alert_attributes?.frequency
                        )[0]?.value || props?.preference?.frequency
                      }
                      required={true}
                      onChange={(e) => {
                        setData({
                          ...data,
                          alert_attributes: {
                            ...data.alert_attributes,
                            frequency: e.target.value,
                          },
                        });
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled>Select Frequency</MenuItem>
                      {frequancy.map((f) => {
                        return <MenuItem value={f.value}>{f.show}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <CustomButton
                    textAlign="center"
                    display={"block"}
                    padding="8px 25px"
                    margin={"5px"}
                    background="#0042a8"
                    hoverBg="#0042a8"
                    border="0"
                    borderRadius="8px"
                    color="#fff"
                    btnText={"Remove"}
                    onClick={() => {
                      setData({
                        ...data,
                        alert_attributes: {
                          ...data.alert_attributes,
                          status: "inactive",
                        },
                      });
                      setReminder(false);
                    }}
                  />
                </Grid>
              </div>
            )}
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Plan ID"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="plan_id"
                onChange={handleChange}
                label=""
                type="number"
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                value={data?.plan_id}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Member ID"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="member_id"
                onChange={handleChange}
                label=""
                variant="outlined"
                type="number"
                fullWidth={true}
                margin="dense"
                required={true}
                value={data?.member_id}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Group ID"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="group_id"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                type="number"
                margin="dense"
                required={true}
                value={data?.group_id}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Premium"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="premium"
                onChange={handleChange}
                label=""
                variant="outlined"
                type="number"
                fullWidth={true}
                margin="dense"
                required={true}
                value={data?.premium?.replace("$", "")?.replace(",", "")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Premium Frequency"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  value={data?.premium_frequency || "Select Premium Frequency"}
                  onChange={handleChange}
                  name="premium_frequency"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  <MenuItem disabled value={"Select Premium Frequency"}>
                    Select Premium Frequency
                  </MenuItem>
                  {stateArray?.map((state, index) => {
                    return (
                      <MenuItem
                        value={state}
                        key={index}
                        style={getStyles(name, personName, theme)}
                      >
                        {state}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Primary Insured"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  value={
                    !data.primary
                      ? "Select Primary Insured"
                      : data.primary === "Self" ||
                        data.primary === "New Profile"
                      ? data.primary
                      : "Other"
                  }
                  onChange={handleChange}
                  name="primary"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  <MenuItem disabled value={"Select Primary Insured"}>
                    Select Primary Insured
                  </MenuItem>
                  {primaryInsured?.map((state, index) => {
                    return (
                      <MenuItem
                        value={state}
                        key={index}
                        style={getStyles(name, personName, theme)}
                      >
                        {state}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {data?.primary !== "Self" &&
              data?.primary !== "New Profile" &&
              data?.primary !== "" && (
                <>
                  <Grid item xs={12} sm={12} md={12} className="pb-0">
                    <Typography
                      variant="h6"
                      component="h3"
                      style={{ color: color.themeColorNew }}
                    >
                      {"Other"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <FormInput
                      name="primary"
                      onChange={(e) =>
                        setData({
                          ...data,
                          primary: e.target.value ? e.target.value : "nothing",
                        })
                      }
                      label=""
                      variant="outlined"
                      fullWidth={true}
                      value={data?.primary !== "" && data?.primary}
                      margin="dense"
                      required={true}
                      placeholder={"Specify Other Primary Insured"}
                    />
                  </Grid>
                </>
              )}
            {/* {data.primary && data?.primary !== "Self" ? (
              <>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Relation"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl id="security-question" fullWidth={true}>
                    <Select
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      required={true}
                      value={data?.relation}
                      onChange={handleChange}
                      name="relation"
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      MenuProps={MenuProps}
                    >
                      <MenuItem disabled>Select Relation</MenuItem>
                      {relation?.map((state, index) => {
                        return (
                          <MenuItem
                            value={state}
                            key={index}
                            style={getStyles(name, personName, theme)}
                          >
                            {state}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            ) : null} */}
            {/* {data?.primary !== "Self" &&
            data?.primary !== "New Profile" &&
            data?.primary !== "" ? (
              <>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Primary Insured SSN"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormInput
                    name="social_number"
                    onChange={handleChange}
                    label=""
                    variant="outlined"
                    fullWidth={true}
                    value={data?.social_number}
                    margin="dense"
                    required={true}
                    placeholder={"Primary Insured SSN"}
                  />
                </Grid>
              </>
            ) : null} */}
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Employer Name"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                id="class"
                name="company_name"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                value={data?.company_name}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Payer ID"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                id="class"
                name="payer_id"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                value={data?.payer_id}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Prescription plan"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="prescription_plan"
                  name="prescription_plan"
                  value={data?.prescription_plan}
                  onChange={(e) =>
                    setData({
                      ...data,
                      prescription_plan:
                        e.target.value === "false" ? false : true,
                    })
                  }
                  className="flex-row"
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {data?.prescription_plan === true && (
              <>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Rx BIN"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormInput
                    name="rx_bin"
                    onChange={handleChange}
                    label=""
                    variant="outlined"
                    fullWidth={true}
                    value={data.rx_bin}
                    margin="dense"
                    required={true}
                    placeholder={"Rx Bin"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Rx PCN"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormInput
                    name="rx_pcn"
                    onChange={handleChange}
                    label=""
                    variant="outlined"
                    fullWidth={true}
                    value={data.rx_pcn}
                    margin="dense"
                    required={true}
                    placeholder={"Rx PCN"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Rx Group"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormInput
                    name="rx_group"
                    onChange={handleChange}
                    label=""
                    value={data.rx_group}
                    variant="outlined"
                    fullWidth={true}
                    margin="dense"
                    required={true}
                    placeholder={"Rx Group"}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Notes"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                id="class"
                name="notes"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                value={data?.notes}
              />
            </Grid>
            {webLoginModal && (
              <ChooseWebLogins
                setData={setData}
                setWebLogin={setWebLogin}
                data={data}
                open={webLoginModal}
                webLogin={data?.associated_logins_attributes[0]?.web_login_id}
                handleClose={() => setWebLoginModal(false)}
                closeModal={() => setWebLoginModal(false)}
                category={"Personal"}
              />
            )}
            {editWebLogin && (
              <EditWebLogin
                open={editWebLogin}
                handleClose={() => setEditWebLogin(false)}
                webLogin={webLogin}
              />
            )}
            {editWebLogin && (
              <EditWebLogin
                open={editWebLogin}
                handleClose={() => setEditWebLogin(false)}
                webLogin={webLogin}
              />
            )}
            {webLogin && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Web Login"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {webLogin?.user_name}
                  </Typography>
                  <CustomButton
                    btnText={"Remove"}
                    textAlign="center"
                    display={"block"}
                    background="transparent"
                    hoverBg="#0042a8"
                    border="1px solid #0042a8"
                    borderRadius="8px"
                    color="#0042a8"
                    onClick={() => {
                      setWebLogin(null);
                      setData({
                        ...data,
                        associated_logins_attributes: [
                          {
                            web_login_id:
                              data.associated_logins_attributes[0].web_login_id,
                            id: data.associated_logins_attributes[0].id,
                            _destroy: 1,
                          },
                        ],
                      });
                    }}
                  />
                  <CustomButton
                    btnText={"Edit"}
                    textAlign="center"
                    marginLeft="8px"
                    display={"block"}
                    background="transparent"
                    hoverBg="#0042a8"
                    border="1px solid #0042a8"
                    borderRadius="8px"
                    color="#0042a8"
                    onClick={() => {
                      setEditWebLogin(true);
                    }}
                  />
                </Grid>
              </>
            )}
            {contact && (
              <div className={"w-75 m-2 border m-5 p-4"}>
                <Typography
                  variant="h6"
                  component="h3"
                  style={{
                    color: color.themeColorNew,
                    textAlign: "center",
                    padding: "1rem",
                  }}
                >
                  {"Agent"}
                </Typography>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{
                      color: color.themeColorNew,
                    }}
                  >
                    {"Name"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{
                      color: color.themeColorNew,
                    }}
                  >
                    {contact?.firstName + " " + contact?.lastName}
                  </Typography>
                </Grid>
                {contact?.contact_phone_numbers?.map((p, index) => {
                  return (
                    <>
                      <Grid item xs={12} sm={12} md={12} className="pb-0">
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{
                            color: color.themeColorNew,
                          }}
                        >
                          {`Phone Number (${p.phone_type})`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12} sm={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{
                            color: color.themeColorNew,
                          }}
                        >
                          {p?.country_code + p?.phone_number}
                        </Typography>
                      </Grid>
                    </>
                  );
                })}
                <div style={{ textAlign: "center", margin: "1rem" }}>
                  <CustomButton
                    btnText={"Remove"}
                    textAlign="center"
                    display={"block"}
                    background="transparent"
                    hoverBg="#0042a8"
                    border="1px solid #0042a8"
                    borderRadius="8px"
                    color="#0042a8"
                    onClick={() => {
                      setContact(null);
                      setData({
                        ...data,
                        insurance_contacts_attributes: [
                          {
                            id: data.insurance_contacts_attributes[0].id,
                            contact_id:
                              data.insurance_contacts_attributes[0].contact_id,
                            _destroy: 1,
                          },
                        ],
                      });
                    }}
                  />
                  <CustomButton
                    btnText={"Edit"}
                    textAlign="center"
                    marginLeft="8px"
                    display={"block"}
                    background="transparent"
                    hoverBg="#0042a8"
                    border="1px solid #0042a8"
                    borderRadius="8px"
                    color="#0042a8"
                    onClick={() => {
                      setEditContact(true);
                    }}
                  />
                </div>
              </div>
            )}
            {doc.length > 0 && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Document"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                  {doc.map((e, k) => (
                    <>
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: color.themeColorNew }}
                        key={k}
                      >
                        {e?.title}
                      </Typography>
                      <CustomButton
                        btnText={"Remove"}
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          let temp = data.associated_documents_attributes?.map(
                            (d) => {
                              if (d.document_id === e.id) {
                                return {
                                  ...d,
                                  _destroy: 1,
                                };
                              } else {
                                return d;
                              }
                            }
                          );
                          let newDoc = doc?.filter((d) => d.id !== e.id);
                          setDoc(newDoc);
                          setData({
                            ...data,
                            associated_documents_attributes: temp,
                          });
                        }}
                      />

                      <CustomButton
                        btnText={"Edit"}
                        className="ms-2"
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          setCurrentDocument(e);
                          setEditDoc(true);
                        }}
                      />
                      <CustomButton
                        btnText={"View"}
                        className="ms-2"
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          if (!props?.healthInsurance?.documents) {
                            show(e?.id);
                          } else {
                            if (e?.attachment) {
                              setLink({
                                attachment: _default.BASE_URL + e.attachment,
                                contentType: e.content_type,
                              });
                              setShowDoc(true);
                            } else {
                              setId(e?.id);
                              setShowPasswordVerification(true);
                            }
                          }
                        }}
                      />
                    </>
                  ))}
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              {!contact && (
                <CustomButton
                  btnText="Add Agent"
                  className="mb-sm-2"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  marginRight={"10px"}
                  onClick={() => setAddContact(true)}
                />
              )}
              <CustomButton
                btnText="Add Document"
                className="mb-sm-2"
                textAlign="center"
                display={"block"}
                padding="8px 25px"
                background="#0042a8"
                hoverBg="#0042a8"
                border="0"
                borderRadius="8px"
                color="#fff"
                marginRight={"10px"}
                onClick={() => setDocumentModal(true)}
              />

              {!webLogin && (
                <CustomButton
                  className="mb-sm-2"
                  btnText="Add Web Login"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  marginRight={"10px"}
                  onClick={() => setWebLoginModal(true)}
                />
              )}

              {!isLoading ? (
                <CustomButton
                  type="submit"
                  btnText="Save"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  onClick={() => handleOpenSureToAdd(null)}
                  disabled={disableCheck(initialRef, data)}
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
      <SureToAdd
        open={sureToAdd.open}
        handleClose={handleCloseSureToAdd}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
    profile: state.profile,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditHealthInsurance);
