import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import styles from "../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authAction";
import { useEffect } from "react";
import Administrators from "./PermissionTabsContent/Collaborator.js";
import IndividualAccess from "./PermissionTabsContent/IndividualAccess.js";
import { PersonalService } from "../../services/personal.service.js";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  useEffect(() => {
  }, [props.profile]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(styles);

const options = ["Disable", "Delete"];

function SimpleTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(1);
  const personalService = new PersonalService();
  const [collaborator, setCollaborater] = React.useState({});
  const [err, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [openDropdown, setOpenDropdown] = useState(false);


  const getData = async () => {
    try {
      const response = await personalService.administratorsPermission();
      setCollaborater(response.data);
      console.log("Response data:", response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error);
    }
  };

   const toggleCollaboratorStatus = async (id, status) => {
     try {
       await personalService.collaborationStatus(id, status);
       
       console.log(
         `Collaborator status updated to ${status ? "active" : "disabled"}`
        );
      } catch (error) {
        console.error("Error updating collaborator status:", error);
      }
      finally {
        setLoading(false)
      }
   };

   const deleteCollaborator = async (id) => {
      setLoading(true);
     try {
       await personalService.collaboratorDelete(id);
       await getData()
     } catch (error) {
       console.error("Error on deleting collaborator:", error);
     }
     finally{
      setLoading(false)
     }
   };

  useEffect(() => {
      getData()
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseDropdown = (event) => {
    // Ensure the click is outside the anchor element
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    // Close the dropdown if clicked outside
    setOpenDropdown(false);
  };

  const handleToggle = () => {
    setOpenDropdown((prevOpen) => !prevOpen); // Toggle popper visibility
  };

  return (
    <>
      <AppBar position="static" color="transparent" elevation="0">
        <Tabs
          value={0}
          // onChange={handleChange}
          aria-label="simple tabs example"
          className={classes.tabsMain}
        >
          {/* <Tab label="Administrators" {...a11yProps(0)} /> */}
          <Tab label="Individual Access" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <Grid container wrap="wrap" direction="row">
        <Grid item xs={12} sm={12} md={9} lg={8}>
          <div className={classes.root}>
            {/* <TabPanel value={value} index={0}>
              <Administrators options={options} />
            </TabPanel> */}
            <TabPanel value={1} index={1}>
              <IndividualAccess
                err={err}
                data= {getData}
                loading={loading}
                options={options}
                collaborator={collaborator}
                anchorRef={anchorRef}
                open={openDropdown}
                handleToggle={handleToggle}
                handleCloseDropdown={handleCloseDropdown}
                toggleCollaboratorStatus={(id, status) =>
                  toggleCollaboratorStatus(id, status)
                }
                deleteCollaborator={(id) => deleteCollaborator(id)}
              />
            </TabPanel>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};
const mapStateToProps = (state, ownProps) => {
  return {
    profile: state.profile.profileInfo,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SimpleTabs);
