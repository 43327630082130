import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Grid, Typography } from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close, EditOutlined } from "@material-ui/icons";
// import AvatarGroup from '@mui/material/AvatarGroup';
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import jpegImage from "../../../assets/img/jpegImage.png";
import pdfImage from "../../../assets/img/pdfImage.png";
import { InsuranceService } from "../../../services/insurance.service.js";
import { dateFormat } from "../../../Utils/dateFormate";
import moment from "moment";
import _default from "../../../config/default.js";
import man from "../../../assets/img/man.png";
import female from "../../../assets/img/user.png";

const useStyles = makeStyles(styles);
export default function DisabilityInsuranceInformation(props) {
  const classes = useStyles();
  const insuranceService = new InsuranceService();

  React.useEffect(() => {
    insuranceService
      .disabilityInsuranceShow(props?.disabilityInsurance[0]?.id)
      .then(() => {})
      .catch(() => {});
  }, []);

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%"
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          rightIcon={
            <EditOutlined
              onClick={() =>
                props?.handleOpenEdit(props?.disabilityInsurance[0])
              }
              style={{
                color: color.white,
                marginLeft: "auto",
                fontSize: "36px",
                cursor: "pointer",
              }}
            />
          }
          heading={
            props?.disabilityInsurance[0]?.insurance_providerr
              ? props?.disabilityInsurance[0]?.insurance_providerr
              : "Disability Insurance"
          }
          color={color.white}
        />
        <div className={classes.modalBody}>
          <div className="w-100 d-flex flex-row mb-5">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              {"Insurance > Disability Insurance > Information"}
            </Typography>
          </div>
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Provider"}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.disabilityInsurance[0]?.provider}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Insurance Status"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.disabilityInsurance[0]?.insurance_status
                  ? "Current"
                  : "Previous"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Description"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.disabilityInsurance[0]?.description ? (
                  props?.disabilityInsurance[0]?.description
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Policy Number"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.disabilityInsurance[0]?.policy_number ? (
                  props?.disabilityInsurance[0]?.policy_number
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Start Date"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.disabilityInsurance[0]?.start_date ? (
                  moment(
                    props?.disabilityInsurance[0]?.start_date?.replaceAll(
                      "-",
                      "/"
                    )
                  )?.format(dateFormat()?.toUpperCase())
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"End Date"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.disabilityInsurance[0]?.end_date ? (
                  moment(
                    props?.disabilityInsurance[0]?.end_date?.replaceAll(
                      "-",
                      "/"
                    )
                  )?.format(dateFormat()?.toUpperCase())
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Benefit"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.disabilityInsurance[0]?.benefit ? (
                  props?.disabilityInsurance[0]?.benefit
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"After tax benefit"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.disabilityInsurance[0]?.after_tax_benefit
                  ? "Yes"
                  : "No"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Benefit Duration"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.disabilityInsurance[0]?.benefit_duration ? (
                  props?.disabilityInsurance[0]?.benefit_duration
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Premium"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.disabilityInsurance[0]?.premium ? (
                  props?.disabilityInsurance[0]?.premium
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Premium Frequency"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.disabilityInsurance[0]?.premium_frequency ? (
                  props?.disabilityInsurance[0]?.premium_frequency
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            {props?.disabilityInsurance[0].contacts ? (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Agent Name"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew, fontSize: "18px" }}
                  >
                    {props?.disabilityInsurance[0]?.contacts[0]?.firstName +
                      " " +
                      props?.disabilityInsurance[0]?.contacts[0]?.lastName}
                  </Typography>
                </Grid>
                {props?.disabilityInsurance[0]?.contacts[0]?.contact_phone_numbers?.map(
                  (p, index) => {
                    return (
                      <>
                        <Grid item xs={12} sm={4} md={4}>
                          <Typography
                            variant="h6"
                            component="h3"
                            style={{ color: color.themeColorNew }}
                          >
                            {`Phone Number (${p.phone_type})`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8}>
                          <a
                            href={`tel:${p?.country_code + p?.phone_number}`}
                            style={{
                              color: color.themeColorNew,
                              fontSize: "18px",
                              textDecoration: "none",
                            }}
                          >
                            {p?.phone_number}
                          </a>
                        </Grid>
                      </>
                    );
                  }
                )}
              </>
            ) : null}
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Notes"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.disabilityInsurance[0]?.notes ? (
                  props?.disabilityInsurance[0]?.notes
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            {props?.disabilityInsurance[0].web_logins ? (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Web Login"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew, fontSize: "18px" }}
                  >
                    {props?.disabilityInsurance[0]?.web_logins[0]?.user_name}
                  </Typography>
                </Grid>
              </>
            ) : null}
          </Grid>
          <Grid
            container
            wrap="wrap"
            direction="row"
            spacing={3}
            className="mt-5"
          >
            <Grid item xs={12} sm={6} md={6}>
              {props?.disabilityInsurance[0]?.documents && (
                <>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Linked Items"}
                  </Typography>
                  <div className="d-flex flex-row align-items-center mt-2">
                    <img src={jpegImage} height={40} className="me-2" />
                    <img src={pdfImage} height={40} className="me-2" />
                    <Typography
                      onClick={props.handleOpenLinkedItems}
                      variant="h6"
                      component="h3"
                      align="right"
                      className="text-decoration-underline"
                      style={{
                        color: color.themeColorNew,
                        cursor: "pointer",
                      }}
                    >
                      {"See all"}
                    </Typography>
                  </div>
                </>
              )}
            </Grid>
            {!props?.disabilityInsurance[0]?.collaborated_record && (
  <Grid item xs={12} sm={6} md={6}>
    <Typography
      variant="h6"
      component="h3"
      align="right"
      style={{ color: color.themeColorNew }}
    >
      {"Collaborators"}
    </Typography>
    <div className="d-flex flex-row align-items-center justify-content-end mt-2">
      <Avatar
        src={
          props?.disabilityInsurance[0]?.collaborators
            ?.collaborators[0]?.profile_image
            ? _default.BASE_URL +
              props?.disabilityInsurance[0]?.collaborators
                ?.collaborators[0]?.profile_image
            : props?.disabilityInsurance[0]?.collaborators
                ?.collaborators[0]?.gender === "male"
            ? man
            : female
        }
        alt={"C"}
        width={35}
        style={{
          marginLeft: "",
          borderRadius: 50,
          zIndex: "auto",
        }}
      />
      {/* {props?.data?.collaborated_records_count > 1 && ( */}
      {props?.disabilityInsurance[0]?.collaborators &&
        props?.disabilityInsurance[0]?.collaborators
          ?.collaborators_count > 1 && (
        <Typography
          variant="p"
          component="p"
          align="left"
          style={{
            color: "#000",
            fontWeight: "bold",
            margin: "0 5px",
          }}
        >
          {`+ ${
            props?.disabilityInsurance[0]?.collaborators
              ?.collaborators_count - 1
          }`}
        </Typography>
      )}
      {/* )} */}
      <Typography
        onClick={props.handleOpenCollaborators}
        variant="h6"
        component="h3"
        align="right"
        className="text-decoration-underline"
        style={{ color: color.themeColorNew, cursor: "pointer" }}
      >
        {"See all"}
      </Typography>
    </div>
  </Grid>
)}

          </Grid>
        </div>
      </CustomModal>
    </>
  );
}
