import React, { useEffect, useCallback, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
// @material-ui/core components
// Bootstrap
import { HTML5Backend } from "react-dnd-html5-backend";

import "bootstrap/dist/css/bootstrap.min.css";
// core components
import { useRouteName } from "../../../hooks";
import styles from "../../../assets/jss/material-dashboard-react/components/ActivityHistoryAccordion";
import GridItem from "../../../components/Grid/GridItem.js";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import color from "../../../assets/css/color.js";
// @material-ui/icons
import WebLoginInformation from "../../../containers/Modals/WebLoginInformation/WebLoginInformation";
import LinkedItems from "../../../containers/Modals/LinkedItems/LinkedItems.js";
import Collaborators from "../../../containers/Modals/Collaborators/Collaborators";
import W2Form from "../../../containers/Modals/W2Form/W2Form";
import AddWebLogin from "../../../containers/Modals/AddWebLogin/AddWebLogin";
import { CustomButton } from "../../../components/CustomButton/Button";
import AddContactInfo from "../../../containers/Modals/AddContactInfo/AddContactInfo";
import CircularProgress from "@material-ui/core/CircularProgress";
import { store } from "../../../store/store";
import CardCategory from "../../../components/Card/CardCategory";
import EditWebLogin from "../../../containers/Modals/EditWebLogin/EditWebLogin";
import { PersonalService } from "../../../services/personal.service";
import { connect } from "react-redux";
import CardViewComponent from "../../../components/Card/CardViewComponent";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import AddSection from "../../../containers/Modals/AddSection/AddSection";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SectionIndex from "../../../containers/Modals/AddSection/SectionIndex";
import { useDispatch } from "react-redux";
import { sort } from "../../../store/actions/sortAction";
import SectionEditModal from "../../../containers/Modals/SectionEdit/SectionEditModal";
import { DndProvider } from "react-dnd";
import Droppable from "../../../components/Droppable";
import Draggable from "../../../components/Draggable";
const useStyles = makeStyles(styles);

function WebLogins({ sortType, order }) {
  const childRef = useRef();
  const personalService = new PersonalService();
  const [ids, setIds] = React.useState([]);
  const [multiple, setMultiple] = React.useState(false);
  const [openDropdown, setOpenDropdown] = React.useState(false);
  const [webLogins, setWebLogins] = React.useState([]);
  const [err, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [indexId, setIndexId] = React.useState(null);
  const [edit, setEdit] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [info, setInfo] = React.useState(null);
  const [sections, setSections] = React.useState([]);
const [currId, setCurrId] = React.useState(null);
  const [selectedData, setSelectedData] = useState({});  
  const [currWebLogin, setCurrWebLogin] = React.useState(null);

  const classes = useStyles();
  const dispatch = useDispatch();
  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");

  const group = async (s_id, w_id) => {
    setLoading(true);
    const data = {
      section_id: s_id,
    };
    try {
      let response = await personalService.editWebLogins(data, w_id);
      setLoading(false);
      fetchData();
    } catch (e) {
      setLoading(false);
    }
  };

  const fetchData = () => {
    setLoading(true);
    personalService
      .webLoginsIndex()
      .then((response) => {
        console.log({ response });
        setLoading(false);
        setWebLogins(
          response.data.web_logins && response?.data?.web_logins.length > 0
            ? response.data.web_logins
            : []
        );
        if (
          id &&
          response?.data?.web_logins?.length > 0 &&
          response?.data?.web_logins?.filter((f) => f?.id === +id).length > 0
        ) {
          setInfo(response?.data?.web_logins?.filter((f) => f?.id === +id));
          setOpen(true);
        }
      })
      .then(() => {
        return personalService.sectionsIndex();
      })
      .then((res) => setSections(res.data.sections ?? []))
      .catch((err) => {
        // console.log({ err });
        setLoading(false);
        setError("error occurred");
      });
  };
  React.useEffect(() => {
    fetchData();
  }, [store.getState().loading.render]);

  const handleMenuItemClick = (event) => {
    // setSelectedIndex(index); +
    setOpenDropdown(false);
  };

  const handleToggle = () => {
    setOpenDropdown((prevopenDropdown) => !prevopenDropdown);
  };

  const handleOpenDropDown = (id) => {
    setCurrId(id);
    handleToggle();
  };
  const handleCloseDropdown = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenDropdown(false);
  };

  React.useEffect(() => {
    return () => {
      dispatch(sort("date", "dsc"));
    };
  }, []);

  const routeName = useRouteName();

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openAddNew, setOpenAddNew] = React.useState(false);
  const [openLinkedItems, setOpenLinkedItems] = React.useState(false);
  const [openCollaborators, setOpenCollaborators] = React.useState(false);
  const [openW2Form, setOpenW2Form] = React.useState(false);
  const [openAddContactInfo, setOpenAddContactInfo] = React.useState(false);
  const [addSectionModal, setAddSectionModal] = React.useState(false);
  const [showSections, setShowSections] = React.useState(false);
  const [editSection, setEditSection] = React.useState(false);
  const [sectionName, setSectionName] = React.useState(null);
  const [sectionId, setSectionId] = React.useState(null);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickInfo = (id) => {
    const webLogin = webLogins?.filter((aw) => aw.id === id);
    setInfo(webLogin);
    setWebLogins(
      webLogins?.map((web) => {
        if (web.id === id) {
          return {
            ...web,
            view_count: web.view_count + 1,
          };
        } else {
          return web;
        }
      })
    );
    if (
      sections.length > 0 &&
      webLogins.filter((w) => w.id === id)[0]?.section_id
    ) {
      let array = sections?.map((sec) => {
        if (sec.id === webLogin.filter((web) => web.id === id)[0]?.section_id) {
          let temp = sec.web_logins.map((w) => {
            if (w.id === id) {
              return {
                ...w,
                view_count: w.view_count + 1,
              };
            } else {
              return w;
            }
          });

          return {
            ...sec,
            web_logins: temp,
          };
        } else {
          return sec;
        }
      });
      console.log({ array });
      setSections(array);
    }
    handleOpen();
  };

  const handleOpenAddNew = () => {
    setOpenAddNew(true);
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenW2Form(false);
  };

  const handleOpenEdit = (webLogin) => {
    setEdit(true);
    setOpenEdit(true);
    setCurrWebLogin(webLogin);
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenW2Form(false);
  };

  const handleOpenLinkedItems = () => {
    setOpenLinkedItems(true);
    setOpen(false);
    setOpenCollaborators(false);
    setOpenW2Form(false);
    setOpenAddNew(false);
  };

  const handleOpenCollaborators = () => {
    setOpenCollaborators(true);
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenW2Form(false);
    setOpenAddNew(false);
  };
  const handleSelectedCollaborators = useCallback((dl) => {
    setSelectedData(dl);
  }, []);

  const handleOpenW2Form = () => {
    setOpenW2Form(true);
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenAddNew(false);
  };

  const handleOpenAddContactInfo = () => {
    setOpenAddContactInfo(true);
    setOpenW2Form(false);
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenAddNew(false);
  };

  const handleCloseLinkedItems = () => {
    setOpenLinkedItems(false);
    setOpen(true);
  };

  const handleCloseCollaborators = () => {
    setOpenCollaborators(false);
  };

  const handleCloseW2Form = () => {
    setOpenW2Form(false);
  };

  const handleCloseAddNew = () => {
    setOpenAddNew(false);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setEdit(false);
  };

  const handleCloseAddContactInfo = () => {
    setOpenAddContactInfo(false);
  };

  const closeModal = () => {
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenW2Form(false);
    setOpenAddNew(false);
    setOpenAddContactInfo(false);
  };

  const closeEditModal = () => {
    setOpenEdit(false);
    setEdit(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenW2Form(false);
    setOpenAddNew(false);
    setOpenAddContactInfo(false);
  };

  const handleDelete = (id) => {
    personalService.webLoginDestroy(id).then(() => {
      fetchData();
    });
  };

  const handleFolderDelete = (id, wl) => {
    personalService.destroySectionId(id).then(() => {
      fetchData();
      wl &&
        wl.length > 0 &&
        wl.forEach((ele) => {
          unGroup(ele.id);
        });
    });
  };

  const unGroup = (id) => {
    personalService.editWebLogins({ section_id: null }, id).then(() => {
      fetchData();
    });
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <Grid
        container
        wrap="wrap"
        direction={isMobile ? 'column' : 'row'}
        justifyContent=""
        alignItems={isMobile ? 'flex-start' : 'center'}
        style={{ margin: "25px 0" }}
      >
        <Typography
          variant="h6"
          component="h3"
          style={{ color: color.themeColorNew }}
        >
          Web Logins
        </Typography>
        <CustomButton
          type="submit"
          btnText="Add New"
          textAlign="center"
          display={"block"}
          padding="8px 17px"
          background="#0042a8"
          marginLeft="4rem"
          hoverBg="#0042a8"
          border="0"
          borderRadius="8px"
          color="#fff"
          onClick={() => handleOpenAddNew()}
        />
        <Grid item xs={6} md={6}></Grid>
        <Grid item xs={6} md={6} style={{ textAlign: "right" }}></Grid>
      </Grid>
      <Grid container>
        <GridItem xs={12} sm={12} md={12}>
          {openAddNew && (
            <AddWebLogin
              open={openAddNew}
              handleClose={handleCloseAddNew}
              handleOpenAddContactInfo={handleOpenAddContactInfo}
              closeModal={closeModal}
            />
          )}
          {edit && (
            <EditWebLogin
              open={openEdit}
              handleClose={handleCloseEdit}
              handleOpenAddContactInfo={handleOpenAddContactInfo}
              closeModal={closeEditModal}
              webLogin={currWebLogin}
            />
          )}
          <AddContactInfo
            open={openAddContactInfo}
            handleCloseAddContactInfo={handleCloseAddContactInfo}
            closeModal={closeModal}
          />
          {open && (
            <WebLoginInformation
              open={open}
              handleClose={handleClose}
              handleOpenEdit={handleOpenEdit}
              webLogin={info}
              handleOpenLinkedItems={handleOpenLinkedItems}
              handleOpenCollaborators={handleOpenCollaborators}
              closeModal={closeModal}
            />
          )}
          {addSectionModal && (
            <AddSection
              handleClose={() => setAddSectionModal(false)}
              fetchData={fetchData}
              open={addSectionModal}
            />
          )}
          {editSection && (
            <SectionEditModal
              id={sectionId}
              name={sectionName}
              handleClose={() => {
                setEditSection(false);
                fetchData();
              }}
              open={editSection}
            />
          )}
          {showSections && (
            <SectionIndex
              handleClose={() => setShowSections(false)}
              fetchData={fetchData}
              sections={sections}
              id={indexId}
              open={showSections}
            />
          )}
          <LinkedItems
            attachment={info}
            open={openLinkedItems}
            handleCloseLinkedItems={handleCloseLinkedItems}
            closeModal={closeModal}
            heading={"Web Login"}
            category={"Personal"}
          />
          <Collaborators
            open={openCollaborators}
            handleCloseCollaborators={handleCloseCollaborators}
            closeModal={closeModal}
            data={selectedData}
          />
          <W2Form
            open={openW2Form}
            handleCloseW2Form={handleCloseW2Form}
            handleOpenLinkedItems={handleOpenLinkedItems}
            handleOpenCollaborators={handleOpenCollaborators}
            closeModal={closeModal}
          />
          {webLogins?.length > 0 && (
            <>
              <button
                style={{
                  borderRadius: "16px",
                  border: "none",
                  backgroundColor: "transparent",

                  color: "#0042a8",
                  fontSize: "14px",
                  fontWeight: "500",
                  margin: "4px",
                }}
                onClick={() => {
                  setMultiple(true);
                  let array = [];
                  webLogins?.forEach((e) => array.push(e.id));
                  setIds(array);
                }}
              >
                Select All
              </button>
              <button
                style={{
                  borderRadius: "16px",
                  border: "none",
                  backgroundColor: "transparent",
                  color: "#0042a8",
                  fontSize: "14px",
                  fontWeight: "500",
                  margin: "4px",
                }}
                onClick={() => {
                  setAddSectionModal(true);
                }}
                data-tooltip={
                  "Create a Folder to group types of Web Logins together"
                }
                data-tooltip-location="top"
              >
                Create Folder
              </button>
            </>
          )}
          {multiple && (
            <>
              <button
                style={{
                  borderRadius: "16px",
                  border: "none",
                  backgroundColor: "transparent",
                  color: "#0042a8",
                  fontSize: "14px",
                  fontWeight: "500",
                  margin: "4px",
                }}
                onClick={() => {
                  setMultiple(false);
                  setIds([]);
                }}
              >
                Unselect All
              </button>
              <button
                style={{
                  borderRadius: "16px",
                  border: "none",
                  backgroundColor: "transparent",
                  color: "#0042a8",
                  fontSize: "14px",
                  fontWeight: "500",
                  margin: "4px",
                }}
                onClick={() => {
                  childRef.current();
                }}
              >
                Share Selected
              </button>
              <button
                style={{
                  borderRadius: "16px",
                  border: "none",
                  backgroundColor: "transparent",
                  color: "#0042a8",
                  fontSize: "14px",
                  fontWeight: "500",
                  margin: "4px",
                }}
                onClick={() => {
                  personalService.webLoginDestroyAll(ids).then(() => {
                    fetchData();
                    setIds([]);
                    setMultiple(false);
                  });
                }}
              >
                Delete Selected
              </button>
              <button
                style={{
                  borderRadius: "16px",
                  border: "none",
                  backgroundColor: "transparent",
                  color: "#0042a8",
                  fontSize: "14px",
                  fontWeight: "500",
                  margin: "4px",
                }}
                onClick={() => {
                  setMultiple(false);
                  setIds([]);
                }}
              >
                Cancel
              </button>
            </>
          )}
        </GridItem>
        {sections && sections.length > 0 && (
          <>
            <TreeView
              className={classes.root + " tree-styling"}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              {sections.map((sec, key) => {
                return (
                  <Droppable
                    onDropItem={(s_id, w_id) => group(s_id, w_id)}
                    id={sec.id}
                  >
                    <TreeItem
                      style={{ padding: "1rem" }}
                      nodeId={key}
                      label={`${sec.name} (${
                        sec.web_logins ? sec.web_logins.length : "0"
                      })`}
                    >
                      <button
                        style={{
                          borderRadius: "16px",
                          border: "none",
                          backgroundColor: "transparent",
                          color: "#0042a8",
                          fontSize: "14px",
                          fontWeight: "500",
                          margin: "4px",
                        }}
                        onClick={() => {
                          setSectionId(sec.id);
                          setSectionName(sec.name);
                          setEditSection(true);
                        }}
                      >
                        Edit Name
                      </button>
                      <button
                        style={{
                          borderRadius: "16px",
                          border: "none",
                          backgroundColor: "transparent",
                          color: "#0042a8",
                          fontSize: "14px",
                          fontWeight: "500",
                          margin: "4px",
                        }}
                        onClick={() => {
                          handleFolderDelete(sec.id, sec.web_logins);
                          // setSectionId(sec.id);
                          // setSectionName(sec.name);
                          // setEditSection(true);
                        }}
                      >
                        Delete Folder
                      </button>
                      <Grid
                        container
                        wrap="wrap"
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                          margin: "25px 0",
                          borderBottom: "2px solid #0042a8",
                        }}
                      >
                        <Grid container>
                          {sec.web_logins &&
                            sec.web_logins
                              ?.sort((a, b) => {
                                if (sortType === "alpha") {
                                  if (order === "asc") {
                                    return a.description?.localeCompare(
                                      b.description
                                    );
                                  } else {
                                    return b.description?.localeCompare(
                                      a.description
                                    );
                                  }
                                } else if (sortType === "date") {
                                  if (order === "asc") {
                                    return a.created_at?.localeCompare(
                                      b.created_at
                                    );
                                  } else {
                                    return b.created_at?.localeCompare(
                                      a.created_at
                                    );
                                  }
                                } else {
                                  if (order === "asc") {
                                    return b.view_count - a.view_count;
                                  } else {
                                    return a.view_count - b.view_count;
                                  }
                                }
                              })
                              .map((dl, index) => {
                                return (
                                  <Draggable id={dl.id} sec_id={sec.id}>
                                    <CardViewComponent
                                      handleClickInfo={() =>
                                        handleClickInfo(dl?.id)
                                      }
                                      // options={options}
                                      childRef={childRef}
                                      handleOpenAddNew={handleOpenAddNew}
                                      handleOpenEdit={handleOpenEdit}
                                      grouped={true}
                                      unGroup={unGroup}
                                      openDropdown={openDropdown}
                                      handleToggle={() =>
                                        handleOpenDropDown(dl?.id)
                                      }
                                      anchorRef={anchorRef}
                                      handleCloseDropdown={handleCloseDropdown}
                                      show={
                                        !!dl?.password ||
                                        !!dl?.password_ciphertext
                                      }
                                      data={dl}
                                      setIndexId={setIndexId}
                                      currId={currId}
                                      selectedIndex={selectedIndex}
                                      setShowSections={setShowSections}
                                      key={index}
                                      category={"web"}
                                      handleDelete={handleDelete}
                                      handleMenuItemClick={handleMenuItemClick}
                                      subcategory={"WebLogin"}
                                      heading={
                                        dl.description ? dl.description : "-"
                                      }
                                      multiple={multiple}
                                      setMultiple={setMultiple}
                                      setIds={setIds}
                                      ids={ids}
                                    />
                                  </Draggable>
                                );
                              })}
                        </Grid>
                      </Grid>
                    </TreeItem>
                  </Droppable>
                );
              })}
            </TreeView>
          </>
        )}
        {webLogins?.length > 0 && !loading && !err ? (
          <>
            {webLogins
              ?.sort((a, b) => {
                if (sortType === "alpha") {
                  if (order === "asc") {
                    return a.description?.localeCompare(b.description);
                  } else {
                    return b.description?.localeCompare(a.description);
                  }
                } else if (sortType === "date") {
                  if (order === "asc") {
                    return a.created_at?.localeCompare(b.created_at);
                  } else {
                    return b.created_at?.localeCompare(a.created_at);
                  }
                } else {
                  if (order === "asc") {
                    return b.view_count - a.view_count;
                  } else {
                    return a.view_count - b.view_count;
                  }
                }
              })
              .map((dl, index) => {
                if (!dl?.section_id) {
                  return (
                    <Draggable id={dl.id}>
                      <CardViewComponent
                        handleClickInfo={() => handleClickInfo(dl?.id)}
                        // options={options}
                        childRef={childRef}
                        handleOpenAddNew={handleOpenAddNew}
                        handleOpenEdit={handleOpenEdit}
                        openDropdown={openDropdown}
                        handleToggle={() => handleOpenDropDown(dl?.id)}
                        anchorRef={anchorRef}
                        handleCloseDropdown={handleCloseDropdown}
                        show={!!dl?.password || !!dl?.password_ciphertext}
                        data={dl}
                        currId={currId}
                        selectedIndex={selectedIndex}
                        key={index}
                        category={"web"}
                        handleDelete={handleDelete}
                        handleMenuItemClick={handleMenuItemClick}
                        subcategory={"WebLogin"}
                        heading={dl.description ? dl.description : "-"}
                        multiple={multiple}
                        setIndexId={setIndexId}
                        setMultiple={setMultiple}
                        setShowSections={setShowSections}
                        setIds={setIds}
                        ids={ids}
                      />
                    </Draggable>
                  );
                }
              })}
          </>
        ) : loading ? (
          <CircularProgress size={40} color="secondary" />
        ) : err ? (
          "error occurred"
        ) : !loading &&
          sections.filter((sec) => sec.web_logins).length === 0 &&
          !err ? (
          "No Web Logins Yet"
        ) : null}
      </Grid>
    </DndProvider>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    sortType: state?.sort?.sort,
    order: state?.sort?.order,
  };
};

export default connect(mapStateToProps)(WebLogins);
