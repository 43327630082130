import React, { useEffect, useCallback, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
// @material-ui/core components
// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
// core components
import { useRouteName } from "../../../hooks";
import GridItem from "../../../components/Grid/GridItem.js";
import { Grid, Typography } from "@material-ui/core";
import color from "../../../assets/css/color.js";
// @material-ui/icons
import OtherInsuranceInformation from "../../../containers/Modals/OtherInsuranceInformation/OtherInsuranceInformation";
import LinkedItems from "../../../containers/Modals/LinkedItems/LinkedItems.js";
import Collaborators from "../../../containers/Modals/Collaborators/Collaborators";
import W2Form from "../../../containers/Modals/W2Form/W2Form";
import AddOtherInsurance from "../../../containers/Modals/AddOtherInsurance/AddOtherInsurance";
import { CustomButton } from "../../../components/CustomButton/Button";
import AddContactInfo from "../../../containers/Modals/AddContactInfo/AddContactInfo";
import CircularProgress from "@material-ui/core/CircularProgress";
import { store } from "../../../store/store";
import CardCategory from "../../../components/Card/CardCategory";
import EditOtherInsurance from "../../../containers/Modals/EditOtherInsurance copy/EditOtherInsurance";
import { InsuranceService } from "../../../services/insurance.service";
import { connect } from "react-redux";
import { HistoryService } from "../../../services/history.service";

function OtherInsurance({ sortType, order }) {
  const historyService = new HistoryService();
  const insuranceService = new InsuranceService();
  const [multiple, setMultiple] = React.useState(false);
  const [ids, setIds] = React.useState([]);
  const [openDropdown, setOpenDropdown] = React.useState(false);
  const [otherInsurances, setOtherInsurances] = React.useState([]);
  const [err, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [preference, setPreference] = useState(null);
  const [edit, setEdit] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [info, setInfo] = React.useState(null);
const [currId, setCurrId] = React.useState(null);
  const [selectedData, setSelectedData] = useState({});  
  const [currotherInsurance, setCurrOtherInsurance] = React.useState(null);
  const childRef = useRef();
  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");

  React.useEffect(() => {
    setLoading(true);
    insuranceService
      .otherInsuranceIndex()
      .then((response) => {
        console.log({ response });
        setLoading(false);
        setOtherInsurances(response?.data?.other_insurances);
        if (
          id &&
          response?.data?.other_insurances?.length > 0 &&
          response?.data?.other_insurances?.filter((f) => f?.id === +id)
            .length > 0
        ) {
          setInfo(
            response?.data?.other_insurances?.filter((f) => f?.id === +id)
          );
          setOpen(true);
        }
      })
      .then(() => {
        return historyService.alertsIndex();
      })
      .then((response) => {
        if (response.data && response.data?.length > 0) {
          let temp = response.data?.filter(
            (p, key) => p.category === "OtherInsurance"
          );
          setPreference(temp[0]);
        }
      })
      .catch((err) => {
        // console.log({ err });
        setLoading(false);
        setError("error occurred");
      });
  }, [store.getState().loading.render]);

  const handleMenuItemClick = (event) => {
    // setSelectedIndex(index); +
    setOpenDropdown(false);
  };

  const handleToggle = () => {
    setOpenDropdown((prevopenDropdown) => !prevopenDropdown);
  };

  const handleOpenDropDown = (id) => {
    setCurrId(id);
    handleToggle();
  };
  const handleCloseDropdown = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenDropdown(false);
  };

  const routeName = useRouteName();

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openAddNew, setOpenAddNew] = React.useState(false);
  const [openLinkedItems, setOpenLinkedItems] = React.useState(false);
  const [openCollaborators, setOpenCollaborators] = React.useState(false);
  const [openW2Form, setOpenW2Form] = React.useState(false);
  const [openAddContactInfo, setOpenAddContactInfo] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickInfo = (id) => {
    const otherInsurance = otherInsurances?.filter((aw) => aw.id === id);
    setInfo(otherInsurance);
    handleOpen();
  };

  const handleOpenAddNew = () => {
    setOpenAddNew(true);
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenW2Form(false);
  };

  const handleOpenEdit = (otherInsurance) => {
    setEdit(true);
    setOpenEdit(true);
    setCurrOtherInsurance(otherInsurance);
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenW2Form(false);
  };

  const handleOpenLinkedItems = () => {
    setOpenLinkedItems(true);
    setOpen(false);
    setOpenCollaborators(false);
    setOpenW2Form(false);
    setOpenAddNew(false);
  };

  const handleOpenCollaborators = () => {
    setOpenCollaborators(true);
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenW2Form(false);
    setOpenAddNew(false);
  };
  const handleSelectedCollaborators = useCallback((dl) => {
    setSelectedData(dl);
  }, []);

  const handleOpenW2Form = () => {
    setOpenW2Form(true);
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenAddNew(false);
  };

  const handleOpenAddContactInfo = () => {
    setOpenAddContactInfo(true);
    setOpenW2Form(false);
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenAddNew(false);
  };

  const handleCloseLinkedItems = () => {
    setOpenLinkedItems(false);
    setOpen(true);
  };

  const handleCloseCollaborators = () => {
    setOpenCollaborators(false);
  };

  const handleCloseW2Form = () => {
    setOpenW2Form(false);
  };

  const handleCloseAddNew = () => {
    setOpenAddNew(false);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setEdit(false);
  };

  const handleCloseAddContactInfo = () => {
    setOpenAddContactInfo(false);
  };

  const closeModal = () => {
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenW2Form(false);
    setOpenAddNew(false);
    setOpenAddContactInfo(false);
  };

  const closeEditModal = () => {
    setOpenEdit(false);
    setEdit(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenW2Form(false);
    setOpenAddNew(false);
    setOpenAddContactInfo(false);
  };

  const handleDelete = (id) => {
    insuranceService.otherInsuranceDestroy(id).then(() => {
      const remaining = otherInsurances.filter((aw) => aw.id !== id);
      setOtherInsurances(remaining);
    });
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Grid
        container
        wrap="wrap"
        direction={isMobile ? 'column' : 'row'}
        justifyContent=""
        alignItems={isMobile ? 'flex-start' : 'center'}
        style={{ margin: "25px 0" }}
      >
        <Typography
          variant="h6"
          component="h3"
          style={{ color: color.themeColorNew }}
        >
          Other Insurances
        </Typography>
        <CustomButton
          type="submit"
          btnText="Add New"
          marginLeft="4rem"
          textAlign="center"
          display={"block"}
          padding="8px 17px"
          background="#0042a8"
          hoverBg="#0042a8"
          border="0"
          borderRadius="8px"
          color="#fff"
          onClick={() => handleOpenAddNew()}
        />
        <Grid item xs={6} md={6}></Grid>
        <Grid item xs={6} md={6} style={{ textAlign: "right" }}></Grid>
      </Grid>
      <Grid container>
        <GridItem xs={12} sm={12} md={12}>
          {openAddNew && (
            <AddOtherInsurance
              preference={preference}
              open={openAddNew}
              handleClose={handleCloseAddNew}
              handleOpenAddContactInfo={handleOpenAddContactInfo}
              closeModal={closeModal}
            />
          )}
          {edit && (
            <EditOtherInsurance
              preference={preference}
              open={openEdit}
              handleClose={handleCloseEdit}
              handleOpenAddContactInfo={handleOpenAddContactInfo}
              closeModal={closeEditModal}
              otherInsurance={currotherInsurance}
            />
          )}
          <AddContactInfo
            open={openAddContactInfo}
            handleCloseAddContactInfo={handleCloseAddContactInfo}
            closeModal={closeModal}
          />
          {open && (
            <OtherInsuranceInformation
              open={open}
              handleClose={handleClose}
              handleOpenEdit={handleOpenEdit}
              otherInsurance={info}
              handleOpenLinkedItems={handleOpenLinkedItems}
              handleOpenCollaborators={handleOpenCollaborators}
              closeModal={closeModal}
            />
          )}
          <LinkedItems
            attachment={info}
            open={openLinkedItems}
            handleCloseLinkedItems={handleCloseLinkedItems}
            closeModal={closeModal}
            heading={"Other Insurance"}
            category={"Insurance"}
          />
          <Collaborators
            open={openCollaborators}
            handleCloseCollaborators={handleCloseCollaborators}
            closeModal={closeModal}
            data={selectedData}
          />
          <W2Form
            open={openW2Form}
            handleCloseW2Form={handleCloseW2Form}
            handleOpenLinkedItems={handleOpenLinkedItems}
            handleOpenCollaborators={handleOpenCollaborators}
            closeModal={closeModal}
          />
          {otherInsurances?.length > 0 && (
            <button
              style={{
                borderRadius: "16px",
                border: "none",
                backgroundColor: "transparent",
                color: "#0042a8",
                fontSize: "14px",
                fontWeight: "500",
                margin: "4px",
              }}
              onClick={() => {
                setMultiple(true);
                let array = [];
                otherInsurances?.forEach((e) => array.push(e.id));
                setIds(array);
              }}
            >
              Select All
            </button>
          )}
          {multiple && (
            <>
              <button
                style={{
                  borderRadius: "16px",
                  border: "none",
                  backgroundColor: "transparent",
                  color: "#0042a8",
                  fontSize: "14px",
                  fontWeight: "500",
                  margin: "4px",
                }}
                onClick={() => {
                  setMultiple(false);
                  setIds([]);
                }}
              >
                Unselect All
              </button>
              <button
                style={{
                  borderRadius: "16px",
                  border: "none",
                  backgroundColor: "transparent",
                  color: "#0042a8",
                  fontSize: "14px",
                  fontWeight: "500",
                  margin: "4px",
                }}
                onClick={() => {
                  childRef.current();
                }}
              >
                Share Selected
              </button>
              <button
                style={{
                  borderRadius: "16px",
                  border: "none",
                  backgroundColor: "transparent",
                  color: "#0042a8",
                  fontSize: "14px",
                  fontWeight: "500",
                  margin: "4px",
                }}
                onClick={() => {
                  insuranceService.otherInsuranceDestroyAll(ids).then(() => {
                    const remaining = otherInsurances.filter(
                      (aw) => !ids.includes(aw.id)
                    );
                    setOtherInsurances(remaining);
                    setIds([]);
                    setMultiple(false);
                  });
                }}
              >
                Delete Selected
              </button>
              <button
                style={{
                  borderRadius: "16px",
                  border: "none",
                  backgroundColor: "transparent",
                  color: "#0042a8",
                  fontSize: "14px",
                  fontWeight: "500",
                  margin: "4px",
                }}
                onClick={() => {
                  setMultiple(false);
                  setIds([]);
                }}
              >
                Cancel
              </button>
            </>
          )}
        </GridItem>
        {otherInsurances?.length > 0 && !loading && !err ? (
          <>
            {otherInsurances
              ?.sort((a, b) => {
                if (sortType === "alpha") {
                  if (order === "asc") {
                    return a.provider?.localeCompare(b.provider);
                  } else {
                    return b.provider?.localeCompare(a.provider);
                  }
                } else if (sortType === "date") {
                  if (order === "asc") {
                    return a.created_at?.localeCompare(b.created_at);
                  } else {
                    return b.created_at?.localeCompare(a.created_at);
                  }
                }
              })
              .map((aid, index) => {
                return (
                  <CardCategory
                    handleClickInfo={() => handleClickInfo(aid?.id)}
                    // options={options}
                    handleOpenAddNew={handleOpenAddNew}
                    handleOpenEdit={handleOpenEdit}
                    openDropdown={openDropdown}
                    handleToggle={() => handleOpenDropDown(aid?.id)}
                    anchorRef={anchorRef}
                    handleCloseDropdown={handleCloseDropdown}
                    data={aid}
                    currId={currId}
                    selectedIndex={selectedIndex}
                    key={index}
                    handleDelete={handleDelete}
                    subcategory={"OtherInsurance"}
                    handleMenuItemClick={handleMenuItemClick}
                    heading={aid.provider ? aid.provider : "-"}
                    heading2={aid.policy_number ?? "-"}
                    heading3={aid.insurance_status ? "(Current)" : "(Expired)"}
                    multiple={multiple}
                    setMultiple={setMultiple}
                    handleSelectedCollaborators={handleSelectedCollaborators}
                    setIds={setIds}
                    ids={ids}
                    childRef={childRef}
                  />
                );
              })}
          </>
        ) : loading ? (
          <CircularProgress size={40} color="secondary" />
        ) : err ? (
          "error occurred"
        ) : !loading && !err ? (
          "No Other Insurances yet"
        ) : null}
      </Grid>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    sortType: state?.sort?.sort,
    order: state?.sort?.order,
  };
};

export default connect(mapStateToProps)(OtherInsurance);
