import "date-fns";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";

import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color.js";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction.js";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import { PersonalService } from "../../../services/personal.service.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import AddDocument from "../AddDocument/AddDocument.js";
import { upperCaseErrorKeys } from "../../../Utils/stringManipulation.js";
import dashboardRoutes from "../../../routes.js";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { dateFormat } from "../../../Utils/dateFormate.js";


const useStyles = makeStyles(styles);

function AddUserPermissions(props) {
  const classes = useStyles();
  const personalService = new PersonalService();
  const [err, setErr] = React.useState([]);
  const [subArray, setSubArray] = React.useState(dashboardRoutes[0].submenu);
  const [isLoading, setIsLoading] = React.useState(false);
  const errRef = useRef(null);
  const [isEndDateEnabled, setIsEndDateEnabled] = React.useState(true);
  const [data, setData] = React.useState({
    category_type: "Personal",
    subcategory_type: "AlternateIdentification",
    user: {
      email: "",
    },
    access_type: "read-only",
    end_date: new Date(),
    profile_id: props?.profile?.profileInfo?.profile.id,
  });

  const handleChangeCategory = React.useCallback(() => {
    setSubArray(
      dashboardRoutes.filter((cat) => cat.name === data.category_type)[0]
        .submenu
    );
    setData({
      ...data,
      subcategory_type: dashboardRoutes.filter(
        (cat) => cat.name === data.category_type
      )[0].submenu[0].model,
    });
  }, [data.category_type]);

  useEffect(() => {
    handleChangeCategory();
  }, [handleChangeCategory]);

  const handleSubmit = () => {
    setIsLoading(true);
    personalService
      .addUserPermission(data)
      .then((response) => {
        setIsLoading(false);
        props.forceRerender();
        props.handleClose();
        props.data();
        console.log(response.data);
      })
      .catch((err) => {
        errRef.current.scrollIntoView();
        setIsLoading(false);
        if (err.response && err.response.data && err.response.data.errors) {
          let errors = [];
          err.response.data.errors.forEach((err) => {
            errors.push(err);
          });
          setErr(errors);
        } else {
          setErr(["Internal Server Error"]);
        }
      });
  };

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleEndDateToggle = (event) => {
    setIsEndDateEnabled(event.target.checked);
    if (!event.target.checked) {
      setData({ ...data, end_date: null }); // Reset end_date when toggle is off
    }
  };

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Add User Permissions"}
          color={color.white}
        />
        <div className={classes.modalBody}>
          <div className="w-100 d-flex flex-row mb-4" id={"err"} ref={errRef}>
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              {"Permission > Administrator > Add User Permission"}
            </Typography>
          </div>
          {err?.length > 0 && (
            <div className="mb-4">
              {err?.map((val, key) => {
                return (
                  <div className="w-100 d-flex flex-row mb-1">
                    <Typography
                      key={key}
                      variant="p"
                      component="p"
                      align="left"
                      style={{
                        color: "red",
                        fontWeight: "normal",
                        marginBottom: "2px",
                      }}
                    >
                      {val.charAt(0).toUpperCase() + val.slice(1)}
                    </Typography>
                  </div>
                );
              })}
            </div>
          )}
          <Grid container wrap="wrap" direction="row" spacing={3}>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Invite Users*"}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                id="invite_users"
                name="invite_users"
                onChange={(e) =>
                  setData({
                    ...data,
                    user: {
                      email: e.target.value,
                    },
                  })
                }
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Type user's email address and click enter"}
              />
              {/* <div
                className="d-flex flex-row align-items-center rounded p-2"
                style={{ width: "min-content", background: color.LightGrey }}
              >
                <Close
                  style={{
                    color: color.black,
                    marginRight: "5px",
                    // fontSize: "30px",
                    cursor: "pointer",
                  }}
                />
                <span>andy@gmail.com</span>
              </div> */}
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Category*"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  id="category_type"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  value={data.category_type}
                  onChange={handleChange}
                  name="category_type"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {dashboardRoutes.map((cat, index) => {
                    return (
                      <MenuItem key={index} value={cat.name}>
                        {cat.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Information Type*"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  id="subcategory_type"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  value={data.subcategory_type}
                  onChange={handleChange}
                  name="subcategory_type"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {subArray.map((sub) => {
                    return <MenuItem value={sub.model}>{sub.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>

            {/* <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"End Date"}
              </Typography>
            </Grid> */}

            <Grid item xs={12} sm={12} md={12}>
              <div className="d-flex justify-content-between">
                <Typography
                  variant="h6"
                  component="h3"
                  style={{ color: color.themeColorNew }}
                >
                  {"End Date"}
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isEndDateEnabled}
                      onChange={handleEndDateToggle}
                      name="endDateToggle"
                      color="primary"
                    />
                  }
                  label=""
                />
              </div>
              {isEndDateEnabled && (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth={true}
                    margin="dense"
                    required={true}
                    inputVariant="outlined"
                    format={dateFormat()}
                    id="end_date"
                    placeholder={dateFormat()?.toUpperCase()}
                    value={data.end_date}
                    onChange={(date) => {
                      setData({
                        ...data,
                        end_date: date,
                      });
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              )}
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  inputVariant="outlined"
                  format={dateFormat()}
                  id="start_date"
                  placeholder={dateFormat()?.toUpperCase()}
                  value={data.end_date}
                  onChange={(date) => {
                    setData({
                      ...data,
                      end_date: date,
                    });
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid> */}
            {/* <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Document/Information/Contact"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  id="id_type"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  // value={data?.id_type}
                  onChange={handleChange}
                  name="id_type1"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="Government Issued ID">
                    Start typing...
                  </MenuItem>
                  <MenuItem value="Military ID">Military ID</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {other ? (
              <>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Other"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormInput
                    name="id_type"
                    onChange={handleChange}
                    label=""
                    variant="outlined"
                    fullWidth={true}
                    margin="dense"
                    required={true}
                    placeholder={""}
                  />
                </Grid>
              </>
            ) : null}
            <Grid item xs="12" sm="12" md="12">
              <div
                className="d-flex flex-row align-items-center justify-content-between rounded p-2 w-50 w-md-100"
                style={{ background: color.LightGrey }}
              >
                <div className="d-flex flex-row align-items-center">
                  <Close
                    style={{
                      color: color.black,
                      marginRight: "5px",
                      // fontSize: "30px",
                      cursor: "pointer",
                    }}
                  />
                  <span>W2 Form</span>
                </div>
                <CustomButton
                  btnText="Editor"
                  onClick={() => props.closeModal()}
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="25px"
                  color="#fff"
                />
              </div>
            </Grid> */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              {/*  */}
              {!isLoading ? (
                <CustomButton
                  type="submit"
                  onClick={handleSubmit}
                  btnText="Invite"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  className="mb-sm-2"
                  marginRight={"10px"}
                  color="#fff"
                  disabled={
                    !data.user.email ||
                    !data.subcategory_type ||
                    !data.category_type
                  }
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  className="mb-sm-2"
                  marginRight={"10px"}
                  color="#fff"
                />
              )}
              <CustomButton
                btnText="Cancel"
                onClick={() => props.closeModal()}
                textAlign="center"
                display={"block"}
                padding="8px 25px"
                background="#0042a8"
                hoverBg="#0042a8"
                border="0"
                borderRadius="8px"
                color="#fff"
              />
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
    profile: state.profile,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserPermissions);
