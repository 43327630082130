import DashboardPage from "../src/views/Dashboard/Dashboard.js";
import LoginPage from "./views/LoginPage/Login";
import RegisterPage from "./views/RegisterPage/Register";
import UserProfile from "../src/views/UserProfile/UserProfile.js";
import Typography from "../src/views/Typography/Typography.js";
import Icons from "../src/views/Icons/Icons.js";
import Maps from "../src/views/Maps/Maps.js";
import NotificationsPage from "../src/views/Notifications/Notifications.js";
import { RegisterEmail } from "./views/RegisterEmail/RegisterEmail";
import Personal from "./assets/img/Home_Personal.svg";
import Health from "./assets/img/Home_Medical.svg";
import Employment from "./assets/img/Home_Employment.svg";
import Finance from "./assets/img/Home_Finance.svg";
import Mortgage from "./views/Finance/Mortgage/Mortgage";
import Insurance from "./assets/img/Home_Insurance.svg";
import EducationIcon from "./assets/img/Home_Education.svg";
import Vehicle from "./assets/img/Home_Vehicle.svg";
import Property from "./assets/img/Home_Property.svg";
import Skill from "./views/EmploymentPage/Skill/Skill.js";
import Employer from "./views/EmploymentPage/Employer/Employer.js";
import Award from "./views/EmploymentPage/Award/Award.js";
import AlternateIdentifications from "./views/Personal/AlternateIdentifications/AlternateIdentifications.js";
import DriverLicenses from "./views/Personal/DriverLicenses/DriverLicenses";
import EmailAccounts from "./views/Personal/EmailAccounts/EmailAcounts";
import LoyaltyPrograms from "./views/Personal/LoyaltyPrograms/LoyaltyPrograms";
import Passports from "./views/Personal/Passports/Passports";
import PersonalInformation from "./views/Personal/PersonalInformation/PersonalInformation";
import Visas from "./views/Personal/Visas/Visas";
import WebLogins from "./views/Personal/WebLogins/WebLogins";
import Certificates from "./views/Education/Certificates/Certificates";
import Languages from "./views/Education/Languages/Languages";
import Education from "./views/Education/Education";
import CashAccounts from "./views/Finance/CashAccounts/CashAccounts";
import Expenses from "./views/Finance/Expenses/Expenses";
import Incomes from "./views/Finance/Incomes/Incomes";
import MarginAccounts from "./views/Finance/MarginAccounts/MarginAccounts";
import MarketableSecurities from "./views/Finance/MarketableSecurities/MarketableSecurities";
import NonMarketableSecurities from "./views/Finance/NonMarketableSecurities/NonMarketableSecurities";
import RetirementFunds from "./views/Finance/RetirementFunds/RetirementFunds";
import TaxReturns from "./views/Finance/TaxReturns/TaxReturns";
import FamilyConditions from "./views/Health/FamilyConditions/FamilyConditions";
import HealthConditions from "./views/Health/HealthConditions/HealthConditions";
import Hospitalizations from "./views/Health/Hopitalizations/Hospitalizations";
import Immunizations from "./views/Health/Immunizations/Immunizations";
import Medications from "./views/Health/Medications/Medications";
import Procedures from "./views/Health/Procedures/Procedures";
import TestResults from "./views/Health/TestResults/TestResults";
import Wellness from "./views/Health/Wellness/Wellness";
import Allergies from "./views/Health/Allergies/Allergies";
import AutoInsurance from "./views/Insurance/AutoInsurance/AutoInsurance";
import DisabilityInsurance from "./views/Insurance/DisabilityInsurance/DisabilityInsurance";
import HealthInsurance from "./views/Insurance/HealthInsurance/HealthInsurance";
import LifeInsurance from "./views/Insurance/LifeInsurance/LifeInsurance";
import OtherInsurance from "./views/Insurance/OtherInsurance/OtherInsurance";
import PropertyInsurance from "./views/Insurance/PropertyInsurance/PropertyInsurance";
import Vehicles from "./views/Vehicle/Vehicles";
import AutoServices from "./views/Vehicle/AutoServices/AutoServices";
import House from "./views/Property/House/House";
import PersonalItems from "./views/Property/PersonalItems/PersonalItems";
import Retails from "./views/Property/Retails/Retails";
import SerialNumbers from "./views/Property/SerialNumbers/SerialNumbers";
import ResetPasswordVerification from "./views/ResetPasswordVerification/ResetPasswordVerification";
import ResetPassword from "./views/ResetPassword/ResetPassword";
import { MailSuccessResetPassword } from "./views/MailSuccessResetPassword/MailSuccessResetPassword";
import Otp from "./views/Otp/Otp.js";
import AltIDSub from "./assets/img/subMenuNew/1_AltID.svg";
import DriverLicenseSub from "./assets/img/subMenuNew/1_DriverLicense.svg";
import EmailAcctSub from "./assets/img/subMenuNew/1_EmailAcct.svg";
import LoyaltyProgramsSub from "./assets/img/subMenuNew/1_LoyaltyPrograms.svg";
import PassportsSub from "./assets/img/subMenuNew/1_Passports.svg";
import PInformationSub from "./assets/img/subMenuNew/1_PInformation.svg";
import VisasSub from "./assets/img/subMenuNew/1_Visas.svg";
import WebLoginsSub from "./assets/img/subMenuNew/1_WebLogins.svg";
import EmergencyContactSub from "./assets/img/subMenuNew/2_EmergencyContacts.svg";
import AllergiesSub from "./assets/img/subMenuNew/2_Allergies.svg";
import FConditionsSub from "./assets/img/subMenuNew/2_FConditions.svg";
import HConditionsSub from "./assets/img/subMenuNew/2_HealthConditions.svg";
import HospitalizationsSub from "./assets/img/subMenuNew/2_Hospitalizations.svg";
import ImmunizationsSub from "./assets/img/subMenuNew/2_Immunizations.svg";
import MedicationsSub from "./assets/img/subMenuNew/2_Medications.svg";
import ProceduresSub from "./assets/img/subMenuNew/2_Procedures.svg";
import TResultsSub from "./assets/img/subMenuNew/2_TResults.svg";
import WellnessSub from "./assets/img/subMenuNew/2_Wellness.svg";
import AwardsSub from "./assets/img/subMenuNew/3_Awards.svg";
import EmployersSub from "./assets/img/subMenuNew/3_Employers.svg";
import SkillsSub from "./assets/img/subMenuNew/3_Skills.svg";
import CashAcctSub from "./assets/img/subMenuNew/4_CashAcct.svg";
import ExpensesSub from "./assets/img/subMenuNew/4_Expenses.svg";
import IncomesSub from "./assets/img/subMenuNew/4_Incomes.svg";
import MarginAcctSub from "./assets/img/subMenuNew/4_MarginAcct.svg";
import MSecuritiesSub from "./assets/img/subMenuNew/4_MSecurities.svg";
import NSecuritiesSub from "./assets/img/subMenuNew/4_NSecurities.svg";
import RFundsSub from "./assets/img/subMenuNew/4_RFunds.svg";
import TaxReturnsSub from "./assets/img/subMenuNew/4_TaxReturns.svg";
import MortgagesSub from "./assets/img/subMenuNew/4_Loans.svg";
import AutoSub from "./assets/img/subMenuNew/5_Auto.svg";
import DisabilitySub from "./assets/img/subMenuNew/5_Disability.svg";
import HealthSub from "./assets/img/subMenuNew/5_Health.svg";
import LifeSub from "./assets/img/subMenuNew/5_Life.svg";
import OtherSub from "./assets/img/subMenuNew/5_Other.svg";
import PropertySub from "./assets/img/subMenuNew/5_Property.svg";
import AutoServicesSub from "./assets/img/subMenuNew/6_AutoServices.svg";
import VehiclesSub from "./assets/img/subMenuNew/6_Vehicles.svg";
import HousesSub from "./assets/img/subMenuNew/7_Houses.svg";
import PItemsSub from "./assets/img/subMenuNew/7_PItems.svg";
import RentalsSub from "./assets/img/subMenuNew/7_Rentals.svg";
import SerialNumbersSub from "./assets/img/subMenuNew/7_Serial Numbers.svg";
import CertificatesSub from "./assets/img/subMenuNew/8_Certificates.svg";
import EducationSub from "./assets/img/axielSideBarIcon/education.png";
import LanguagesSub from "./assets/img/subMenuNew/8_Languages.svg";
import EmergencyContact from "./views/Personal/EmergencyContact/EmergencyContact";

const dashboardRoutes = [
  {
    path: "/personal",
    name: "Personal",
    iconImage: Personal,
    component: DashboardPage,
    layout: "/user",
    submenu: [
      {
        subIconImage: AltIDSub,
        name: "Alternate Identifications",
        path: "/alternate-Identifications",
        component: AlternateIdentifications,
        sub: "Alternate Identification",
        sub1: "alternate_identification",
        model: "AlternateIdentification",
      },
      {
        subIconImage: DriverLicenseSub,
        name: "Driver Licenses",
        path: "/driver-licenses",
        component: DriverLicenses,
        sub: "Driver License",
        sub1: "driver_license",
        model: "DriverLicense",
      },
      {
        subIconImage: EmailAcctSub,
        name: "Email Accounts",
        path: "/email-accounts",
        component: EmailAccounts,
        sub: "Email Account",
        sub1: "email_account",
        model: "EmailAccount",
      },
      {
        subIconImage: LoyaltyProgramsSub,
        name: "Loyalty Programs",
        path: "/loyalty-programs",
        component: LoyaltyPrograms,
        sub: "Loyalty Program",
        sub1: "loyalty_program",
        model: "LoyaltyProgram",
      },
      {
        subIconImage: PassportsSub,
        name: "Passports",
        path: "/passports",
        sub: "Passport",
        sub1: "passport",
        component: Passports,
        model: "Passport",
      },
      {
        subIconImage: PInformationSub,
        name: "Personal Information",
        path: "/personal-information",
        sub: "Personal Information",
        sub1: "personal_information",
        component: PersonalInformation,
        model: "PersonalInformation",
      },
      {
        subIconImage: VisasSub,
        name: "Visas",
        path: "/visas",
        sub: "Visa",
        sub1: "visa",
        component: Visas,
        model: "Visa",
      },
      {
        subIconImage: WebLoginsSub,
        name: "Web Logins",
        path: "/web-logins",
        sub: "Web Login",
        sub1: "web_login",
        component: WebLogins,
        model: "WebLogin",
      },
      {
        subIconImage: EmergencyContactSub,
        name: "Emergency Contacts",
        path: "/emergency-contacts",
        sub: "Emergency Contact",
        sub1: "emergency_contacts",
        component: EmergencyContact,
        model: "EmergencyContact",
      },
    ],
  },
  // {
  //   path: "/personal",
  //   name: "Personal",
  //   iconImage: Personal,
  //   component: NotificationsPage,
  //   layout: "/user",
  // },
  {
    path: "/health",
    name: "Health",
    iconImage: Health,
    component: UserProfile,
    layout: "/user",
    submenu: [
      {
        subIconImage: FConditionsSub,
        name: "Family Conditions",
        path: "/family-conditions",
        sub: "Family Condition",
        sub1: "family_condition",
        component: FamilyConditions,
        model: "FamilyCondition",
      },
      {
        subIconImage: HConditionsSub,
        name: "Health Conditions",
        path: "/health-conditions",
        sub: "Health Condition",
        sub1: "health_condition",
        component: HealthConditions,
        model: "HealthCondition",
      },
      {
        subIconImage: HospitalizationsSub,
        name: "Hospitalizations",
        path: "/hospitalizations",
        sub: "Hospitalization",
        sub1: "hospitalization",
        component: Hospitalizations,
        model: "Hospitalization",
      },
      {
        subIconImage: ImmunizationsSub,
        name: "Immunizations",
        path: "/immunizations",
        sub: "Immunization",
        sub1: "immunization",
        component: Immunizations,
        model: "Immunization",
      },
      {
        subIconImage: MedicationsSub,
        name: "Medications",
        path: "/medications",
        sub: "Medication",
        sub1: "medication",
        component: Medications,
        model: "Medication",
      },
      {
        subIconImage: ProceduresSub,
        name: "Procedures",
        path: "/procedures",
        sub: "Procedure",
        sub1: "procedure",
        component: Procedures,
        model: "Procedure",
      },
      {
        subIconImage: TResultsSub,
        name: "Test Results",
        path: "/test-results",
        sub: "Test Result",
        sub1: "test_result",
        component: TestResults,
        model: "TestResult",
      },
      {
        subIconImage: WellnessSub,
        name: "Wellness",
        path: "/wellness",
        sub: "Wellness",
        sub1: "wellness",
        component: Wellness,
        model: "Wellness",
      },
      {
        subIconImage: AllergiesSub,
        name: "Allergies",
        path: "/allergies",
        sub: "Allergy",
        sub1: "allergy",
        component: Allergies,
        model: "Allergy",
      },
    ],
  },
  {
    path: "/employment",
    name: "Employment",
    iconImage: Employment,
    component: Employer,
    layout: "/user",
    submenu: [
      {
        subIconImage: SkillsSub,
        name: "Skills",
        sub: "Skill",
        sub1: "skill",
        path: "/skills",
        component: Skill,
        model: "Skill",
      },
      // { name: "Employers", path: "/employers", component: EmploymentPage },
      {
        subIconImage: EmployersSub,
        name: "Employers",
        sub: "Employer",
        sub1: "employer",
        path: "/employers",
        component: Employer,
        model: "Employer",
      },
      {
        subIconImage: AwardsSub,
        name: "Awards",
        sub: "Award",
        sub1: "award",
        path: "/awards",
        component: Award,
        model: "Award",
      },
    ],
  },
  {
    path: "/finance",
    name: "Finance",
    iconImage: Finance,
    component: Typography,
    layout: "/user",
    submenu: [
      {
        subIconImage: CashAcctSub,
        name: "Cash Accounts",
        path: "/cash-accounts",
        sub: "Cash Account",
        sub1: "cash_account",
        component: CashAccounts,
        model: "CashAccount",
      },
      {
        subIconImage: ExpensesSub,
        name: "Expenses",
        path: "/expenses",
        sub: "Expense",
        sub1: "expense",
        component: Expenses,
        model: "Expense",
      },
      {
        subIconImage: IncomesSub,
        name: "Incomes",
        path: "/incomes",
        sub: "Income",
        sub1: "income",
        component: Incomes,
        model: "Income",
      },
      {
        subIconImage: MarginAcctSub,
        name: "Margin Accounts",
        sub: "Margin Account",
        sub1: "margin_account",
        path: "/margin-accounts",
        component: MarginAccounts,
        model: "MarginAccount",
      },
      {
        subIconImage: MSecuritiesSub,
        name: "Marketable Securities",
        sub: "Marketable Security",
        sub1: "marketable_security",
        path: "/marketable-securities",
        component: MarketableSecurities,
        model: "MarketableSecurity",
      },
      {
        subIconImage: NSecuritiesSub,
        name: "Nonmarketable Securities",
        sub: "Nonmarketable Security",
        sub1: "nonmarketable_security",
        path: "/nonmarketable-securities",
        component: NonMarketableSecurities,
        model: "NonmarketableSecurity",
      },
      {
        subIconImage: RFundsSub,
        name: "Retirement Funds",
        sub: "Retirement Fund",
        sub1: "retirement_fund",
        path: "/retirement-funds",
        component: RetirementFunds,
        model: "RetirementFund",
      },
      {
        subIconImage: TaxReturnsSub,
        name: "Tax Returns",
        sub: "Tax Return",
        sub1: "tax_return",
        path: "/tax-returns",
        component: TaxReturns,
        model: "TaxReturn",
      },
      {
        subIconImage: MortgagesSub,
        name: "Mortgages",
        sub: "Mortgage",
        sub1: "mortgage",
        path: "/mortgages",
        component: Mortgage,
        model: "Mortgage",
      },
    ],
  },
  {
    path: "/insurance",
    name: "Insurance",
    iconImage: Insurance,
    component: Icons,
    layout: "/user",

    submenu: [
      {
        subIconImage: AutoSub,
        name: "Auto Insurances",
        path: "/auto-insurances",
        sub: "Auto Insurance",
        sub1: "auto_insurances",
        component: AutoInsurance,
        model: "AutoInsurance",
      },
      {
        subIconImage: DisabilitySub,
        name: "Disability Insurances",
        path: "/disability-insurances",
        sub: "Disability Insurance",
        sub1: "disability_insurances",
        component: DisabilityInsurance,
        model: "DisabilityInsurance",
      },
      {
        subIconImage: HealthSub,
        name: "Health Insurances",
        path: "/health-insurances",
        sub: "Health Insurance",
        sub1: "health_insurances",
        component: HealthInsurance,
        model: "HealthInsurance",
      },
      {
        subIconImage: LifeSub,
        name: "Life Insurances",
        path: "/life-insurances",
        sub: "Life Insurance",
        sub1: "life_insurances",
        component: LifeInsurance,
        model: "LifeInsurance",
      },
      {
        subIconImage: PropertySub,
        name: "Property Insurances",
        path: "/property-insurances",
        sub: "Property Insurance",
        sub1: "property_insurances",
        component: PropertyInsurance,
        model: "PropertyInsurance",
      },
      {
        subIconImage: OtherSub,
        name: "Other Insurances",
        path: "/other-insurances",
        sub: "Other Insurance",
        sub1: "other_insurances",
        component: OtherInsurance,
        model: "OtherInsurance",
      },
    ],
  },
  {
    path: "/vehicle",
    name: "Vehicle",
    iconImage: Vehicle,
    component: Maps,
    layout: "/user",
    submenu: [
      {
        subIconImage: AutoServicesSub,
        name: "Auto Services",
        path: "/auto-services",
        component: AutoServices,
        sub: "Auto Service",
        sub1: "auto_service",
        model: "AutoService",
      },
      {
        subIconImage: VehiclesSub,
        name: "Vehicles",
        path: "/vehicles",
        component: Vehicles,
        sub: "Vehicle",
        sub1: "vehicle",
        model: "Vehicle",
      },
    ],
  },
  {
    path: "/property",
    name: "Property",
    iconImage: Property,
    component: NotificationsPage,
    layout: "/user",
    submenu: [
      {
        subIconImage: HousesSub,
        name: "Houses",
        path: "/houses",
        component: House,
        sub: "House",
        sub1: "house",
        model: "House",
      },
      {
        subIconImage: PItemsSub,
        name: "Personal Items",
        path: "/personal-items",
        component: PersonalItems,
        sub: "Personal Item",
        sub1: "personal_item",
        model: "PersonalItem",
      },
      {
        subIconImage: RentalsSub,
        name: "Rentals",
        path: "/rentals",
        component: Retails,
        sub: "Rental",
        sub1: "rental",
        model: "Rental",
      },
      {
        subIconImage: SerialNumbersSub,
        name: "Serial Numbers",
        path: "/serial-numbers",
        component: SerialNumbers,
        sub: "Serial Number",
        sub1: "serial_number",
        model: "SerialNumber",
      },
    ],
  },
  {
    path: "/education",
    name: "Education",
    iconImage: EducationIcon,
    component: NotificationsPage,
    layout: "/user",
    submenu: [
      {
        subIconImage: CertificatesSub,
        name: "Certificates",
        path: "/certificates",
        sub: "Certificate",
        sub1: "certificate",
        component: Certificates,
        model: "Certificate",
      },
      {
        subIconImage: LanguagesSub,
        name: "Languages",
        path: "/languages",
        sub: "Language",
        sub1: "language",
        component: Languages,
        model: "Language",
      },
      {
        subIconImage: EducationSub,
        name: "Educations",
        path: "/education",
        sub: "Education",
        sub1: "education",
        component: Education,
        model: "Education",
      },
    ],
  },
];

export const authRoutes = [
  {
    path: "/register",
    name: "Register",
    // icon: Notifications,
    component: RegisterPage,
    layout: "/auth",
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    // icon: Notifications,
    component: ResetPasswordVerification,
    layout: "/auth",
  },
  {
    path: "/otp",
    name: "OTP",
    // icon: Notifications,
    component: Otp,
    layout: "/auth",
  },
  {
    path: "/login",
    name: "Login",
    // icon: Notifications,
    component: LoginPage,
    layout: "/auth",
  },
  {
    path: "/mail-success",
    name: "RegisterEmail",
    // icon: Notifications,
    component: RegisterEmail,
    layout: "/auth",
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    // icon: Notifications,
    component: ResetPassword,
    layout: "/auth",
  },
  {
    path: "/reset-password-mail-success",
    name: "Reset Password Mail",
    // icon: Notifications,
    component: MailSuccessResetPassword,
    layout: "/auth",
  },
];

export default dashboardRoutes;
