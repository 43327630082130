import "date-fns";
import React, { useState, useRef } from "react";
import debounce from "lodash.debounce";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  useTheme,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { FinanceService } from "../../../services/finance.service.js";
import AddDocument from "../AddDocument/AddDocument";
import ChooseWebLogins from "../ChooseWebLogins/ChooseWebLogins.js";

const taxYear = [
  "2027",
  "2026",
  "2025",
  "2024",
  "2023",
  "2022",
  "2021",
  "2020",
  "2019",
  "2018",
  "2017",
  "2016",
  "2015",
  "2014",
  "2013",
  "2012",
  "2011",
  "2010",
  "2009",
  "2008",
  "2007",
  "2006",
  "2005",
  "2004",
  "2003",
  "2002",
  "2000",
  "1999",
  "1998",
  "1997",
  "1996",
  "1995",
  "1994",
  "1993",
  "1992",
  "1991",
  "1990",
  "1989",
  "1988",
  "1987",
  "1986",
  "1985",
  "1984",
  "1983",
  "1982",
  "1981",
  "1980",
];

const stateArray = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Federated States of Micronesia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Marshall Islands",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palau",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Island",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles(styles);

function AddTaxReturns(props) {
  const classes = useStyles();
  const errRef = useRef(null);
  const financeService = new FinanceService();
  const [err, setErr] = React.useState([]);
  const [doc, setDoc] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [chooseState, setChooseState] = useState("federal");
  const [chooseActual, setChooseActual] = useState("actual");
  const [webLogin, setWebLogin] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [webLoginModal, setWebLoginModal] = React.useState(false);
  const [data, setData] = React.useState({
    federal: true,
    actual: true,
    quarter: "",
    tax_year: "",
    owner: "Self",
    state: "false",
    year: "",
    amount: "",
    notes: "",
    attachments: "",
    associated_logins_attributes: [
      {
        web_login_id: null,
      },
    ],
    associated_documents_attributes: [],
  });

  const [documentModal, setDocumentModal] = React.useState(false);

  const handleDocumentModal = () => {
    setDocumentModal(true);
  };
  const handleSubmit = () => {
    let formData = new FormData();
    formData.append("federal", data.federal);
    formData.append("actual", data.actual);
    formData.append("quarter", data.quarter);
    formData.append("tax_year", data.tax_year);
    formData.append("owner", data.owner);
    formData.append("state", data.state);
    formData.append("year", data.year);
    formData.append("amount", data.amount);
    formData.append("notes", data.notes);
    if (data.associated_logins_attributes[0].web_login_id) {
      formData.append(
        "associated_logins_attributes[][web_login_id]",
        JSON.stringify(data.associated_logins_attributes[0].web_login_id)
      );
    }

    if (data.associated_documents_attributes?.length > 0) {
      for (let i = 0; i < data.associated_documents_attributes?.length; i++) {
        formData.append(
          "associated_documents_attributes[][document_id]",
          data.associated_documents_attributes[i]?.document_id ||
            data.associated_documents_attributes[i]?.id
        );
      }
    }
    setIsLoading(true);
    financeService
      .createTaxReturns(formData)
      .then((response) => {
        setIsLoading(false);
        props.forceRerender();
        props.handleClose();

        console.log(response.data);
      })
      .catch((err) => {
        errRef.current.scrollIntoView();
        setIsLoading(false);
        let str = [];
        if (err.response) {
          Object.keys(err?.response?.data?.message).forEach((key) => {
            let value = err?.response?.data?.message[key];
            str.push(key?.replace("_", " ") + " " + value);
          });
          setErr(str);
        } else {
          setErr(["Error occurred while creating Tax Returns"]);
        }
      });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChange = debounce((event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  }, 300);

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChangeCountry = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Add New Tax Return"}
          color={color.white}
        />
        <div className={classes.modalBody} ref={errRef}>
          <div className="w-100 d-flex flex-row mb-4">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              {"Finance > Tax Returns > New"}
            </Typography>
          </div>
          {err.length > 0 && (
            <div className="w-100 d-flex flex-row mb-4">
              {err.map((e) => {
                return (
                  <Typography
                    variant="p"
                    component="p"
                    align="left"
                    style={{ color: "red", fontWeight: "normal" }}
                  >
                    {e}
                  </Typography>
                );
              })}
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Federal or State *"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="federal"
                  name="federal"
                  value={chooseState}
                  onChange={(e) => {
                    setChooseState(e.target.value);
                    if (e.target.value === "federal") {
                      setData({ ...data, federal: true, state: "false" });
                    } else {
                      setData({ ...data, federal: false });
                    }
                  }}
                  className="flex-row"
                >
                  <FormControlLabel
                    value="federal"
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Federal"
                  />
                  <FormControlLabel
                    value="state"
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="State"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {chooseState === "state" && (
              <>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"State *"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <FormControl id="security-question" fullWidth={true}>
                    <Select
                      id="state"
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      required={true}
                      onChange={(e) => {
                        setData({
                          ...data,
                          state: e.target.value,
                          federal: false,
                        });
                      }}
                      name="license_state"
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      MenuProps={MenuProps}
                    >
                      <MenuItem disabled>Select State</MenuItem>
                      {stateArray?.map((state, index) => {
                        return (
                          <MenuItem
                            value={state}
                            key={index}
                            style={getStyles(name, personName, theme)}
                          >
                            {state}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {chooseActual === "actual" ? "Tax year" : "Year"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  id="state"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  onChange={(e) => {
                    if (chooseActual === "actual") {
                      setData({ ...data, tax_year: e.target.value });
                    } else {
                      setData({ ...data, year: e.target.value });
                    }
                  }}
                  name="tax_year"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  <MenuItem disabled>Please Select</MenuItem>
                  {taxYear?.map((state, index) => {
                    return (
                      <MenuItem
                        value={state}
                        key={index}
                        style={getStyles(name, personName, theme)}
                      >
                        {state}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} sm={4} md={4}>
                            <Typography
                                variant="h6"
                                component="h3"
                                style={{color: color.themeColorNew}}
                            >
                                {"Precision"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8}>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="actual"
                                    name="actual"
                                    value={chooseActual}
                                    onChange={(e) => {
                                        setChooseActual(e.target.value);
                                        if (e.target.value === "estimate") {
                                            setData({...data, actual: false});
                                        } else {
                                            setData({...data, actual: true});
                                        }
                                    }}
                                    className="flex-row"
                                >
                                    <FormControlLabel
                                        value="actual"
                                        control={<Radio style={{color: "#ffb722"}}/>}
                                        label="Actual"
                                    />
                                    <FormControlLabel
                                        value="estimate"
                                        control={
                                            <Radio style={{color: "#ffb722", marginLeft: "7px"}}/>
                                        }
                                        label="Estimate"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {chooseActual === "estimate" && (
                            <>
                                <Grid item xs={12} sm={12} md={12} className="pb-0">
                                    <Typography
                                        variant="h6"
                                        component="h3"
                                        style={{color: color.themeColorNew}}
                                    >
                                        {"Quarter"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} sm={12}>
                                    <FormControl id="security-question" fullWidth={true}>
                                        <Select
                                            id="state"
                                            variant="outlined"
                                            fullWidth={true}
                                            margin="dense"
                                            required={true}
                                            onChange={(e) => {
                                                setData({
                                                    ...data,
                                                    quarter: e.target.value,
                                                    year: data.tax_year,
                                                });
                                            }}
                                            name="license_state"
                                            displayEmpty
                                            inputProps={{"aria-label": "Without label"}}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem disabled>Select Quarter</MenuItem>
                                            <MenuItem
                                                value={1}
                                                style={getStyles(name, personName, theme)}
                                            >
                                                {1}
                                            </MenuItem>
                                            <MenuItem
                                                value={2}
                                                style={getStyles(name, personName, theme)}
                                            >
                                                {2}
                                            </MenuItem>
                                            <MenuItem
                                                value={3}
                                                style={getStyles(name, personName, theme)}
                                            >
                                                {3}
                                            </MenuItem>
                                            <MenuItem
                                                value={4}
                                                style={getStyles(name, personName, theme)}
                                            >
                                                {4}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </>
                        )} */}

            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Amount Due"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                id="class"
                name="amount"
                onChange={handleChange}
                label=""
                variant="outlined"
                type="number"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Amount"}
                inputProps={{
                  maxlength: 8,
                  step: 1,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Notes"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                id="class"
                name="notes"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Notes"}
              />
              {documentModal && (
                <AddDocument
                  setData={setData}
                  setDoc={setDoc}
                  associated_documents_attributes={false}
                  doc={doc}
                  data={data}
                  open={documentModal}
                  handleClose={() => setDocumentModal(false)}
                  closeModal={() => setDocumentModal(false)}
                  category={"Finance"}
                />
              )}
              {webLoginModal && (
                <ChooseWebLogins
                  setData={setData}
                  setWebLogin={setWebLogin}
                  data={data}
                  open={webLoginModal}
                  webLogin={data?.associated_logins_attributes[0]?.web_login_id}
                  handleClose={() => setWebLoginModal(false)}
                  closeModal={() => setWebLoginModal(false)}
                  category={"Personal"}
                />
              )}
            </Grid>
            {webLogin && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Web Login"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {webLogin?.user_name}
                  </Typography>
                  <CustomButton
                    btnText={"Remove"}
                    textAlign="center"
                    display={"block"}
                    background="transparent"
                    hoverBg="#0042a8"
                    border="1px solid #0042a8"
                    borderRadius="8px"
                    color="#0042a8"
                    onClick={() => {
                      setWebLogin(null);
                      setData({
                        ...data,
                        associated_logins_attributes: [
                          {
                            web_login_id: null,
                          },
                        ],
                      });
                    }}
                  />
                </Grid>
              </>
            )}

            {doc.length > 0 && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Document"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                  {doc.map((e, k) => (
                    <>
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: color.themeColorNew }}
                        key={k}
                      >
                        {e?.title}
                      </Typography>
                      <CustomButton
                        btnText={"Remove"}
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          let newDoc = doc?.filter((d) => d.id !== e.id);
                          setDoc(newDoc);
                          setData({
                            ...data,
                            associated_documents_attributes: newDoc,
                          });
                        }}
                      />
                    </>
                  ))}
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              {!webLogin && (
                <CustomButton
                  className="mb-sm-2"
                  btnText="Add Web Login"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  marginRight={"10px"}
                  onClick={() => setWebLoginModal(true)}
                />
              )}
              <CustomButton
                btnText="Add Document"
                className="mb-sm-2"
                textAlign="center"
                display={"block"}
                padding="8px 25px"
                background="#0042a8"
                hoverBg="#0042a8"
                border="0"
                borderRadius="8px"
                color="#fff"
                marginRight={"10px"}
                onClick={handleDocumentModal}
              />
              <a
                ref={errRef}
                href={"#err"}
                style={{
                  display: "none",
                }}
              >
                click
              </a>
              {!isLoading ? (
                <CustomButton
                  type="submit"
                  btnText="Save"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  onClick={handleSubmit}
                  disabled={!data.federal && data.state === "false"}
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTaxReturns);
