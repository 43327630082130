import React, { useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "../components/Navbars/Navbar";
import Sidebar from "../components/Sidebar/Sidebar.js";
import routes from "../routes.js";
import styles from "../assets/jss/material-dashboard-react/layouts/adminStyle.js";
import { store } from "../store/store";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import bgImage from "../assets/img/sidebar-2.jpg";
import logo from "../assets/img/reactlogo.png";
import Profile from "../views/Profile/Profile";
import { ProfileService } from "../services/profile.service";
import { index } from "../store/actions/profileAction";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchResult from "../components/SearchResult/SearchResult";
import { Typography } from "@material-ui/core";
import "intro.js/introjs.css";
import {
  logout,
  setCurrentUser,
  setError,
  setLoginAnimationFalse,
  setUserCount,
} from "../store/actions/authAction";
import { useIdleTimer } from "react-idle-timer";
import { CustomButton } from "../components/CustomButton/Button";
import { sort } from "../store/actions/sortAction";
import AddProfile from "../containers/Modals/AddProfile/AddProfile";
import { LoggerService } from "../services/logger.service";
import Permission from "../views/Permission/Permission";
import Dashboard from "../views/Dashboard/Dashboard";
import ArrowDownwardOutlinedIcon from "@material-ui/icons/ArrowDownwardOutlined";
import ArrowUpwardOutlinedIcon from "@material-ui/icons/ArrowUpwardOutlined";
import Lottie from "react-lottie";
import animationData from "../assets/animations/AfterLogin.json";
import Tour from "../components/Tour/Tour";
import DisableAutoFill from "../components/DisableAutoFill";
import _default from "../config/default.js";
import AlertPopup from "../containers/Modals/Alerts/Generic/GenericAlert";
import { isExpired } from "../store/actions/loadingAction.js";
import ShareProfile from "../views/ShareProfile/ShareProfile.js";

const profile = new ProfileService();

let ps;

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      exact
      render={(props) =>
        store?.getState()?.auth?.isAuthenticated ? (
          <Component {...rest} />
        ) : (
          <Redirect
            to={{ pathname: "/auth/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
}

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/user") {
        return prop?.submenu?.map((menu, index) => {
          return (
            <PrivateRoute
              path={prop.layout + prop.path + menu.path}
              component={menu?.component}
              key={index}
              exact
            />
          );
        });
      }
      return null;
    })}
    <PrivateRoute
      path="/user/profile/account-options"
      exact
      component={Profile}
    />
    <PrivateRoute
      path="/user/profile/payment-methods"
      exact
      component={Profile}
    />
    <PrivateRoute
      path="/user/profile/activity-history"
      exact
      component={Profile}
    />
    <PrivateRoute
      path="/user/profile/share-history"
      exact
      component={Profile}
    />
    <PrivateRoute
      path="/user/share-profile"
      exact
      component={ShareProfile}
    />
    <PrivateRoute path="/user/profile/reports" exact component={Profile} />
    <PrivateRoute path="/user/profile/info" exact component={Profile} />
    <PrivateRoute path="/user/permission" exact component={Permission} />
    <PrivateRoute path="/user/favorite" exact component={Dashboard} />
    <PrivateRoute path="/user/recently-viewed" exact component={Dashboard} />
    <PrivateRoute path="/user/all-documents" exact component={Dashboard} />
    <PrivateRoute path="/user/all-contacts" exact component={Dashboard} />
    <PrivateRoute path="/user/active-alerts" exact component={Dashboard} />
    <PrivateRoute path="/user/received-documents" exact component={Dashboard} />
    <Redirect exact from="/user" to={"/user/favorite"} />
    <Redirect exact from="/user/profile" to={"/user/profile/account-options"} />
    <Redirect
      from="/user/personal"
      to="/user/personal/alternate-identifications"
    />
    <Redirect from="/user/employment" to="/user/employment/skills" />
    <Redirect path="/user/health" to={"/user/health/family-conditions"} />
    <Redirect from="/user/finance" to="/user/finance/cash-accounts" />
    <Redirect from="/user/insurance" to="/user/insurance/auto-insurance" />
    <Redirect from="/user/vehicle" to="/user/vehicle/vehicles" />
    <Redirect from="/user/property" to="/user/property/houses" />
    <Redirect path="/user/education" to="/user/education/certificates" />
  </Switch>
);
const useStyles = makeStyles(styles);
const loggerService = new LoggerService();

const globalCancelTokenSource = _default.Axios.CancelToken.source();

// Set up the interceptor with the global cancel token
_default.Axios.interceptors.request.use((config) => {
  // Associate the global cancel token with each request
  config.cancelToken = globalCancelTokenSource.token;
  return config;
});

// _default.Axios.interceptors.request.use(
//   (response) => response,
//   (error) => {
//     if (
//       error.response.status === 401 &&
//       !history.location.pathname?.includes("auth")
//     ) {
//       store.dispatch(logout(history));
//     } else if (error.response.status === 403) {
//       // Cancel all ongoing requests using the global cancel token
//     // globalCancelTokenSource.cancel('Request canceled due to 403 error');
//       const user = {
//         ...store.getState().auth?.user,
//         is_subscribed: false,
//       };
//       store.dispatch(setCurrentUser(user));
//       store.dispatch(isExpired(true));
//       console.log("403 is here")
//       // setOpenAlert(true);
//       // return Promise.reject(error)
//     } else {
//       return Promise.reject(error);
//     }
//   }
// );

function Admin(
  {
    sort,
    user,
    profiles,
    sortType,
    sortOrder,
    setLoginAnimationFalse,
    loginAnimation,
    expired,
    ...rest
  },
  props
) {
  const history = useHistory();

  if (!history.location.pathname.includes("/auth")) {
    _default.Axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          store.dispatch(logout(history));
        } else if (error.response.status === 403 && error.response.data && !error.response.data.message ) {
          // Cancel all ongoing requests using the global cancel token
          // globalCancelTokenSource.cancel('Request canceled due to 403 error');
          const user = {
            ...store.getState().auth?.user,
            is_subscribed: false,
          };
          store.dispatch(setCurrentUser(user));
          store.dispatch(isExpired(true));
          console.log("403 is here");
          _default.Axios.CancelToken.source().cancel(
            "Operation canceled due to 403 response."
          );
          // setOpenAlert(true);
          // return Promise.reject(error)
        } else {
          return Promise.reject(error);
        }
      }
    );
  }
  // Create a global cancel token source
  const globalCancelTokenSource = _default.Axios.CancelToken.source();
  const [desktopOpen, setDesktopOpen] = React.useState(false);
  // Set up the interceptor with the global cancel token
  _default.Axios.interceptors.request.use((config) => {
    // Associate the global cancel token with each request
    config.cancelToken = globalCancelTokenSource.token;
    return config;
  });

  const handleOnIdle = (event) => {
    if (store.getState().auth?.user?.remember_created_at === null) {
      store.dispatch(logout(history, { bool: true }));
      store.dispatch(
        setError("Your session has been expired due to inactivity.")
      );
    }
  };
  const [openAddProfile, setOpenAddProfile] = React.useState(false);
  // const [openAddProfile, setOpenAddProfile] = React.useState(
  //   user?.sign_in_count === 1 ? true : false
  // );

  const handleOnActive = (event) => {};

  const handleOnAction = (event) => {};

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 50000,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  const [tour, setTour] = useState(false);
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [initialStep, setInitialStep] = React.useState(0);
  const [isOpened, setIsOpened] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isPaused, setIsPaused] = React.useState(false);
  const open = Boolean(anchorEl);

  function toggle() {
    setIsOpened(!isOpened);
  }

  const handleClose = () => {
    toggle();
    setAnchorEl(null);
  };
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setDesktopOpen(!desktopOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const handleDrawerToggleMobile = () => {
    setMobileOpen(!mobileOpen);
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (!user?.is_subscribed) {
      history.push("/auth/subscriptions");
    }
  }, []);

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    (process.env.NODE_ENV === "production" ||
      process.env.REACT_APP_ENV === "STAGING") &&
      loggerService.GlobalDebug(false);

    store.dispatch(index());
    setTimeout(() => {
      if (user?.sign_in_count <= 1) {
        setOpenAddProfile(true);
      } else {
      }
    }, 2000);
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    // setIsPaused(true);
    // setTimeout(() => {
    //   setIsPaused(false);
    //   setLoginAnimationFalse(false);
    // }, 4000);
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
      // store.dispatch(logout(history, {bool: true}));
      // store.dispatch(isExpired(false))
    };
  }, [props?.search, sortType]);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      {tour && (
        <Tour
          setTour={setTour}
          initialStep={initialStep}
          setInitialStep={(number) => setInitialStep(number)}
          changeCount={() => store.dispatch(setUserCount(2))}
        />
      )}
      {/* {isPaused === true && store.getState().auth.loginAnimation ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#00BED6",
            position: "fixed",
            zIndex: 1000000,
            top: 0,
            left: 0,
          }}
        >
          <Lottie
            options={defaultOptions}
            // isStopped={isPaused}
            // isPaused={isPaused}
          />
        </div>
      ) : null} */}

      {expired && (
        <AlertPopup
          btnText={"Yes"}
          cancelText={"No"}
          open={expired}
          type={"Warning"}
          heading={"Subscription"}
          message={
            "Your subscription has expired or your trial period is over.  You need to subscribe to continue using TagData.  Would you like to continue?"
          }
          isLoading={false}
          handleClose={() => {
            store.dispatch(logout(history));
          }}
          handleClick={() => {
            history.push("/auth/subscriptions");
          }}
        />
      )}

      <div className={classes.wrapper + " dashboard"}>
        <DisableAutoFill />
        <Sidebar
          routes={routes}
          logo={logo}
          image={image}
          plusToggle={toggle}
          plusAchor={setAnchorEl}
          plusOpen={isOpened}
          rtlActivee={window.innerWidth <= 960}
          // image={undefined}
          handleDrawerToggle={handleDrawerToggle}
          handleDrawerToggleMobile={handleDrawerToggleMobile}
          open={desktopOpen}
          openMobile={mobileOpen}
          color={color}
          {...rest}
        />
        <div className={classes.mainPanel} ref={mainPanel}>
          <Navbar
            open={open}
            isOpened={isOpened}
            toggle={toggle}
            anchorEl={anchorEl}
            openMobile={mobileOpen}
            setAnchorEl={setAnchorEl}
            setIsOpened={setIsOpened}
            handleClose={handleClose}
            routes={routes}
            handleDrawerToggle={handleDrawerToggle}
            handleDrawerToggleMobile={handleDrawerToggleMobile}
            {...rest}
          />
          {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          <div className={classes.content}>
            {openAddProfile && (
              <AddProfile
                open={openAddProfile}
                handleCloseAddProfile={() => {
                  setOpenAddProfile(false);
                  setTour(true);
                }}
                closeModal={() => {
                  setOpenAddProfile(false);
                  setTour(true);
                }}
              />
            )}
            {store.getState().search.query ? (
              <>
                {store?.getState()?.search?.loading ? (
                  <CircularProgress size={20} color="#DDD" />
                ) : !store?.getState()?.search?.loading &&
                  store?.getState()?.search?.error ? (
                  <Typography>{store?.getState()?.search?.error}</Typography>
                ) : (
                  <SearchResult results={store?.getState()?.search?.results} />
                )}
              </>
            ) : (
              <>
                {window.location.pathname !== "/user/profile/info" &&
                window.location.pathname !== "/user/profile/reports" &&
                window.location.pathname !== "/user/profile/account-options" &&
                window.location.pathname !== "/user/profile/payment-methods" ? (
                  <div className="d-flex flex-row align-items-center flex-wrap">
                    <h6 className="text-capitalize fw-bold mb-0 me-2">
                      sort:{" "}
                    </h6>
                    <CustomButton
                      onClick={() =>
                        sort("alpha", sortOrder === "asc" ? "dsc" : "asc")
                      }
                      btnText="Alphabetical"
                      className={`${
                        sortType === "alpha"
                          ? "shadow-none p-0 me-2 color_dark"
                          : "shadow-none p-0 me-2 color_dark"
                      }`}
                      cursor="pointer"
                      hoverBg="transparent"
                      background="transparent"
                      style={{ marginLeft: "4px" }}
                      rightIcon={
                        <div style={{ marginLeft: "2px", marginBottom: "4px" }}>
                          <ArrowUpwardOutlinedIcon
                            style={{
                              fontSize: "15px",
                              color:
                                sortType === "alpha" && sortOrder === "asc"
                                  ? "#ffb722"
                                  : "#060d5c",
                            }}
                          />
                          <ArrowDownwardOutlinedIcon
                            style={{
                              fontSize: "15px",
                              color:
                                sortType === "alpha" && sortOrder !== "asc"
                                  ? "#ffb722"
                                  : "#060d5c",
                            }}
                          />
                        </div>
                      }
                    />
                    <CustomButton
                      onClick={() =>
                        sort("date", sortOrder === "asc" ? "dsc" : "asc")
                      }
                      btnText="Date"
                      style={{ marginLeft: "4px" }}
                      className={`${
                        sortType === "date"
                          ? "shadow-none p-0 me-2 color_dark"
                          : "shadow-none p-0 me-2 color_dark"
                      }`}
                      cursor="pointer"
                      hoverBg="transparent"
                      background="transparent"
                      variat="text"
                      rightIcon={
                        <div style={{ marginLeft: "2px", marginBottom: "4px" }}>
                          <ArrowUpwardOutlinedIcon
                            style={{
                              fontSize: "15px",
                              color:
                                sortType === "date" && sortOrder === "asc"
                                  ? "#ffb722"
                                  : "#060d5c",
                            }}
                          />
                          <ArrowDownwardOutlinedIcon
                            style={{
                              fontSize: "15px",
                              color:
                                sortType === "date" && sortOrder !== "asc"
                                  ? "#ffb722"
                                  : "#060d5c",
                            }}
                          />
                        </div>
                      }
                    />

                    {window.location.pathname === "/user/favorite" && (
                      <CustomButton
                        onClick={() =>
                          sort("count", sortOrder === "asc" ? "dsc" : "asc")
                        }
                        btnText="Count"
                        style={{ marginLeft: "4px" }}
                        className={`${
                          sortType === "count"
                            ? "shadow-none p-0 me-2 color_dark"
                            : "shadow-none p-0 me-2 color_dark"
                        }`}
                        cursor="pointer"
                        hoverBg="transparent"
                        background="transparent"
                        variat="text"
                        rightIcon={
                          <div
                            style={{ marginLeft: "2px", marginBottom: "4px" }}
                          >
                            <ArrowUpwardOutlinedIcon
                              style={{
                                fontSize: "15px",
                                color:
                                  sortType === "count" && sortOrder === "asc"
                                    ? "#ffb722"
                                    : "#060d5c",
                              }}
                            />
                            <ArrowDownwardOutlinedIcon
                              style={{
                                fontSize: "15px",
                                color:
                                  sortType === "count" && sortOrder !== "asc"
                                    ? "#ffb722"
                                    : "#060d5c",
                              }}
                            />
                          </div>
                        }
                      />
                    )}
                    {window.location.pathname === "/user/all-documents" && (
                      <CustomButton
                        onClick={() =>
                          sort("size", sortOrder === "asc" ? "dsc" : "asc")
                        }
                        btnText="Document size"
                        style={{ marginLeft: "4px" }}
                        className={`${
                          sortType === "size"
                            ? "shadow-none p-0 me-2 color_dark"
                            : "shadow-none p-0 me-2 color_dark"
                        }`}
                        cursor="pointer"
                        variat="text"
                        hoverBg="transparent"
                        background="transparent"
                        rightIcon={
                          <div
                            style={{ marginLeft: "2px", marginBottom: "4px" }}
                          >
                            <ArrowUpwardOutlinedIcon
                              style={{
                                fontSize: "15px",
                                color:
                                  sortType === "size" && sortOrder === "asc"
                                    ? "#ffb722"
                                    : "#060d5c",
                              }}
                            />
                            <ArrowDownwardOutlinedIcon
                              style={{
                                fontSize: "15px",
                                color:
                                  sortType === "size" && sortOrder !== "asc"
                                    ? "#ffb722"
                                    : "#060d5c",
                              }}
                            />
                          </div>
                        }
                      />
                    )}
                    {window.location.pathname?.includes("web-logins") && (
                      <CustomButton
                        onClick={() =>
                          sort(
                            "mostViewed",
                            sortOrder === "asc" ? "dsc" : "asc"
                          )
                        }
                        btnText="Most Viewed"
                        style={{ marginLeft: "4px" }}
                        className={`${
                          sortType === "mostViewed"
                            ? "shadow-none p-0 me-2 color_dark"
                            : "shadow-none p-0 me-2 color_dark"
                        }`}
                        cursor="pointer"
                        hoverBg="transparent"
                        background="transparent"
                        rightIcon={
                          <div
                            style={{ marginLeft: "2px", marginBottom: "4px" }}
                          >
                            <ArrowUpwardOutlinedIcon
                              style={{
                                fontSize: "15px",
                                color:
                                  sortType === "mostViewed" &&
                                  sortOrder === "asc"
                                    ? "#ffb722"
                                    : "#060d5c",
                              }}
                            />
                            <ArrowDownwardOutlinedIcon
                              style={{
                                fontSize: "15px",
                                color:
                                  sortType === "mostViewed" &&
                                  sortOrder !== "asc"
                                    ? "#ffb722"
                                    : "#060d5c",
                              }}
                            />
                          </div>
                        }
                      />
                    )}
                    {user?.sign_in_count <= 5 && (
                      <div
                        onClick={() => setTour(true)}
                        className="tooltip__a"
                        style={{
                          position: "absolute",
                          right: "0",
                          top: "40%",
                          cursor: "pointer",
                        }}
                      >
                        <EmojiObjectsIcon
                          style={{
                            color: "#ffde23",
                            fontSize: "3.3rem",
                          }}
                        />
                        <span className="tooltiptext__a">Guide Tour</span>
                      </div>
                    )}
                  </div>
                ) : null}
                <div className={classes.container}>{switchRoutes}</div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    search: state.search.query,
    loading: state?.search?.loading,
    expired: state?.loading?.expired,
    err: state?.search?.err,
    sortType: state?.sort?.sort,
    sortOrder: state?.sort?.order,
    user: state?.auth?.user,
    profiles: state?.profile?.profiles,
    loginAnimation: state?.auth?.loginAnimation,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    logout: (history) => {
      dispatch(logout(history));
    },
    setError: (msg) => {
      dispatch(setError(msg));
    },
    sort: (str, ord) => dispatch(sort(str, ord)),
    setLoginAnimationFalse: (e) => dispatch(setLoginAnimationFalse(e)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Admin);
