
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../../Card/Card";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { CustomButton } from "../../CustomButton/Button";
import AlertPrefrence from "../../../containers/Modals/AlertPrefrence/AlertPrefrence";
import EditEmailAddress from "../../../containers/Modals/EditEmailAddress/EditEmailAddress";
import EditPassword from "../../../containers/Modals/EditPassword/EditPassword";
import EditEmailPrefrence from "../../../containers/Modals/EditEmailPrefrence/EditEmailPrefrence";
import AddMasking from "../../../containers/Modals/AddMasking/AddMasking";
import SuccessGreen from "../../../containers/Modals/Alerts/SuccessGreen/SuccessGreen";
import Error from "../../../containers/Modals/Alerts/Error/Error";
import userProfileImg from "../../../../src/assets/img/account-settting.png";
import { MoreVert } from "@material-ui/icons";
import color from "../../../assets/css/color";

import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import AddUserPermissions from "../../../containers/Modals/AddUserPermissions/AddUserPermissions.js";
import EditAccess from "../../../containers/Modals/EditAccess/EditAccess.js";
import EditPermissions from "../../../containers/Modals/EditPermissions/EditPermissions.js";
import _default from "../../../config/default.js";

import userImg from "../../../assets/img/user.png";
import man from "../../../assets/img/man.png";
import CollaboratorList from "./CollaboratorList.js";
import CollaboratorAccordion from "./CollaboratorAccordion.js";
import ActivityHistory from "../../AccountSettingTabs/AccountSettingTabsContent/ActivityHistory.js";

// import MenuItem from "@material-ui/core/MenuItem";
const useStyles = makeStyles(styles);
export default function IndividualAccess(props) {
  const classes = useStyles();
  const [openError, setOpenError] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openAlertPrefrence, setOpenAlertPrefrence] = React.useState(false);
  const [openMasking, setOpenMasking] = React.useState(false);
  const [openEmailAddress, setOpenEmailAddress] = React.useState(false);
  const [openPassword, setOpenPassword] = React.useState(false);
  const [openAddNew, setOpenAddNew] = React.useState(false);
  const [openEditAccess, setOpenEditAccess] = React.useState(false);
  const [openEditPermissions, setOpenEditPermission] = React.useState(false);
  const [openEmailPrefrence, setOpenEmailPrefrence] = React.useState(false);
  console.log("props collaborator user", props.collaborator.users)
  

  const handleOpenAddNew = () => {
    setOpenAddNew(true);
    setOpen(false);
  };

  const handleCloseAddNew = () => {
    setOpenAddNew(false);
  };

  const handleOpenEditAccess = () => {
    setOpenEditAccess(true);
    setOpen(false);
  };

  const handleCloseEditAccess = () => {
    setOpenEditAccess(false);
  };

  const handleOpenEditPermissions = () => {
    setOpenEditPermission(true);
    setOpen(false);
  };

  const handleCloseEditPermissions = () => {
    setOpenEditPermission(false);
  };

  const closeModal = () => {
    setOpenAddNew(false);
    setOpenEditAccess(false);
    setOpenEditPermission(false);
  };

  useEffect(() => {}, []);
  const handleOpenAlertPrefrence = () => {
    setOpenAlertPrefrence(true);
  };
  const handleCloseAlertPrefrence = () => {
    setOpenAlertPrefrence(false);
  };
  const handleCloseEmailAddress = () => {
    setOpenEmailAddress(false);
  };
  const handleClosePassword = () => {
    setOpenPassword(false);
  };
  const handleCloseEmailPrefrence = () => {
    setOpenEmailPrefrence(false);
  };

  return (
    <>
      <Card className={classes.accountCard}>
        <Grid
          container
          alignContent="center"
          justifyContent="center"
          className={classes.marginBottom18px + " " + "mb-4"}
        >
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              component="h3"
              style={{ color: "#060d5c", fontWeight: "bold" }}
            >
              {"Collaborators"}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            container
            className="justify-content-sm-end justify-content-start mt-sm-0 mt-3"
          >
            <CustomButton
              btnText={"Add New"}
              padding="12px 20px"
              minWidth="113px"
              height="50px"
              background="#fff"
              hoverBg="#0042a8"
              color="#0042a8"
              border="2px solid #0042a8"
              borderRadius="8px"
              onClick={() => handleOpenAddNew()}
            />
          </Grid>
        </Grid>
        {openAddNew && (
          <AddUserPermissions
            open={openAddNew}
            handleClose={handleCloseAddNew}
            closeModal={closeModal}
            data={props.data}
          />
        )}
        <div className={classes.administratorTableResponsiveMain}>
          <Grid
            container
            className={`${classes.marginBottom18px} 
            ${classes.administratorTableResponsive}`}
          >
            <Grid item xs={4} md={4}>
              <Typography
                variant="p"
                component="p"
                className={`${classes.normalFontSize} ${classes.marginBottom14px}`}
                style={{ fontWeight: "bold" }}
              >
                {"User"}
              </Typography>
            </Grid>

            <Grid item xs={3} md={3}>
              <Typography
                variant="p"
                component="p"
                className={`${classes.normalFontSize} ${classes.marginBottom14px}`}
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                {"Permissions"}
              </Typography>
            </Grid>
            <Grid item xs={3} md={3}>
              <Typography
                variant="p"
                component="p"
                className={`${classes.normalFontSize} ${classes.marginBottom14px}`}
                style={{ fontWeight: "bold" }}
              >
                {"Last Active"}
              </Typography>
            </Grid>
            <Grid item xs={2} md={2}>
              <Typography
                variant="p"
                component="p"
                className={`${classes.normalFontSize} ${classes.marginBottom14px}`}
                style={{ fontWeight: "bold" }}
              >
                {"Status"}
              </Typography>
            </Grid>
          </Grid>
          
          {!props.loading &&
          props.collaborator.users &&
          props.collaborator.users.length > 0 ? (
            props.collaborator.users.map(
              (user, key) =>
                (user.status === "accepted" || user.status === "pending" || user.status === "inactive" || user.status === "expired") && (
                  <CollaboratorList key={key} user={user} deleteCollaborator={(id) => props.deleteCollaborator(id)} toggleCollaboratorStatus={(id, status) =>
                    props.toggleCollaboratorStatus(id, status)
                  }/>
                )
            )
          ) : props.loading ? (
            <CircularProgress size={40} color="secondary" />
          ) : props.err ? (
            "error occurred"
          ) : !props.loading && !props.err ? (
            "No Collaborators yet"
          ) : null}
          {/* <Grid
              container
              alignItems="center"
              className={`${classes.marginBottom18px} ${classes.administratorTableResponsive}`}
            >
              <Grid
                item
                xs={4}
                md={4}
                className="d-flex flex-row align-items-center"
              >
                <img
                  src={userProfileImg}
                  alt={"User Profile"}
                  height={65}
                  width={65}
                  className="rounded-pill me-3"
                  style={{ objectFit: "cover" }}
                />
                <Typography
                  variant="p"
                  component="p"
                  className={
                    classes.normalFontSize + " " + "me-5 mb-2 mb-md-0 mb-lg-0"
                  }
                  style={{ color: "#060d5c", fontWeight: "bold" }}
                >
                  {"Adam Driver"}
                </Typography>
              </Grid>
              <Grid item xs={3} md={3}>
                <CustomButton
                  className="shadow-none text-decoration-underline border-0"
                  btnText={"Edit"}
                  padding="8px 25px"
                  minWidth="113px"
                  background="transparent"
                  hoverBg="transparent"
                  color="#0042a8!important"
                />
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography
                  variant="p"
                  component="p"
                  className={classes.normalFontSize}
                  style={{ fontWeight: "normal" }}
                >
                  {"20 mint ago"}
                </Typography>
              </Grid>
              <Grid item xs={2} md={2} className="d-flex flex-row">
                <Typography
                  variant="p"
                  component="p"
                  className={classes.normalFontSize}
                  style={{
                    color: "#ffb722",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  {"Active"}
                </Typography>
                <div className="">
                  <MoreVert
                    color="primary"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={() => handleToggle(key)}
                    style={{
                      cursor: "pointer",
                      color: color.themeYellow,
                    }}
                  />
                </div>

                <Popper
                  className="position-absolute dropMenuDocument"
                  open={openn}
                  anchorEl={props.anchorRef}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={props.handleClose}>
                          <MenuList id="split-button-menu">
                            {props.options.map((option, index) => (
                              <MenuItem
                                key={option}
                                // selected={index === selectedIndex}

                                onClick={(event) =>
                                  handleMenuItemClick(
                                    option,
                                    doc.id,
                                    config.BASE_URL + doc?.attachment
                                  )
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              className={`${classes.marginBottom18px} ${classes.administratorTableResponsive}`}
            >
              <Grid
                item
                xs={4}
                md={4}
                className="d-flex flex-row align-items-center"
              >
                <img
                  src={userProfileImg}
                  alt={"User Profile"}
                  height={65}
                  width={65}
                  className="rounded-pill me-3"
                  style={{ objectFit: "cover" }}
                />
                <Typography
                  variant="p"
                  component="p"
                  className={
                    classes.normalFontSize + " " + "me-5 mb-2 mb-md-0 mb-lg-0"
                  }
                  style={{ color: "#060d5c", fontWeight: "bold" }}
                >
                  {"Adam Driver"}
                </Typography>
              </Grid>
              <Grid item xs={3} md={3}>
                <CustomButton
                  className="shadow-none text-decoration-underline border-0"
                  btnText={"Edit"}
                  padding="8px 25px"
                  minWidth="113px"
                  background="transparent"
                  hoverBg="transparent"
                  color="#0042a8!important"
                />
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography
                  variant="p"
                  component="p"
                  className={classes.normalFontSize}
                  style={{ fontWeight: "normal" }}
                >
                  {"6 months ago"}
                </Typography>
              </Grid>
              <Grid item xs={2} md={2} className="d-flex flex-row">
                <Typography
                  variant="p"
                  component="p"
                  className={classes.normalFontSize}
                  style={{
                    color: "#ffb722",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  {"Active"}
                </Typography>
                <div className="">
                  <MoreVert
                    color="primary"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={() => handleToggle(key)}
                    style={{
                      cursor: "pointer",
                      color: color.themeYellow,
                    }}
                  />
                </div>

                <Popper
                  className="position-absolute dropMenuDocument"
                  open={openn}
                  anchorEl={props.anchorRef}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={props.handleClose}>
                          <MenuList id="split-button-menu">
                            {props.options.map((option, index) => (
                              <MenuItem
                                key={option}
                                // selected={index === selectedIndex}

                                onClick={(event) =>
                                  handleMenuItemClick(
                                    option,
                                    doc.id,
                                    config.BASE_URL + doc?.attachment
                                  )
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            </Grid> */}
        </div>
       
      </Card>
      {/* <CollaboratorAccordion  activities={props.collaborator.users}/> */}

      <AlertPrefrence
        open={openAlertPrefrence}
        handleClose={handleCloseAlertPrefrence}
      />

      {openError && (
        <Error
          open={openError}
          handleClose={() => setOpenError(false)}
          message="Error occurred while updating the timezone"
        />
      )}

      {open && (
        <SuccessGreen
          open={open}
          handleClose={() => setOpen(false)}
          message="Your timezone has been successfully updated"
        />
      )}

      <EditEmailAddress
        open={openEmailAddress}
        handleClose={handleCloseEmailAddress}
      />

      <EditPassword open={openPassword} handleClose={handleClosePassword} />

      <AddMasking
        open={openMasking}
        handleClose={() => setOpenMasking(false)}
      />

      <EditEmailPrefrence
        open={openEmailPrefrence}
        handleClose={handleCloseEmailPrefrence}
      />
    </>
  );
}
