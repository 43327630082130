import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Card from "../../Card/Card.js";
import {Grid, Typography} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import {CustomButton} from "../../CustomButton/Button.js";
import AlertPrefrence from "../../../containers/Modals/AlertPrefrence/AlertPrefrence.js";
import EditEmailAddress from "../../../containers/Modals/EditEmailAddress/EditEmailAddress.js";
import EditPassword from "../../../containers/Modals/EditPassword/EditPassword.js";
import EditEmailPrefrence from "../../../containers/Modals/EditEmailPrefrence/EditEmailPrefrence.js";
import AddMasking from "../../../containers/Modals/AddMasking/AddMasking.js";
import SuccessGreen from "../../../containers/Modals/Alerts/SuccessGreen/SuccessGreen.js";
import Error from "../../../containers/Modals/Alerts/Error/Error.js";
import userProfileImg from "../../../../src/assets/img/account-settting.png";
import {MoreVert} from "@material-ui/icons";
import color from "../../../assets/css/color.js";

import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import AddUserPermissions from "../../../containers/Modals/AddUserPermissions/AddUserPermissions.js";
// import MenuItem from "@material-ui/core/MenuItem";
const useStyles = makeStyles(styles);
export default function Administrators(props) {
    const classes = useStyles();
    const [openError, setOpenError] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [openn, setOpenn] = React.useState(false);
    const [openAlertPrefrence, setOpenAlertPrefrence] = React.useState(false);
    const [openMasking, setOpenMasking] = React.useState(false);
    const [openEmailAddress, setOpenEmailAddress] = React.useState(false);
    const [openPassword, setOpenPassword] = React.useState(false);
    const [openEmailPrefrence, setOpenEmailPrefrence] = React.useState(false);
    const [openAddNew, setOpenAddNew] = React.useState(false);
    const anchorRef = React.useRef(null);
    // const [data, setData] = React.useState({
    //   administrators: [
    //     {
    //       name: "",
    //       access_type: "",
    //       last_active: "",
    //       status: "",
    //     },
    //   ],
    // });


    const handleOpenAddNew = () => {
      setOpenAddNew(true);
      setOpen(false);
    };

  const handleCloseAddNew = () => {
    setOpenAddNew(false);
  };

  const closeModal = () => {
    setOpenAddNew(false);
  };

    const handleToggle = (key) => {
        // setExpanded(key);
        setOpenn((prevOpen) => !prevOpen);
    };

    useEffect(() => {
    }, []);
    const handleOpenAlertPrefrence = () => {
        setOpenAlertPrefrence(true);
    };
    const handleCloseAlertPrefrence = () => {
        setOpenAlertPrefrence(false);
    };
    const handleCloseEmailAddress = () => {
        setOpenEmailAddress(false);
    };
    const handleClosePassword = () => {
        setOpenPassword(false);
    };
    const handleCloseEmailPrefrence = () => {
        setOpenEmailPrefrence(false);
    };
    return (
      <>
        <Card className={classes.accountCard}>
          <Grid
            container
            alignContent="center"
            justifyContent="center"
            className={classes.marginBottom18px + " " + "mb-4"}
          >
            <Grid item xs={12} md={6}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: "#060d5c", fontWeight: "bold" }}
              >
                {"Collaborators"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              container
              className="justify-content-sm-end justify-content-start mt-sm-0 mt-3"
            >
              <CustomButton
                btnText={"Add New"}
                padding="12px 20px"
                minWidth="113px"
                height="50px"
                background="#fff"
                hoverBg="#0042a8"
                color="#0042a8"
                border="2px solid #0042a8"
                borderRadius="8px"
                onClick={() => handleOpenAddNew()}
              />
            </Grid>
          </Grid>
          {openAddNew && (
            <AddUserPermissions
              open={openAddNew}
              handleClose={handleCloseAddNew}
              closeModal={closeModal}
            />
          )}
          {/* <Grid container className={classes.marginBottom18px}>
            <Grid item xs={12} md={12}>
              <Typography
                variant="p"
                component="p"
                className={`${classes.normalFontSize} ${classes.marginBottom14px}`}
                style={{
                  color: "#ffb722",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                {"Emergency Contact"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              container
              className="align-items-center mb-5 gap-2"
            >
              <img
                src={userProfileImg}
                alt={"User Profile"}
                height={65}
                width={65}
                className="rounded-pill me-3"
                style={{ objectFit: "cover" }}
              />
              <Typography
                variant="p"
                component="p"
                className={
                  classes.normalFontSize + " " + "me-5 mb-2 mb-md-0 mb-lg-0"
                }
                style={{ color: "#060d5c", fontWeight: "bold" }}
              >
                {"Email Preference"}
              </Typography>
              <CustomButton
                btnText={"Give Full Access"}
                padding="15px 25px"
                minWidth="113px"
                height="50px"
                background="#0042a8"
                hoverBg="#0042a8"
                color="#fff"
                borderRadius="8px"
              />
            </Grid>
          </Grid> */}


          
          <div className={classes.administratorTableResponsiveMain}>
            <Grid
              container
              className={`${classes.marginBottom18px} ${classes.administratorTableResponsive}`}
            >
              <Grid item xs={4} md={4}>
                <Typography
                  variant="p"
                  component="p"
                  className={`${classes.normalFontSize} ${classes.marginBottom14px}`}
                  style={{ fontWeight: "bold" }}
                >
                  {"User"}
                </Typography>
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography
                  variant="p"
                  component="p"
                  className={`${classes.normalFontSize} ${classes.marginBottom14px}`}
                  style={{ fontWeight: "bold" }}
                >
                  {"Access Type"}
                </Typography>
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography
                  variant="p"
                  component="p"
                  className={`${classes.normalFontSize} ${classes.marginBottom14px}`}
                  style={{ fontWeight: "bold" }}
                >
                  {"Last Active"}
                </Typography>
              </Grid>
              <Grid item xs={2} md={2}>
                <Typography
                  variant="p"
                  component="p"
                  className={`${classes.normalFontSize} ${classes.marginBottom14px}`}
                  style={{ fontWeight: "bold" }}
                >
                  {"Status"}
                </Typography>
              </Grid>
            </Grid>

            

            {collaborator.users &&
              collaborator.users.length > 0 &&
              collaborator.users.map((user) => (
                <Grid
                  container
                  alignItems="center"
                  className={`${classes.marginBottom18px} ${classes.administratorTableResponsive}`}
                >
                  <Grid
                    item
                    xs={4}
                    md={4}
                    className="d-flex flex-row align-items-center"
                  >
                    <img
                      src={userProfileImg}
                      alt={"User Profile"}
                      height={65}
                      width={65}
                      className="rounded-pill me-3"
                      style={{ objectFit: "cover" }}
                    />
                    <Typography
                      variant="p"
                      component="p"
                      className={
                        classes.normalFontSize +
                        " " +
                        "me-5 mb-2 mb-md-0 mb-lg-0"
                      }
                      style={{ color: "#060d5c", fontWeight: "bold" }}
                      key={user.name}
                    >
                      {user.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <CustomButton
                      className="shadow-sm"
                      btnText={user.access_type}
                      padding="8px 25px"
                      minWidth="113px"
                      background="#D6D6D6"
                      hoverBg="#0042a8"
                      color="#fff"
                      borderRadius="25em"
                    />
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <Typography
                      variant="p"
                      component="p"
                      className={classes.normalFontSize}
                      style={{ fontWeight: "normal" }}
                    >
                      {user.last_active}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    md={2}
                    className="d-flex flex-row position-relative"
                    style={{ zIndex: 1 }}
                  >
                    <Typography
                      variant="p"
                      component="p"
                      className={classes.normalFontSize}
                      style={{
                        color: "#ffb722",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                      }}
                    >
                      {user.status === "accepted" ? "Active" : "Disabled"}
                    </Typography>
                    <div className="">
                      <MoreVert
                        color="primary"
                        aria-controls={open ? "split-button-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={() => handleToggle()}
                        style={{
                          cursor: "pointer",
                          color: color.themeYellow,
                        }}
                      />
                    </div>

                    <Popper
                      className="position-absolute dropMenuDocument"
                      open={openn}
                      anchorEl={anchorRef}
                      //   role={undefined}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom"
                                ? "center top"
                                : "center bottom",
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={props.handleClose}>
                              <MenuList id="split-button-menu">
                                {props.options.map((option, index) => (
                                  <MenuItem
                                    key={option}
                                    // selected={index === selectedIndex}

                                    onClick={(event) =>
                                      handleMenuItemClick(
                                        option,
                                        doc.id,
                                        config.BASE_URL + doc?.attachment
                                      )
                                    }
                                  >
                                    {option}
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </Grid>
                </Grid>
              ))}
            {/* <Grid
              container
              alignItems="center"
              className={`${classes.marginBottom18px} ${classes.administratorTableResponsive}`}
            >
              <Grid
                item
                xs={4}
                md={4}
                className="d-flex flex-row align-items-center"
              >
                <img
                  src={userProfileImg}
                  alt={"User Profile"}
                  height={65}
                  width={65}
                  className="rounded-pill me-3"
                  style={{ objectFit: "cover" }}
                />
                <Typography
                  variant="p"
                  component="p"
                  className={
                    classes.normalFontSize + " " + "me-5 mb-2 mb-md-0 mb-lg-0"
                  }
                  style={{ color: "#060d5c", fontWeight: "bold" }}
                >
                  {"Adam Driver"}
                </Typography>
              </Grid>
              <Grid item xs={3} md={3}>
                <CustomButton
                  className="shadow-sm"
                  btnText={"Editor"}
                  padding="8px 25px"
                  minWidth="113px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  color="#fff"
                  borderRadius="25em"
                />
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography
                  variant="p"
                  component="p"
                  className={classes.normalFontSize}
                  style={{ fontWeight: "normal" }}
                >
                  {"20 mint ago"}
                </Typography>
              </Grid>
              <Grid item xs={2} md={2} className="d-flex flex-row">
                <Typography
                  variant="p"
                  component="p"
                  className={classes.normalFontSize}
                  style={{
                    color: "#ffb722",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  {"Active"}
                </Typography>
                <div className="position-relative">
                  <MoreVert
                    color="primary"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    style={{
                      cursor: "pointer",
                      color: color.themeYellow,
                    }}
                  />
                </div>

                <Popper
                  className="position-absolute dropMenuDocument"
                  open={openn}
                  anchorEl={props.anchorRef}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={props.handleClose}>
                          <MenuList id="split-button-menu">
                            {props.options.map((option, index) => (
                              <MenuItem
                                key={option}
                                // selected={index === selectedIndex}

                                onClick={(event) =>
                                  handleMenuItemClick(
                                    option,
                                    doc.id,
                                    config.BASE_URL + doc?.attachment
                                  )
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              className={`${classes.marginBottom18px} ${classes.administratorTableResponsive}`}
            >
              <Grid
                item
                xs={4}
                md={4}
                className="d-flex flex-row align-items-center"
              >
                <img
                  src={userProfileImg}
                  alt={"User Profile"}
                  height={65}
                  width={65}
                  className="rounded-pill me-3"
                  style={{ objectFit: "cover" }}
                />
                <Typography
                  variant="p"
                  component="p"
                  className={
                    classes.normalFontSize + " " + "me-5 mb-2 mb-md-0 mb-lg-0"
                  }
                  style={{ color: "#060d5c", fontWeight: "bold" }}
                >
                  {"Adam Driver"}
                </Typography>
              </Grid>
              <Grid item xs={3} md={3}>
                <CustomButton
                  className="shadow-sm"
                  btnText={"Owner"}
                  padding="8px 25px"
                  minWidth="113px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  color="#fff"
                  borderRadius="25em"
                />
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography
                  variant="p"
                  component="p"
                  className={classes.normalFontSize}
                  style={{ fontWeight: "normal" }}
                >
                  {"6 months ago"}
                </Typography>
              </Grid>
              <Grid item xs={2} md={2} className="d-flex flex-row">
                <Typography
                  variant="p"
                  component="p"
                  className={classes.normalFontSize}
                  style={{
                    color: "#ffb722",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  {"Active"}
                </Typography>
                <div className="">
                  <MoreVert
                    color="primary"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={() => handleToggle(key)}
                    style={{
                      cursor: "pointer",
                      color: color.themeYellow,
                    }}
                  />
                </div>

                <Popper
                  className="position-absolute dropMenuDocument"
                  open={openn}
                  anchorEl={props.anchorRef}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={props.handleClose}>
                          <MenuList id="split-button-menu">
                            {props.options.map((option, index) => (
                              <MenuItem
                                key={option}
                                // selected={index === selectedIndex}

                                onClick={(event) =>
                                  handleMenuItemClick(
                                    option,
                                    doc.id,
                                    config.BASE_URL + doc?.attachment
                                  )
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            </Grid> */}
          </div>
        </Card>

        <AlertPrefrence
          open={openAlertPrefrence}
          handleClose={handleCloseAlertPrefrence}
        />

        {openError && (
          <Error
            open={openError}
            handleClose={() => setOpenError(false)}
            message="Error occurred while updating the timezone"
          />
        )}

        {open && (
          <SuccessGreen
            open={open}
            handleClose={() => setOpen(false)}
            message="Your timezone has been successfully updated"
          />
        )}

        <EditEmailAddress
          open={openEmailAddress}
          handleClose={handleCloseEmailAddress}
        />

        <EditPassword open={openPassword} handleClose={handleClosePassword} />

        <AddMasking
          open={openMasking}
          handleClose={() => setOpenMasking(false)}
        />

        <EditEmailPrefrence
          open={openEmailPrefrence}
          handleClose={handleCloseEmailPrefrence}
        />
      </>
    );
}
