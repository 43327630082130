import "date-fns";
import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import debounce from "lodash.debounce";
import disableCheck from "../../../Utils/disableCheck";
import {
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  useTheme,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { FinanceService } from "../../../services/finance.service.js";
import SureToAdd from "../Alerts/SureToAdd/SureToAdd.js";
import AddDocument from "../AddDocument/AddDocument.js";
import EditDocument from "../EditDocument/EditDocument.js";
import ChooseWebLogins from "../ChooseWebLogins/ChooseWebLogins";
import EditWebLogin from "../EditWebLogin/EditWebLogin";
import RevealPassword from "../RevealPassword/RevealPassword";
import { store } from "../../../store/store";
import AlertPopup from "../Alerts/Generic/GenericAlert";
import SpinnerModal from "../Alerts/SpinnerModal/SpinnerModal";
import FileViewer from "../../../components/FileViewer/FileViewer";
import PasswordVerification from "../PasswordVerification/PasswordVerification";
import { DocumentService } from "../../../services/document.service";
import _default from "../../../config/default";

const stateArray = [
  "Bi-Weekly",
  "Monthly",
  "Quarterly",
  "Semi-Annually",
  "Annually",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const cashTypes = [
  "CD",
  "Checking",
  "Currency",
  "Money Market",
  "Savings",
  "Other",
];
const useStyles = makeStyles(styles);

function EditCashAccount(props) {
  const classes = useStyles();
  const errRef = useRef(null);
  const financeService = new FinanceService();

  const [err, setErr] = React.useState([]);
  const [documentModal, setDocumentModal] = React.useState(false);
  const [editDoc, setEditDoc] = React.useState(false);
  const [currentDocument, setCurrentDocument] = React.useState(null);
  const [link, setLink] = React.useState(null);
  const [showDoc, setShowDoc] = React.useState(false);
  const [id, setId] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);
  const [alert, setAlert] = React.useState({
    open: false,
    type: null,
    btnText: null,
    cancelText: null,
    heading: null,
    message: null,
  });
  const [password, setPassword] = React.useState(null);
  const [showPasswordVerification, setShowPasswordVerification] =
    React.useState(false);
  const [openReveal, setOpenReveal] = React.useState(false);
  const obj = props?.cashAccount?.associated_documents_attributes
    ? props?.cashAccount?.associated_documents_attributes
    : [];
  const [doc, setDoc] = React.useState(
    props?.cashAccount?.documents ? props?.cashAccount?.documents : []
  );
  const [editWebLogin, setEditWebLogin] = React.useState(false);
  const [webLogin, setWebLogin] = React.useState(
    (props?.cashAccount?.web_logins && props?.cashAccount?.web_logins[0]) ||
      null
  );
  const [webLoginModal, setWebLoginModal] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [data, setData] = React.useState({
    description: props?.cashAccount?.description,
    institution: props?.cashAccount?.institution,
    sk: props?.cashAccount?.sk,
    account_number:
      props?.cashAccount?.account_number_ciphertext ||
      props?.cashAccount?.account_number,
    cash_type: props?.cashAccount?.cash_type,
    balance: props?.cashAccount?.balance
      ?.replaceAll("$", "")
      ?.replaceAll(",", ""),
    direct_deposit: props?.cashAccount?.direct_deposit,
    international_wire_transfer:
      props?.cashAccount?.international_wire_transfer,
    domestic_wire_transfer: props?.cashAccount?.domestic_wire_transfer,
    owner: props?.cashAccount?.owner,
    ownership_description: props?.cashAccount?.ownership_description,
    notes: props?.cashAccount?.notes,
    associated_documents_attributes: obj,
    associated_logins_attributes: [
      {
        web_login_id:
          props?.cashAccount?.web_logins &&
          props?.cashAccount?.web_logins[0]?.id,
        id:
          props?.cashAccount?.associated_logins &&
          props?.cashAccount?.associated_logins[0]?.id,
        _destroy: 0,
      },
    ],
  });
  const [sureToAdd, setSureToAdd] = React.useState({ open: false, str: null });

  const handleOpenSureToAdd = (str) => {
    setSureToAdd({ open: true, str: str ?? null });
  };
  const handleCloseSureToAdd = () => {
    if (sureToAdd.str !== null) {
      props?.handleClose();
    }
    setSureToAdd({ open: false, str: null });
  };

  const handleSubmit = () => {
    handleCloseSureToAdd();
    console.log({ data });
    if (
      !data.associated_logins_attributes[0].web_login_id &&
      data.associated_logins_attributes[0]._destroy === 0
    ) {
      delete data["associated_logins_attributes"];
    }
    const temp = data?.associated_documents_attributes?.filter(
      (e) => e.document_id !== null
    );
    if (props?.cashAccount?.associated_documents_attributes) {
      setData({ ...data, associated_documents_attributes: temp });
    } else {
      if (temp && temp.length > 0) {
        setData({ ...data, associated_documents_attributes: temp });
      } else {
        let temp2 = delete data["associated_documents_attributes"];
        setData(data);
      }
    }
    const payload = {
      ...data,
      account_number: data.sk,
    };
    setIsLoading(true);
    financeService
      .editCashAcounts(payload, props?.cashAccount?.id)
      .then((response) => {
        setIsLoading(false);
        props.forceRerender();
        props.handleClose();
        console.log(response.data);
      })
      .catch((err) => {
        errRef.current.scrollIntoView();
        setIsLoading(false);
        if (err.response && err.response.data.message) {
          if (err.response?.data?.message === "Please enter a valid date") {
            setErr([err.response?.data?.message]);
          } else {
            let array = [];
            Object.keys(err.response.data.message).forEach((key) =>
            array.push(
              key === "institution"
                ? `Institution ${err.response.data.message[key]}`
                : key === "description" ? 
                `Description ${err.response.data.message[key]}` 
                : `${key} ${err.response.data.message[key]}`
            )
          );
            setErr(array);
          }
        } else {
          setErr(["Internal Server Error"]);
        }
      });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChange = debounce((event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  }, 300);

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChangeCountry = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const documentService = new DocumentService();

  const show = (docId) => {
    const documentId = docId ?? id;
    setSpinner(true);
    documentService
      .show(documentId, password)
      .then((res) => {
        setSpinner(false);
        setLink({
          attachment: res.data.document.attachment,
          contentType: res.data.document.content_type,
        });
        setShowDoc(true);
        setShowPasswordVerification(false);
        setPassword(null);
      })
      .catch((err) => {
        setSpinner(false);
        setPassword(null);
        setAlert({
          type: "Error",
          open: true,
          cancelText: "CANCEL",
          heading:
            err?.response && err?.response?.status === 422
              ? "Unauthorized!"
              : "Error!",
          message:
            err?.response && err?.response?.status === 422
              ? "Wrong password! please try again."
              : "An unknown error occurred, please try later.",
        });
      });
  };

  const initialRef = useRef(null);
  React.useEffect(() => {
    initialRef.current = data;
  }, []);
  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={() => {
          !disableCheck(initialRef, data)
            ? handleOpenSureToAdd("exit")
            : props?.handleClose();
        }}
        width="35%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        {alert.open && (
          <AlertPopup
            btnText={alert.btnText}
            cancelText={alert.cancelText}
            open={alert.open}
            type={alert.type}
            heading={alert.heading}
            message={alert.message}
            isLoading={false}
            handleClose={() => {
              setAlert({
                open: false,
                btnText: null,
                cancelText: null,
                heading: null,
                message: null,
                type: null,
              });
            }}
            handleClick={() => {
              setAlert({
                open: false,
                btnText: null,
                cancelText: null,
                heading: null,
                message: null,
                type: null,
              });
            }}
          />
        )}
        {spinner && <SpinnerModal open={spinner} msg={"Loading"} />}
        {showDoc && (
          <FileViewer
            contentType={link?.contentType}
            link={link?.attachment}
            open={showDoc}
            handleClose={() => setShowDoc(false)}
          />
        )}
        {showPasswordVerification && (
          <PasswordVerification
            open={showPasswordVerification}
            handleClose={() => {
              setShowPasswordVerification(false);
              setId(null);
            }}
            handleChange={(e) => setPassword(e)}
            handleSubmit={() => {
              show();
            }}
          />
        )}
        <ModalHeader
          closeModal={() => {
            !disableCheck(initialRef, data)
              ? handleOpenSureToAdd("exit")
              : props?.handleClose();
          }}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={`Edit ${data.cash_type || "Cash Accounts"}`}
          color={color.white}
        />
        <div className={classes.modalBody} ref={errRef}>
          <div className="w-100 d-flex flex-row mb-4">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              {"Finance > Cash Accounts > Edit"}
            </Typography>
          </div>
          {err?.length > 0 && (
            <div className="w-100 d-flex flex-row mb-4">
              {err?.map((val, key) => {
                return (
                  <Typography
                    key={key}
                    variant="p"
                    component="p"
                    align="left"
                    style={{
                      color: "red",
                      fontWeight: "normal",
                      marginBottom: "2px",
                    }}
                  >
                    {val.charAt(0).toUpperCase() + val.slice(1)}
                  </Typography>
                );
              })}
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            {editDoc && (
              <EditDocument
                password_protected={currentDocument?.password_protected}
                docs={doc}
                category={currentDocument?.category}
                id={currentDocument.id}
                title={currentDocument.title}
                open={editDoc}
                setDocs={setDoc}
                handleClose={() => setEditDoc(false)}
              />
            )}
            {documentModal && (
              <AddDocument
                setData={setData}
                setDoc={setDoc}
                data={data}
                associated_documents_attributes={
                  props?.cashAccount?.associated_documents_attributes
                    ? true
                    : false
                }
                doc={doc}
                open={documentModal}
                handleClose={() => setDocumentModal(false)}
                closeModal={() => setDocumentModal(false)}
                category={"Personal"}
              />
            )}
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Cash Type *"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  id="id_type"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  value={data.cash_type}
                  onChange={(e) => {
                    setData({
                      ...data,
                      cash_type: event.target.value,
                    });
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled>Select Cash Type</MenuItem>
                  {cashTypes.map((t) => {
                    return <MenuItem value={t}>{t}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Institution *"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="institution"
                onChange={handleChange}
                label=""
                value={data.institution}
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Institution"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Description *"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="description"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                value={data?.description}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Balance"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="balance"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                type="number"
                required={true}
                value={data?.balance?.replace("$", "")?.replace(",", "")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Account Number"}
              </Typography>
              {store.getState().auth.user.masking && data.account_number ? (
                <>
                  <CustomButton
                    onClick={() => {
                      setOpenReveal(true);
                      // props.handleClose()
                    }}
                    btnText={"Reveal"}
                    textAlign="center"
                    display={"block"}
                    background="transparent"
                    hoverBg="#0042a8"
                    border="1px solid #0042a8"
                    borderRadius="8px"
                    color="#0042a8"
                  />
                </>
              ) : null}
              {openReveal && (
                <RevealPassword
                  open={openReveal}
                  handleClose={() => setOpenReveal(false)}
                  subcategory_name={"CashAccount"}
                  password={data?.account_number}
                  id={props?.cashAccount?.id}
                />
              )}
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              {store.getState().auth.user.masking ? (
                <FormInput
                  name="sk"
                  onChange={handleChange}
                  label=""
                  variant="outlined"
                  placeholder="Account number"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  value={data?.sk}
                  id="txtPassword"
                />
              ) : (
                <FormInput
                  name="sk"
                  onChange={handleChange}
                  label=""
                  variant="outlined"
                  placeholder="Account number"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  value={data?.sk}
                />
              )}
            </Grid>
            <div className={"w-100 m-2 border p-4"}>
              <Typography
                variant="h6"
                component="h3"
                style={{
                  color: color.themeColorNew,
                  textAlign: "center",
                  padding: "1rem",
                }}
              >
                {"Routing Numbers"}
              </Typography>
              <Grid item xs={12} sm={12} md={12} className="pb-0">
                <Typography
                  variant="h6"
                  component="h3"
                  style={{
                    color: color.themeColorNew,
                  }}
                >
                  {"Domestic Wire Transfer"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <FormInput
                  name="domestic_wire_transfer"
                  onChange={handleChange}
                  label=""
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  value={data.domestic_wire_transfer}
                  required={true}
                  placeholder={"Domestic Wire Transfer"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} className="pb-0">
                <Typography
                  variant="h6"
                  component="h3"
                  style={{ color: color.themeColorNew }}
                >
                  {"International Wire Transfer"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <FormInput
                  name="international_wire_transfer"
                  onChange={handleChange}
                  label=""
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  value={data.international_wire_transfer}
                  placeholder={"International Wire Transfer"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} className="pb-0">
                <Typography
                  variant="h6"
                  component="h3"
                  style={{ color: color.themeColorNew }}
                >
                  {"Direct Deposit"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <FormInput
                  name="direct_deposit"
                  onChange={handleChange}
                  label=""
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  value={data.direct_deposit}
                  placeholder={"Direct Deposit"}
                />
              </Grid>
            </div>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Owner"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="owner"
                  name="owner"
                  value={data?.owner}
                  onChange={handleChange}
                  className="flex-row"
                >
                  <FormControlLabel
                    value="Self"
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Self"
                  />
                  <FormControlLabel
                    value="Joint"
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Joint"
                  />
                  <FormControlLabel
                    value="Other"
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {data.owner === "Other" && (
              <>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Owner Description"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormInput
                    id="class"
                    variant="outlined"
                    fullWidth={true}
                    name="ownership_description"
                    margin="dense"
                    required={true}
                    placeholder={"Ownership description"}
                    onChange={handleChange}
                    value={data.ownership_description}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Notes"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                id="class"
                name="notes"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                value={data?.notes}
              />
            </Grid>
            {webLoginModal && (
              <ChooseWebLogins
                setData={setData}
                setWebLogin={setWebLogin}
                data={data}
                open={webLoginModal}
                webLogin={data?.associated_logins_attributes[0]?.web_login_id}
                handleClose={() => setWebLoginModal(false)}
                closeModal={() => setWebLoginModal(false)}
                category={"Personal"}
              />
            )}
            {editWebLogin && (
              <EditWebLogin
                open={editWebLogin}
                handleClose={() => setEditWebLogin(false)}
                webLogin={webLogin}
              />
            )}
            {editWebLogin && (
              <EditWebLogin
                open={editWebLogin}
                handleClose={() => setEditWebLogin(false)}
                webLogin={webLogin}
              />
            )}
            {webLogin && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Web Login"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {webLogin?.user_name}
                  </Typography>
                  <CustomButton
                    btnText={"Remove"}
                    textAlign="center"
                    display={"block"}
                    background="transparent"
                    hoverBg="#0042a8"
                    border="1px solid #0042a8"
                    borderRadius="8px"
                    color="#0042a8"
                    onClick={() => {
                      setWebLogin(null);
                      setData({
                        ...data,
                        associated_logins_attributes: [
                          {
                            web_login_id:
                              data.associated_logins_attributes[0].web_login_id,
                            id: data.associated_logins_attributes[0].id,
                            _destroy: 1,
                          },
                        ],
                      });
                    }}
                  />
                  <CustomButton
                    btnText={"Edit"}
                    textAlign="center"
                    marginLeft="8px"
                    display={"block"}
                    background="transparent"
                    hoverBg="#0042a8"
                    border="1px solid #0042a8"
                    borderRadius="8px"
                    color="#0042a8"
                    onClick={() => {
                      setEditWebLogin(true);
                    }}
                  />
                </Grid>
              </>
            )}

            <input style={{ position: "absolute", top: -200 }} />

            {doc.length > 0 && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Document"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                  {doc.map((e, k) => (
                    <>
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: color.themeColorNew }}
                        key={k}
                      >
                        {e?.title}
                      </Typography>
                      <CustomButton
                        btnText={"Remove"}
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          let temp = data.associated_documents_attributes?.map(
                            (d) => {
                              if (d.document_id === e.id) {
                                return {
                                  ...d,
                                  _destroy: 1,
                                };
                              } else {
                                return d;
                              }
                            }
                          );
                          let newDoc = doc?.filter((d) => d.id !== e.id);
                          setDoc(newDoc);
                          setData({
                            ...data,
                            associated_documents_attributes: temp,
                          });
                        }}
                      />

                      <CustomButton
                        btnText={"Edit"}
                        className="ms-2"
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          setCurrentDocument(e);
                          setEditDoc(true);
                        }}
                      />

                      <CustomButton
                        btnText={"View"}
                        className="ms-2"
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          if (!props?.cashAccount?.documents) {
                            show(e?.id);
                          } else {
                            if (e?.attachment) {
                              setLink({
                                attachment: _default.BASE_URL + e.attachment,
                                contentType: e.content_type,
                              });
                              setShowDoc(true);
                            } else {
                              setId(e?.id);
                              setShowPasswordVerification(true);
                            }
                          }
                        }}
                      />
                    </>
                  ))}
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              <CustomButton
                btnText="Add Document"
                className="mb-sm-2"
                textAlign="center"
                display={"block"}
                padding="8px 25px"
                background="#0042a8"
                hoverBg="#0042a8"
                border="0"
                borderRadius="8px"
                color="#fff"
                marginRight={"10px"}
                onClick={() => setDocumentModal(true)}
              />

              {!webLogin && (
                <CustomButton
                  className="mb-sm-2"
                  btnText="Add Web Login"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  marginRight={"10px"}
                  onClick={() => setWebLoginModal(true)}
                />
              )}

              {!isLoading ? (
                <CustomButton
                  type="submit"
                  btnText="Save"
                  textAlign="center"
                  display={"block"}
                  disabled={disableCheck(initialRef, data)}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  onClick={() => handleOpenSureToAdd(null)}
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
      <SureToAdd
        open={sureToAdd.open}
        handleClose={handleCloseSureToAdd}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCashAccount);
