import React, { useRef, useState } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import color from "../../../assets/css/color";

import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import _default from "../../../config/default.js";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import userImg from "../../../assets/img/user.png";
import man from "../../../assets/img/man.png";
import { CustomButton } from "../../CustomButton/Button.js";
import EditPermissions from "../../../containers/Modals/EditPermissions/EditPermissions.js";

const useStyles = makeStyles(styles);
const CollaboratorList = ({
  user,
  key,
  deleteCollaborator,
  toggleCollaboratorStatus,
}) => {
  console.log("User", user);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openEditAccess, setOpenEditAccess] = React.useState(false);
  const [openEditPermissions, setOpenEditPermission] = React.useState(false);
  const [openAddNew, setOpenAddNew] = React.useState(false);
  const anchorRef = useRef();
  const handleOpenEditAccess = () => {
    setOpenEditAccess(true);
    setOpen(false);
  };

  const handleCloseEditAccess = () => {
    setOpenEditAccess(false);
  };
  const closeModal = () => {
    setOpenAddNew(false);
    setOpenEditAccess(false);
    setOpenEditPermission(false);
  };
  const handleOpenEditPermissions = () => {
    setOpenEditPermission(true);
    setOpen(false);
  };
  const handleCloseEditPermissions = () => {
    setOpenEditPermission(false);
  };
  const handleCloseDropdown = (event) => {
    // Ensure the click is outside the anchor element
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    // Close the dropdown if clicked outside
    setOpenDropdown(false);
  };
  
  return (
    <Grid
      key={key}
      container
      alignItems="center"
      className={`${classes.marginBottom18px} ${classes.administratorTableResponsive}`}
    >
      <Grid item xs={4} md={4} className="d-flex flex-row align-items-center">
        <img
          src={
            user?.profile_picture_of_user
              ? _default.BASE_URL + user?.profile_picture_of_user
              : user?.gender === "male"
              ? man
              : userImg
          }
          alt={"User Profile"}
          height={65}
          width={65}
          className="rounded-pill me-3"
          style={{ objectFit: "cover" }}
        />
        <Typography
          variant="p"
          component="p"
          className={classes.normalFontSize + " " + "me-5 mb-2 mb-md-0 mb-lg-0"}
          style={{ color: "#060d5c", fontWeight: "bold" }}
        >
          {user.name}
        </Typography>
      </Grid>
      <Grid item xs={3} md={3} style={{ textAlign: "center" }}>
        <CustomButton
          className="shadow-none text-decoration-underline border-0"
          btnText={"Edit"}
          padding="8px 25px"
          minWidth="113px"
          background="transparent"
          hoverBg="transparent"
          // color="#0042a8!important"
          style={{
            color: "#ffb722",
            fontWeight: "bold",
            textTransform: "uppercase",
            textUnderlineOffset: "4px",
          }}
          onClick={() => handleOpenEditPermissions()}
        />
        {openEditAccess && (
          <EditAccess
            open={openEditAccess}
            handleClose={handleCloseEditAccess}
            closeModal={closeModal}
          />
        )}
        {openEditPermissions && (
          <EditPermissions
            open={openEditPermissions}
            handleClose={handleCloseEditPermissions}
            closeModal={closeModal}
            user={user}
          />
        )}
      </Grid>
      <Grid item xs={3} md={3}>
        <Typography
          variant="p"
          component="p"
          className={classes.normalFontSize}
          style={{
            fontWeight: "normal",
            textTransform: "capitalize",
          }}
        >
          {user.last_active}
        </Typography>
      </Grid>
      <Grid
        item
        xs={2}
        md={2}
        className="d-flex flex-row  position-relative"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        {/* <Typography
          variant="p"
          component="p"
          className={classes.normalFontSize}
          style={{
            color: user.status === "pending" ? "#ffa500" : "#ffb722",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          {user.status === "pending"
            ? "Pending"
            : user.is_disabled
            ? "Disabled"
            : "Active"}
        </Typography> */}

        <Typography
          variant="p"
          component="p"
          className={classes.normalFontSize}
          style={{
            color:
              user.status === "pending"
                ? "#ffa500"
                : user.status === "expired"
                ? "#ff0000"
                : user.status === "inactive"
                ? "#808080"
                : "#ffb722",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          {user.status === "pending"
            ? "Pending"
            : user.status === "expired"
            ? "Expired"
            : user.status === "inactive"
            ? "Inactive"
            : user.is_disabled
            ? "Disabled"
            : "Active"}
        </Typography>

        <div className="">
          <MoreVert
            color="primary"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={() => setOpenDropdown(true)}
            style={{
              cursor: "pointer",
              color: color.themeYellow,
            }}
          />
        </div>

        {/* <Popper
                          className="position-absolute dropMenuDocument"
                          open={props?.open}
                          anchorEl={props?.anchorRef.current}
                          role={undefined}
                          transition
                          disablePortal
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === "bottom"
                                    ? "center top"
                                    : "center bottom",
                              }}
                            >
                              <Paper className="bg_white">
                                <ClickAwayListener
                                  onClickAway={props.handleCloseDropdown}
                                >
                                  <MenuList id="split-button-menu">
                                    <MenuItem
                                      onClick={() => {
                                        props.toggleCollaboratorStatus(
                                          user.id,
                                          !user.is_disabled
                                        );
                                      }}
                                    >
                                      <div>
                                        {user.is_disabled
                                          ? "Active"
                                          : "Disable"}
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() =>
                                        props.deleteCollaborator(user.id)
                                      }
                                    >
                                      <div>Delete</div>
                                    </MenuItem>
                                     {props.options.map((option, index) => (
                                  <MenuItem
                                    key={option}
                                    // selected={index === selectedIndex}

                                    onClick={() =>
                                      handleMenuItemClick(
                                        option,
                                        user.id,
                                      )
                                    }
                                  >
                                    {option}
                                  </MenuItem>
                                ))} 
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper> */}
        <Popper
          className="position-absolute dropMenuDocument"
          open={openDropdown}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper className="bg_white">
                <ClickAwayListener onClickAway={handleCloseDropdown}>
                  <MenuList id="split-button-menu">
                    {user.status === "pending" ? (
                      <MenuItem onClick={() => deleteCollaborator(user.id)}>
                        <div>Delete</div>
                      </MenuItem>
                    ) : (
                      <>
                        <MenuItem
                          onClick={() => {
                            toggleCollaboratorStatus(
                              user.id,
                              !user.is_disabled
                            );
                          }}
                        >
                          <div>{user.is_disabled ? "Active" : "Disable"}</div>
                        </MenuItem>
                        <MenuItem onClick={() => deleteCollaborator(user.id)}>
                          <div>Delete</div>
                        </MenuItem>
                      </>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};

export default CollaboratorList;
