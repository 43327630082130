import React, { useEffect, useCallback, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
// @material-ui/core components
// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
// core components
import { useRouteName } from "../../../hooks";
import GridItem from "../../../components/Grid/GridItem.js";
import { Grid, Typography } from "@material-ui/core";
import color from "../../../assets/css/color.js";
// @material-ui/icons
import LinkedItems from "../../../containers/Modals/LinkedItems/LinkedItems.js";
import Collaborators from "../../../containers/Modals/Collaborators/Collaborators";
import W2Form from "../../../containers/Modals/W2Form/W2Form";
import AddWellness from "../../../containers/Modals/AddWellness/AddWellness";
import { CustomButton } from "../../../components/CustomButton/Button";
import AddContactInfo from "../../../containers/Modals/AddContactInfo/AddContactInfo";
import CircularProgress from "@material-ui/core/CircularProgress";
import { store } from "../../../store/store";
import CardCategory from "../../../components/Card/CardCategory";
import { HealthService } from "../../../services/health.service";
import EditWellness from "../../../containers/Modals/EditWellness/EditWellness";
import WellnessInformation from "../../../containers/Modals/WellnessInformation/WellnessInformation";
import { connect } from "react-redux";

function Wellnesses({ sortType, order }) {
  const childRef = useRef();
  const healthService = new HealthService();
  const [openDropdown, setOpenDropdown] = React.useState(false);
  const [wellnesses, setWellnesses] = React.useState([]);
  const [err, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [info, setInfo] = React.useState(null);
  const [multiple, setMultiple] = React.useState(false);
  const [ids, setIds] = React.useState([]);
const [currId, setCurrId] = React.useState(null);
  const [selectedData, setSelectedData] = useState({});  
  const [currWellness, setCurrWellness] = React.useState(null);
  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");

  React.useEffect(() => {
    setLoading(true);
    healthService
      .wellnessesIndex()
      .then((response) => {
        setLoading(false);
        setWellnesses(
          response?.data?.wellness ? [response?.data?.wellness] : []
        );
        if (
          id &&
          response?.data?.wellness?.length > 0 &&
          response?.data?.wellness?.filter((f) => f?.id === +id).length > 0
        ) {
          setInfo(response?.data?.wellness?.filter((f) => f?.id === +id));
          setOpen(true);
        }
      })
      .catch((err) => {
        // console.log({ err });
        setLoading(false);
        setError("error occurred");
      });
  }, [store.getState().loading.render]);

  const handleMenuItemClick = (event) => {
    // setSelectedIndex(index); +
    setOpenDropdown(false);
  };

  const handleToggle = () => {
    setOpenDropdown((prevopenDropdown) => !prevopenDropdown);
  };

  const handleOpenDropDown = (id) => {
    setCurrId(id);
    handleToggle();
  };
  const handleCloseDropdown = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenDropdown(false);
  };

  const routeName = useRouteName();

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openAddNew, setOpenAddNew] = React.useState(false);
  const [openLinkedItems, setOpenLinkedItems] = React.useState(false);
  const [openCollaborators, setOpenCollaborators] = React.useState(false);
  const [openW2Form, setOpenW2Form] = React.useState(false);
  const [openAddContactInfo, setOpenAddContactInfo] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickInfo = (id) => {
    const tr = wellnesses?.filter((aw) => aw.id === id);
    setInfo(tr);
    handleOpen();
  };

  const handleOpenAddNew = () => {
    setOpenAddNew(true);
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenW2Form(false);
  };

  const handleOpenEdit = (tr) => {
    setEdit(true);
    setOpenEdit(true);
    setCurrWellness(tr);
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenW2Form(false);
  };

  const handleOpenLinkedItems = () => {
    setOpenLinkedItems(true);
    setOpen(false);
    setOpenCollaborators(false);
    setOpenW2Form(false);
    setOpenAddNew(false);
  };

  const handleOpenCollaborators = () => {
    setOpenCollaborators(true);
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenW2Form(false);
    setOpenAddNew(false);
  };
  const handleSelectedCollaborators = useCallback((dl) => {
    setSelectedData(dl);
  }, []);

  const handleOpenW2Form = () => {
    setOpenW2Form(true);
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenAddNew(false);
  };

  const handleOpenAddContactInfo = () => {
    setOpenAddContactInfo(true);
    setOpenW2Form(false);
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenAddNew(false);
  };

  const handleCloseLinkedItems = () => {
    setOpenLinkedItems(false);
    setOpen(true);
  };

  const handleCloseCollaborators = () => {
    setOpenCollaborators(false);
  };

  const handleCloseW2Form = () => {
    setOpenW2Form(false);
  };

  const handleCloseAddNew = () => {
    setOpenAddNew(false);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setEdit(false);
  };

  const handleCloseAddContactInfo = () => {
    setOpenAddContactInfo(false);
  };

  const closeModal = () => {
    setOpen(true);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenW2Form(false);
    setOpenAddNew(false);
    setOpenAddContactInfo(false);
  };

  const closeEditModal = () => {
    setOpenEdit(false);
    setEdit(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenW2Form(false);
    setOpenAddNew(false);
    setOpenAddContactInfo(false);
  };

  const handleDelete = (id) => {
    healthService.wellnessesDestroy(id).then(() => {
      const remaining = wellnesses.filter((aw) => aw.id !== id);
      setWellnesses(remaining);
    });
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Grid
        container
        wrap="wrap"
        direction={isMobile ? 'column' : 'row'}
        justifyContent=""
        alignItems={isMobile ? 'flex-start' : 'center'}
        style={{ margin: "25px 0" }}
      >
        <Typography
          variant="h6"
          component="h3"
          style={{ color: color.themeColorNew }}
        >
          Wellness
        </Typography>
        {wellnesses?.length <= 0 && (
          <CustomButton
            type="submit"
            btnText="Add New"
            textAlign="center"
            display={"block"}
            padding="8px 17px"
            background="#0042a8"
            marginLeft="4rem"
            hoverBg="#0042a8"
            border="0"
            borderRadius="8px"
            color="#fff"
            onClick={() => handleOpenAddNew()}
          />
        )}
        <Grid item xs={6} md={6}></Grid>
        <Grid item xs={6} md={6} style={{ textAlign: "right" }}></Grid>
      </Grid>
      <Grid container>
        <GridItem xs={12} sm={12} md={12}>
          {openAddNew && (
            <AddWellness
              open={openAddNew}
              handleClose={handleCloseAddNew}
              handleOpenAddContactInfo={handleOpenAddContactInfo}
              closeModal={closeModal}
            />
          )}
          {edit && (
            <EditWellness
              open={openEdit}
              handleClose={handleCloseEdit}
              handleOpenAddContactInfo={handleOpenAddContactInfo}
              closeModal={closeEditModal}
              wellness={currWellness}
            />
          )}
          <AddContactInfo
            open={openAddContactInfo}
            handleCloseAddContactInfo={handleCloseAddContactInfo}
            closeModal={closeModal}
          />
          {open && (
            <WellnessInformation
              open={open}
              handleClose={handleClose}
              handleOpenEdit={handleOpenEdit}
              wellness={info}
              handleOpenLinkedItems={handleOpenLinkedItems}
              handleOpenCollaborators={handleOpenCollaborators}
              closeModal={closeModal}
            />
          )}
          <LinkedItems
            attachment={info}
            open={openLinkedItems}
            handleCloseLinkedItems={handleCloseLinkedItems}
            closeModal={closeModal}
            category={"Health"}
            heading={"Wellness"}
          />
          <Collaborators
            open={openCollaborators}
            handleCloseCollaborators={handleCloseCollaborators}
            closeModal={closeModal}
            data={selectedData}
          />
          <W2Form
            open={openW2Form}
            handleCloseW2Form={handleCloseW2Form}
            handleOpenLinkedItems={handleOpenLinkedItems}
            handleOpenCollaborators={handleOpenCollaborators}
            closeModal={closeModal}
          />
        </GridItem>
        {/* {console.log('EXPENSE +++++++++++++++++',info)} */}
        {wellnesses?.length > 0 && !loading && !err ? (
          <>
            {wellnesses
              ?.sort((a, b) => {
                if (sortType === "alpha") {
                  if (order === "asc") {
                    return a.test_name?.localeCompare(b.test_name);
                  } else {
                    return b.test_name?.localeCompare(a.test_name);
                  }
                } else if (sortType === "date") {
                  if (order === "asc") {
                    return a.created_at?.localeCompare(b.created_at);
                  } else {
                    return b.created_at?.localeCompare(a.created_at);
                  }
                }
              })
              .map((aid, index) => {
                return (
                  <CardCategory
                    childRef={childRef}
                    handleClickInfo={() => handleClickInfo(aid?.id)}
                    // options={options}
                    handleOpenAddNew={handleOpenAddNew}
                    handleOpenEdit={handleOpenEdit}
                    openDropdown={openDropdown}
                    handleToggle={() => handleOpenDropDown(aid?.id)}
                    anchorRef={anchorRef}
                    handleCloseDropdown={handleCloseDropdown}
                    data={aid}
                    currId={currId}
                    subcategory={"Wellness"}
                    selectedIndex={selectedIndex}
                    key={index}
                    handleDelete={handleDelete}
                    handleMenuItemClick={handleMenuItemClick}
                    heading={
                      aid?.primary_care_physician
                        ? aid?.primary_care_physician
                        : aid?.race_ethnicity
                        ? aid?.race_ethnicity
                        : aid?.blood_type
                        ? aid?.blood_type
                        : "wellness"
                    }
                    multiple={multiple}
                    setMultiple={setMultiple}
                    handleSelectedCollaborators={handleSelectedCollaborators}
                    setIds={setIds}
                    ids={ids}
                  />
                );
              })}
          </>
        ) : loading ? (
          <CircularProgress size={40} color="secondary" />
        ) : err ? (
          "error occurred"
        ) : !loading && !err ? (
          "No Wellness yet"
        ) : null}
      </Grid>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  const mapStateToProps = (state, ownProps) => {
    return {
      sortType: state?.sort?.sort,
      order: state?.sort?.order,
    };
  };
};

export default connect(mapStateToProps)(Wellnesses);
