import "date-fns";
import debounce from "lodash.debounce";
import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import { dateFormat } from "../../../Utils/dateFormate.js";

import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import { EmployerService } from "../../../services/employer.service.js";

import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import AddDocument from "../AddDocument/AddDocument";
import ChooseWebLogins from "../ChooseWebLogins/ChooseWebLogins.js";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { getDateIgnoreTimezone } from "../../../Utils/dateHelper.js";

const useStyles = makeStyles(styles);

function AddEmployer(props) {
  const classes = useStyles();
  const errRef = useRef(null);
  const employerService = new EmployerService();
  const [err, setErr] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [doc, setDoc] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [webLogin, setWebLogin] = React.useState(null);
  const [webLoginModal, setWebLoginModal] = React.useState(false);
  const [data, setData] = React.useState({
    company: "",
    title: "",
    start_date: null,
    end_date: null,
    hours_worked_per_week: "",
    description: "",
    attachments: "",
    associated_logins_attributes: [
      {
        web_login_id: null,
      },
    ],
    associated_documents_attributes: [],
  });
  const [isCurrentEmployer, setIsCurrentEmployer] = React.useState({
    checkedA: false,
  });
  const handleChangeIsCurrentEmployer = (event) => {
    if (event.target.checked) {
      setData({ ...data, end_date: null });
    }
    if (event.target.name === "checkedA") {
      setIsCurrentEmployer({
        ...isCurrentEmployer,
        [event.target.name]: event.target.checked,
      });
    } else {
      setIsCurrentEmployer({
        ...isCurrentEmployer,
        [event.target.name]: event.target.value,
      });
    }
  };
  const [documentModal, setDocumentModal] = React.useState(false);
  const disablePastDate = () => {
    const today = new Date(data.start_date);
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const handleDocumentModal = () => {
    setDocumentModal(true);
  };

  const handleSubmit = () => {
    let formData = new FormData();
    formData.append("company", data.company);

    if (data.associated_documents_attributes?.length > 0) {
      for (let i = 0; i < data.associated_documents_attributes?.length; i++) {
        formData.append(
          "associated_documents_attributes[][document_id]",
          data.associated_documents_attributes[i]?.document_id ||
            data.associated_documents_attributes[i]?.id
        );
      }
    }
    formData.append("current_employer", isCurrentEmployer.checkedA);
    formData.append("title", data.title);
    data.start_date &&
      formData.append(
        "start_date",
        getDateIgnoreTimezone(data.start_date).toISOString().slice(0, 10)
      );

    data.end_date &&
      formData.append(
        "end_date",
        getDateIgnoreTimezone(data.end_date).toISOString().slice(0, 10)
      );
    formData.append("hours_worked_per_week", data.hours_worked_per_week);
    formData.append("description", data.description);

    if (data.associated_logins_attributes[0].web_login_id) {
      formData.append(
        "associated_logins_attributes[][web_login_id]",
        JSON.stringify(data.associated_logins_attributes[0].web_login_id)
      );
    }
    setIsLoading(true);
    employerService
      .createEmplyee(formData)
      .then((response) => {
        setIsLoading(false);
        props.forceRerender();
        props.handleClose();

        console.log(response.data);
      })
      .catch((err) => {
        errRef.current.scrollIntoView();
        setIsLoading(false);
        if (err.response && err.response.data.message) {
          if (err.response?.data?.message === "Please enter a valid date") {
            setErr([err.response?.data?.message]);
          } else {
            let array = [];
            Object.keys(err.response.data.message).forEach((key) =>
              array.push(`${key} ${err.response.data.message[key]}`)
            );
            setErr(array);
          }
        } else {
          setErr(["Internal Server Error"]);
        }
      });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChange = debounce((event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  }, 300);
  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Add New Employer"}
          color={color.white}
        />
        <div className={classes.modalBody} ref={errRef}>
          <div className="w-100 d-flex flex-row mb-4">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              {"Employment > Employers > New"}
            </Typography>
          </div>
          {err?.length > 0 && (
            <div className="w-100 d-flex flex-row mb-4">
              {err?.map((val, key) => {
                return (
                  <Typography
                    key={key}
                    variant="p"
                    component="p"
                    align="left"
                    style={{
                      color: "red",
                      fontWeight: "normal",
                      marginBottom: "2px",
                    }}
                  >
                    {val.charAt(0).toUpperCase() + val.slice(1)}
                  </Typography>
                );
              })}
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Company *"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                id="company"
                name="company"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Company"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pt-0">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCurrentEmployer.checkedA}
                    onChange={handleChangeIsCurrentEmployer}
                    name="checkedA"
                    style={{ color: "#ffb722" }}
                  />
                }
                label="This is my current employer"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Title"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                id=""
                name="title"
                label=""
                onChange={handleChange}
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Title"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Hours Per Week"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                id=""
                name="hours_worked_per_week"
                onChange={handleChange}
                label=""
                type="number"
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Hours Per Week"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Start Date"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  inputVariant="outlined"
                  format={dateFormat()}
                  id="start_date"
                  placeholder={dateFormat()?.toUpperCase()}
                  value={data?.start_date}
                  onChange={(date) => {
                    setData({
                      ...data,
                      start_date: date,
                    });
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            {!isCurrentEmployer.checkedA && (
              <>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"End Date"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth={true}
                      margin="dense"
                      required={true}
                      inputVariant="outlined"
                      format={dateFormat()}
                      id="start_date"
                      placeholder={dateFormat()?.toUpperCase()}
                      value={data?.end_date}
                      inputProps={{
                        min: disablePastDate(),
                      }}
                      onChange={(date) => {
                        setData({
                          ...data,
                          end_date: getDateIgnoreTimezone(date)
                            .toISOString()
                            .slice(0, 10),
                        });
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </>
            )}

            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Notes"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                name="description"
                fullWidth={true}
                onChange={handleChange}
                id="outlined-multiline-static"
                label=""
                multiline
                rows={3}
                placeholder={"Notes"}
                variant="outlined"
              />
              {documentModal && (
                <AddDocument
                  setData={setData}
                  setDoc={setDoc}
                  associated_documents_attributes={false}
                  doc={doc}
                  data={data}
                  open={documentModal}
                  handleClose={() => setDocumentModal(false)}
                  closeModal={() => setDocumentModal(false)}
                  category={"Employment"}
                />
              )}
              {webLoginModal && (
                <ChooseWebLogins
                  setData={setData}
                  setWebLogin={setWebLogin}
                  data={data}
                  open={webLoginModal}
                  webLogin={data?.associated_logins_attributes[0]?.web_login_id}
                  handleClose={() => setWebLoginModal(false)}
                  closeModal={() => setWebLoginModal(false)}
                  category={"Personal"}
                />
              )}
            </Grid>
            {webLogin && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Web Login"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {webLogin?.user_name}
                  </Typography>
                  <CustomButton
                    btnText={"Remove"}
                    textAlign="center"
                    display={"block"}
                    background="transparent"
                    hoverBg="#0042a8"
                    border="1px solid #0042a8"
                    borderRadius="8px"
                    color="#0042a8"
                    onClick={() => {
                      setWebLogin(null);
                      setData({
                        ...data,
                        associated_logins_attributes: [
                          {
                            web_login_id: null,
                          },
                        ],
                      });
                    }}
                  />
                </Grid>
              </>
            )}
            {doc.length > 0 && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Document"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                  {doc.map((e, k) => (
                    <>
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: color.themeColorNew }}
                        key={k}
                      >
                        {e?.title}
                      </Typography>
                      <CustomButton
                        btnText={"Remove"}
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          let newDoc = doc?.filter((d) => d.id !== e.id);
                          setDoc(newDoc);
                          setData({
                            ...data,
                            associated_documents_attributes: newDoc,
                          });
                        }}
                      />
                    </>
                  ))}
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              {!webLogin && (
                <CustomButton
                  btnText="Add Web Login"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  marginRight={"10px"}
                  className="mb-sm-2"
                  onClick={() => setWebLoginModal(true)}
                />
              )}
              <CustomButton
                btnText="Add Document"
                textAlign="center"
                display={"block"}
                padding="8px 25px"
                background="#0042a8"
                hoverBg="#0042a8"
                border="0"
                borderRadius="8px"
                color="#fff"
                marginRight={"10px"}
                className="mb-sm-2"
                onClick={handleDocumentModal}
              />

              {!isLoading ? (
                <CustomButton
                  type="submit"
                  btnText="Save"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  className="mb-sm-2"
                  onClick={handleSubmit}
                  disabled={
                    data.company === "" || !data.company?.trim() ? true : false
                  }
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEmployer);
