import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { connect } from "react-redux";
import styles from "../../../assets/jss/material-dashboard-react/components/ActivityHistoryAccordion.js";
import styles2 from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";

import { Grid, IconButton } from "@material-ui/core";
import CollaboratorList from "./CollaboratorList.js";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ModalHeader from "../../Modal/ModalHeader.js";
import { CustomButton } from "../../CustomButton/Button.js";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(styles2);

function CollaboratorAccordion({ activities, sort, order }) {
  const classes = useStyles();
  const classes2 = useStyles2();
  console.log("Activies", activities);
  const [expanded, setExpanded] = React.useState(false);
  let array = [];
  let temp;
  const handleChange = (panel) => (event, isExpanded) => {
    array = [];
    setExpanded(isExpanded ? panel : false);
  };
  const handleClose = () => {
    setExpanded(false); // Collapse the accordion
  };
  useEffect(() => {
    console.log({ activities });
  }, []);
  return (
    <div className={classes.root}>
      {activities &&
        activities?.map((activity, index) => {
          return (
            <Accordion
              className={`${classes.accordionMainBar}`}
              expanded={expanded === index}
              onChange={handleChange(index)}
            >
              {expanded !== index && (
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={classes.accordionQuestion}
                  style={{ border: "1px solid #0042a8" }}
                >
                  <Typography
                    className={`${classes.heading} ${classes2.normalFontSize}`}
                    style={{ color: "#060d5c", fontWeight: "bold" }}
                  >
                    {activity?.name}
                  </Typography>
                </AccordionSummary>
              )}
              {/* {activity?.actionable && ( */}
              <AccordionDetails
                className={classes.accordionAnswer + " flex-column"}
                style={{ border: "1px solid #0042a8" }}
              >
                 
                <div style={{ overflowX: "auto" }}>
                {/* <div className={classes.closeButton} 
                style={{textAlign:"right"}}
                >
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    style={{ color: "rgb(6, 13, 92)", textAlign: "right" }}
                  >
                    <KeyboardArrowUpIcon />
                  </IconButton>
                  </div> */}
                  <Grid
                    container
                    className={`${classes2.marginBottom18px} 
            ${classes2.administratorTableResponsive} ${classes2.marginTop18px}`}
                  >
                    <Grid item xs={4} md={4}>
                      <Typography
                        variant="p"
                        component="p"
                        className={`${classes2.normalFontSize} ${classes2.marginBottom14px}`}
                        style={{ fontWeight: "bold" }}
                      >
                        {"User"}
                      </Typography>
                    </Grid>

                    <Grid item xs={3} md={3}>
                      <Typography
                        variant="p"
                        component="p"
                        className={`${classes2.normalFontSize} ${classes.marginBottom14px}`}
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        {"Permissions"}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Typography
                        variant="p"
                        component="p"
                        className={`${classes.normalFontSize} ${classes.marginBottom14px}`}
                        style={{ fontWeight: "bold" }}
                      >
                        {"Last Active"}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <Typography
                        variant="p"
                        component="p"
                        className={`${classes.normalFontSize} ${classes.marginBottom14px}`}
                        style={{ fontWeight: "bold" }}
                      >
                        {"Status"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <CollaboratorList
                    user={activity}
                    deleteCollaborator={(id) => props.deleteCollaborator(id)}
                    toggleCollaboratorStatus={(id, status) =>
                      props.toggleCollaboratorStatus(id, status)
                    }
                  />
                </div>

                <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right", paddingTop: "20px", display: "flex", gap: "10px", justifyContent: "flex-end" }}
            >
              <CustomButton
                btnText="Close"
                onClick={() => handleClose()}
                textAlign="center"
                display={"block"}
                padding="8px 25px"
                background="#0042a8"
                hoverBg="#0042a8"
                border="0"
                borderRadius="8px"
                color="#fff"
              />
            </Grid>

                
              </AccordionDetails>
              {/* )} */}
            </Accordion>
          );
        })}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    sort: state.sort.sort,
    order: state.sort.order,
  };
};

export default connect(mapStateToProps)(CollaboratorAccordion);
