import { Steps } from "intro.js-react";
import React, { useEffect } from "react";
import { useState, useRef } from "react";

const Tour = ({ steps, setTour, changeCount, initialStep, setInitialStep }) => {
  const ref = useRef();
  const [refAssigned, setRefAssigned] = useState(false);
  const [setup, setSetup] = useState({
    stepsEnabled: true,
    initialStep: 0,

    hideNext: true,
    hidePrev: true,
    steps: [
      {
        element: ".dashboard",
        intro:
          "Welcome to the TagData Dashboard. You can find your favorited Categories, Recently Viewed items, Documents, Contacts, and any shared items you receive here.",
        position: "middle",
        hidePrev: true,
      },
      {
        element: ".logo",
        intro:
          "You can click on the TagData logo anytime to visit the main dashboard.",
        position: "right",
      },
      {
        element: ".sidebarmenu",
        intro:
          "This sidebar menu contains all the Dimensions of information, where you can click on any of listed Dimension to access any respective Category.",
        position: "right",
      },
      {
        element: ".account-options",
        intro:
          "Clicking the image will take you to your account options, where you can find the settings and info for your account and profiles.",
        position: "bottom",
      },
      {
        element: ".alert",
        intro: "Your notifications will be shown here.",
        position: "right",
        hideNext: true,
      },
    ],
  });

  useEffect(() => {
    if (initialStep !== 0) {
      // setRefAssigned(true);
      const arr = [...setup.steps];
      arr[0] = setup.steps[0];
      arr[initialStep] = setup.steps[initialStep];
      setSetup({ ...setup, steps: arr });
    }
  }, []);
  return (
    <Steps
      ref={ref}
      enabled={setup.stepsEnabled}
      options={{
        exitOnOverlayClick: false,
      }}
      steps={steps ?? setup.steps}
      initialStep={setup.initialStep}
      onExit={(number) => {
        setSetup({ ...setup, stepsEnabled: false });
        setInitialStep(number);
        setTour(false);
        changeCount();
      }}
      hideNext={true}
      hidePrev={true}
      exitOnOverlayClick={false}
      onBeforeChange={(nextStepIndex) => {
        // Ensure that we always use the updated list of steps, necessary for dynamic elements
        if (nextStepIndex) {
          // setInitialStep(nextStepIndex);
          ref.current.updateStepElement(nextStepIndex);
        }
      }}
      onComplete={() => changeCount()}
      onAfterChange={(nextStepIndex) => {
        // TODO: When https://github.com/HiDeoo/intro.js-react/issues/35 is solved, we can remove this nasty hack.
        // It should be easier to hide prev/next buttons, but this works for now.
        if (nextStepIndex === ref?.current?.props.steps.length - 1) {
          document.querySelector(".introjs-nextbutton").style.display = "none";
        } else {
          document.querySelector(".introjs-nextbutton").style.display =
            "inline";
          if (nextStepIndex === 0) {
            document.querySelector(".introjs-prevbutton").style.display =
              "none";
          } else {
            document.querySelector(".introjs-prevbutton").style.display =
              "inline";
          }
        }
      }}
    />
  );
};

export default Tour;
