
import _default from "../config/default";
import { store } from "../store/store";

export class PersonalService {
  constructor() {
    this.BASE_URL = _default.BASE_URL;
    this.AUTH_ENDPOINT = _default.ENDPOINT_AUTH;
  }

  alternateIdIndex(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/alternate_identifications`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  sectionsIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/sections`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  sectionsCreate(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/sections`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  sectionsEdit(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/sections/${data.id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  destroySectionId(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/sections/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  emergencyContactIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/emergency_contacts`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  checkUser(email, token, controller) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/axiel_users`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
      params: {
        receiver_email: email,
      },
      controller: controller.signal,
    });
  }

  alternateIdShow(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/alternate_identifications/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  emergencyContactShow(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/emergency_contacts/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  downloadDoc(link) {
    return _default.Axios({
      url: this.BASE_URL + link,
      headers: {
        "Content-Type": "application/pdf",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  masking(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/unmask`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: {
        password: data.password,
        subcategory_name: data.subcategory_name,
        subcategory_id: data?.id,
      },
    });
  }

  share(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/shares`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  multipleShare(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/share_multi_documents`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  checkShare() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/share_counts`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: {},
    });
  }

  editShare(data, id) {
    console.log({ data });
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/shares/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  shareSub(data) {
    console.log({ data });
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/subcategory_pdfs`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: { subcategories: data },
    });
  }

  shareHistory() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/shared_documents`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  endShare(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/shares/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  createAlternateId(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/alternate_identifications`,
      headers: {
        "Content-Type": "multipart/form-data",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  editAlternateId(data, id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/alternate_identifications/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  destroyAlternateId(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/alternate_identifications/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  destroyAllAlternateId(payload) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/destroy_all_alternate_identifications`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
      data: {
        ids: payload,
      },
    });
  }

  destroyEmergencyContact(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/emergency_contacts/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  destroyAllEmergencyContact(payload) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/destroy_all_emergency_contacts`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
      data: {
        ids: payload,
      },
    });
  }

  createDriverLicense(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/driver_licenses`,
      headers: {
        "Content-Type": "multipart/form-data",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  createEmergencyContact(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/emergency_contacts`,
      headers: {
        "Content-Type": "multipart/form-data",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  driverLicenseIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/driver_licenses`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  driverLicenseShow(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/driver_licenses/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  editDriverLicense(data, id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/driver_licenses/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  editEmergencyCotnacts(data, id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/emergency_contacts/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  driverLicenseDestroy(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/driver_licenses/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  driverLicenseDestroyAll(payload) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/destroy_all_driver_licenses`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
      data: {
        ids: payload,
      },
    });
  }

  driverLicenseDestroyMultiple(payload) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/destroy_all_driver_licenses`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
      data: {
        ids: payload,
      },
    });
  }

  createEmailAccount(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/email_accounts`,
      headers: {
        "Content-Type": "multipart/form-data",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  emailAccountIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/email_accounts`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  emailAccountShow(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/email_accounts/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  editEmailAccount(data, id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/email_accounts/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  emailAccountDestroy(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/email_accounts/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  emailAccountDestroyAll(payload) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/destroy_all_email_accounts`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
      data: {
        ids: payload,
      },
    });
  }

  createLoyaltyProgram(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/loyalty_programs`,
      headers: {
        "Content-Type": "multipart/form-data",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  loyaltyProgramIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/loyalty_programs`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  loyaltyProgramShow(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/loyalty_programs/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  editLoyaltyProgram(data, id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/loyalty_programs/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  loyaltyProgramDestroy(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/loyalty_programs/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  loyaltyProgramDestroyAll(payload) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/destroy_all_loyalty_programs`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
      data: {
        ids: payload,
      },
    });
  }

  createPassports(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/passports`,
      headers: {
        "Content-Type": "multipart/form-data",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  passportIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/passports`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  passportShow(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/passports/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  editPassports(data, id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/passports/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  passportDestroy(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/passports/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  passportDestroyAll(payload) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/destroy_all_passports`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
      data: {
        ids: payload,
      },
    });
  }

  createPersonalInformation(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/personal_information`,
      headers: {
        "Content-Type": "multipart/form-data",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  personalInformationIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/personal_information`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  personalInformationShow(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/personal_information/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  editPersonalInformation(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/personal_information`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  personalInformationDestroy(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/personal_information`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  personalInformationDestroyAll(payload) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/destroy_all_personal_information`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
      data: {
        ids: payload,
      },
    });
  }

  createVisas(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/visas`,
      headers: {
        "Content-Type": "multipart/form-data",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  visasIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/visas`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  visasShow(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/visas/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  editVisas(data, id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/visas/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  visaDestroy(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/visas/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  visaDestroyAll(payload) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/destroy_all_visas`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
      data: {
        ids: payload,
      },
    });
  }

  createWebLogins(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/web_logins`,
      headers: {
        "Content-Type": "multipart/form-data",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  webLoginsIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/web_logins`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  webLoginsShow(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/web_logins/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  editWebLogins(data, id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/web_logins/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  webLoginDestroy(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/web_logins/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  webLoginDestroyAll(payload) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/destroy_all_web_logins`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
      data: {
        ids: payload,
      },
    });
  }

  addUserPermission(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/invite_collaborator`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data,
    });
  }

  administratorsPermission(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/collaborators`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: {
        ...data,
        profile_id: `${store.getState().profile?.profileInfo?.profile?.id}`,
      },
    });
  }

  collaborationStatus(id, status) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/collaboration_status`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: {
        collaboration_id: id,
        status: status,
      },
    });
  }

  collaborationEditDate(id, status) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/edit_access`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: {
        collaboration_id: id,
        end_date: status,
      },
    });
  }




  collaboratorDelete(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/collaboration`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
      data: {
        collaboration_id: id,
      },
    });
  }

  collaboratorGet(data){
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
        }/collaborators_actions`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "Get",
      params: data,
    })

  }
}
