import React, { useEffect, useCallback, useRef, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { PersonalService } from "../../../services/personal.service";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs";
import { store } from "../../../store/store";
import _default from "../../../config/default.js";


const useStyles = makeStyles(styles);
const {Axios} = _default;

function ShareModal(props) {
  const name =
    store.getState().profile.profileInfo?.profile?.first_name ||
    store.getState().profile.profileInfo?.profile?.business_name;
  const [isLoading, setIsLoading] = useState(false);
  const [allowDownload, setAllowDownload] = React.useState("yes");
  const [checkA, setCheckA] = React.useState(false);
  const [checkB, setCheckB] = React.useState(false);
  const [validation, setValidation] = React.useState({
    subject: null,
    personal_message: null,
  });
  const [checkMessage, setCheckMessage] = React.useState(null);
  const errRef = useRef(null);
  const [emailNotification, setEmailNotification] = React.useState("yes");
  const [data, setData] = React.useState({
    receivers_email: null,
    subject: `TagData's  ${name} shared information with you`,
    personal_message: null,
    expiration_date: null,
    allow_download: true,
    terms: "",
    notify_recipient_when_permissions_change: false,
    notify_when_recipient_interacts: false,
    require_authentication: true,
    shared_documents_attributes: [
      {
        document_id: props?.id || JSON.parse(localStorage.getItem("id")),
        title: props?.title,
        profile_id: store.getState().profile.profileInfo?.profile?.id,
      },
    ],
  });
  const personalService = new PersonalService();
  const classes = useStyles();
  useEffect(() => {
    // props.closeParent()
  }, []);
  const [err, setErr] = useState(null);

  const handleSubmit = async () => {
    setErr(null);
    setIsLoading(true);
    if (props.multiple) {
      const payload = {
        ...data,
        shared_documents_attributes: props.payload,
      };
      try {
        let response = await personalService.multipleShare(payload);
        console.log({ response });
        setIsLoading(false);
        localStorage.removeItem("id");
        props.setMultiple(false);
        props.handleClose();
      } catch (e) {
        setIsLoading(false);
        setErr(
          e?.response && e?.response?.data
            ? e.response.data.message
            : "something went wrong, please try later"
        );
        errRef.current.scrollIntoView();
      }
    } else {
      try {
        let response = await personalService.share(data);
        console.log({ response });
        setIsLoading(false);
        localStorage.removeItem("id");

        props.handleClose();
      } catch (e) {
        setIsLoading(false);
        setErr(
          e?.response && e?.response?.data
            ? e?.response?.data?.error ||
                e.response.data.message.share[0] ||
                e.response.data.message.expiration_date[0]
            : "something went wrong, please try later"
        );
        errRef.current.scrollIntoView();
      }
    }
  };

  const handleCheck = async (email) => {
    const controller = new AbortController();
    let cancelToken;
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    controller.abort();
    cancelToken = Axios.CancelToken.source();
    try {
      const response = await personalService.checkUser(
        email,
        cancelToken,
        controller
      );
      const message = await response.data.message;
      if (!message) {
        setCheckMessage(
          "This user does not have a TagData account and if you are asking the user to authenticate first, receiver won't be able to view this information without registering for Tag."
        );
      } else {
        setCheckMessage(
          "This user won't be able to view information without logging into TagData as you are asking the user to authenticate first."
        );
      }
    } catch (e) {}
  };

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={
            props?.category && props?.category !== "null"
              ? name + "'s " + props?.category
              : props?.title
          }
          color={color.white}
        />
        <div id={"err"} className={classes.modalBody}>
          <div className="w-100 d-flex flex-row mb-2">
            <Typography
              variant="h6"
              component="h6"
              align="left"
              style={{ color: "#060d5c", fontWeight: "bold" }}
            >
              {"1. Recipient Information"}
            </Typography>
          </div>
          {err && (
            <div className="w-100 d-flex flex-row mb-5">
              <Typography
                variant="p"
                component="p"
                align="left"
                style={{ color: "red", fontWeight: "normal" }}
              >
                {err}
              </Typography>
            </div>
          )}
          {checkMessage && data.require_authentication && (
            <div className="w-100 d-flex flex-row mb-5">
              <Typography
                variant="p"
                component="p"
                align="left"
                style={{ color: "red", fontWeight: "normal" }}
              >
                {checkMessage}
              </Typography>
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                Email
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <FormInput
                variant="outlined"
                fullWidth={true}
                onChange={(e) => {
                  setData({ ...data, receivers_email: e.target.value });
                  handleCheck(e.target.value);
                }}
                margin="dense"
                type="email"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                Subject
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              {validation.subject && (
                <Typography
                  variant="p"
                  component="p"
                  style={{ color: color.themeRed }}
                >
                  {validation.subject}
                </Typography>
              )}
              <FormInput
                variant="outlined"
                onChange={(e) => {
                  if (/^[A-Za-z0-9'? ,_-]+$/gm.test(e.target.value)) {
                    setValidation({
                      ...validation,
                      subject: null,
                    });
                    setData({ ...data, subject: e.target.value });
                  } else {
                    setValidation({
                      ...validation,
                      subject: "Must only contain alphanumeric characters.",
                    });
                  }
                }}
                fullWidth={true}
                value={`TagData's ${name} shared information with you`}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                Personal Message
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              {validation.personal_message && (
                <Typography
                  variant="p"
                  component="p"
                  style={{ color: color.themeRed }}
                >
                  {validation.personal_message}
                </Typography>
              )}
              <FormInput
                onChange={(e) => {
                  if (/^[A-Za-z0-9? ,_-]+$/gm.test(e.target.value)) {
                    setValidation({
                      ...validation,
                      personal_message: null,
                    });
                    setData({ ...data, personal_message: e.target.value });
                  } else {
                    setValidation({
                      ...validation,
                      personal_message:
                        "Must only contain alphanumeric characters.",
                    });
                  }
                }}
                variant="outlined"
                fullWidth={true}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="mb-1">
              <Typography
                variant="h6"
                component="h6"
                align="left"
                style={{ color: "#060d5c", fontWeight: "bold" }}
              >
                {"2. Set access and usage terms (Data Usage License Agreement)"}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                Require Authentication
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <FormControl component="fieldset" className="d-flex flex-row">
                <RadioGroup
                  aria-label="gender"
                  name="status"
                  value={allowDownload}
                  onChange={() => {
                    setAllowDownload("yes");
                    setData({ ...data, require_authentication: true });
                  }}
                  className="flex-row align-items-center me-3"
                >
                  <FormControlLabel
                    className="m-0"
                    value="yes"
                    control={<Radio style={{ color: "#ffb722" }} />}
                  />
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Yes"}
                  </Typography>
                </RadioGroup>
                <RadioGroup
                  aria-label="gender"
                  name="status"
                  value={allowDownload}
                  onChange={() => {
                    setAllowDownload("no");
                    setData({ ...data, require_authentication: false });
                  }}
                  className="flex-row align-items-center"
                >
                  <FormControlLabel
                    className="m-0"
                    value="no"
                    control={<Radio style={{ color: "#ffb722" }} />}
                  />
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {"No"}
                  </Typography>
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                Expiration Date
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <FormInput
                id="start_date"
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                type="date"
                // fieldClassName={classes.profileInputs}
                placeholder={"Ex. 12/01/1992"}
                onChange={(event) =>
                  setData({
                    ...data,
                    expiration_date: new Date(event.target.value)
                      .toISOString()
                      .slice(0, 10),
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                Allow Downloads
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <FormControl component="fieldset" className="d-flex flex-row">
                <RadioGroup
                  aria-label="gender"
                  name="status"
                  value={emailNotification}
                  onChange={() => {
                    setEmailNotification("yes");
                    setData({ ...data, allow_download: true });
                  }}
                  className="flex-row align-items-center me-3"
                >
                  <FormControlLabel
                    className="m-0"
                    value="yes"
                    control={<Radio style={{ color: "#ffb722" }} />}
                  />
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Yes"}
                  </Typography>
                </RadioGroup>
                <RadioGroup
                  aria-label="gender"
                  name="status"
                  value={emailNotification}
                  onChange={() => {
                    setEmailNotification("no");
                    setData({ ...data, allow_download: false });
                  }}
                  className="flex-row align-items-center"
                >
                  <FormControlLabel
                    className="m-0"
                    value="no"
                    control={<Radio style={{ color: "#ffb722" }} />}
                  />
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {"No"}
                  </Typography>
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                Additional Terms
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <FormInput
                onChange={(e) => setData({ ...data, terms: e.target.value })}
                variant="outlined"
                fullWidth={true}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                Email Notifications
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkA}
                    name="checkedA"
                    onChange={(e) => {
                      setCheckA(e.target.checked);
                      setData({
                        ...data,
                        notify_recipient_when_permissions_change:
                          e.target.checked,
                      });
                    }}
                    style={{ color: "#ffb722" }}
                  />
                }
                label="Notify Recipient When Permissions Change"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkB}
                    name="checkedA"
                    onChange={(e) => {
                      setCheckB(e.target.checked);
                      setData({
                        ...data,
                        notify_when_recipient_interacts: e.target.checked,
                      });
                    }}
                    style={{ color: "#ffb722" }}
                  />
                }
                label="Notify me when the recipient interacts with this document"
              />
            </Grid>
            {(props?.passwordProtected || props?.multiple) && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    Account Password*
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <FormInput
                    variant="outlined"
                    fullWidth={true}
                    margin="dense"
                    required={true}
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                    type="text"
                    id="txtPassword"
                    onChange={(event) =>
                      setData({
                        ...data,
                        password: event.target.value,
                      })
                    }
                  />
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              {!isLoading ? (
                <CustomButton
                  type="submit"
                  btnText="Share"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  disabled={
                    validation.personal_message ||
                    validation.subject ||
                    !data.receivers_email
                  }
                  borderRadius="8px"
                  color="#fff"
                  onClick={handleSubmit}
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

export default ShareModal;
