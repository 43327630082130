import "date-fns";
import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  useTheme,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { dateFormat } from "../../../Utils/dateFormate.js";

import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { HealthService } from "../../../services/health.service.js";
import SureToAdd from "../Alerts/SureToAdd/SureToAdd.js";
import ChooseWebLogins from "../ChooseWebLogins/ChooseWebLogins.js";
import EditWebLogin from "../EditWebLogin/EditWebLogin.js";
import AddDocument from "../AddDocument/AddDocument.js";
import EditDocument from "../EditDocument/EditDocument.js";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { getDateIgnoreTimezone } from "../../../Utils/dateHelper.js";
import disableCheck from "../../../Utils/disableCheck.js";
import { upperCaseErrorKeys } from "../../../Utils/stringManipulation.js";
import { DocumentService } from "../../../services/document.service.js";
import AlertPopup from "../Alerts/Generic/GenericAlert.js";
import SpinnerModal from "../Alerts/SpinnerModal/SpinnerModal.js";
import FileViewer from "../../../components/FileViewer/FileViewer.js";
import PasswordVerification from "../PasswordVerification/PasswordVerification.js";
import _default from "../../../config/default.js";

const dosageUnit = [
  "Colony forming units per milliliter (cfu/ml)",
  "International unit (iu)",
  "Micrograms (mcg)",
  "Milliequivalent (meq)",
  "Milliequivalent per milliliter (meq/ml)",
  "Milligram (mg)",
  "Milligram per milliliter (mg/ml)",
  "Milliliter (ml)",
  "Percent (%)",
  "Units (unt)",
  "Units per milliliter (unt/ml)",
];

const quantityUnits = [
  "Applicatorfuls",
  "Bags",
  "Bars",
  "Capsules",
  "Doses",
  "Dropperfuls",
  "Drops",
  "Grams (g)",
  "Inhalations",
  "Lozenges",
  "Micrograms (mcg)",
  "Milligrams (mg)",
  "Milliliters (ml)",
  "Packets",
  "Pads",
  "Patches",
  "Percent (%)",
  "Puffs",
  "Scoops",
  "Shots",
  "Sprays",
  "Suppositories",
  "Syringe",
  "Tablespoons (tbsp)",
  "Tablets",
  "Teaspoons (tsp)",
  "Units (U)",
];

const frequency = [
  "Daily",
  "Daily: Morning",
  "Daily: Afternoon",
  "Daily: Evening",
  "Daily: Bedtime",
  "Twice per day",
  "Three times per day",
  "Four times per day",
  "Every hour",
  "Every 2 hours",
  "Every 3 hours",
  "Every 4 hours",
  "Every 6 hours",
  "Every 8 hours",
  "Every 12 hours",
  "Every 24 hours",
  "Every other day",
  "Once per week",
  "Twice per week",
  "Every two weeks",
  "Take Monthly",
  "As needed",
  "Once",
  "Other",
];

const timing = [
  { show: "1 week from expiration", value: "one_week" },
  { show: "2 weeks from expiration", value: "two_weeks" },
  { show: "1 month from expiration", value: "one_month" },
  { show: "3 months from expiration", value: "three_months" },
  { show: "6 months from expiration", value: "six_months" },
];

const frequancy = [
  { show: "Daily", value: "daily" },
  { show: "Every other Day", value: "every_other_day" },
  { show: "Weekly", value: "weekly" },
  { show: "Biweekly", value: "bi_weekly" },
];

const methodTaken = [
  "By Injection",
  "By Mouth",
  "Inhaled",
  "Nasal Spray or Drops",
  "Rectally",
  "Through a feeding tube",
  "Through an Intravenous (IV) line",
  "Through the skin",
  "Under the tongue",
  "Vaginally",
  "With Food",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles(styles);

function EditMedication(props) {
  const classes = useStyles();
  const errRef = useRef(null);
  const healthService = new HealthService();
  const [err, setErr] = React.useState([]);
  const [link, setLink] = React.useState(null);
  const [showDoc, setShowDoc] = React.useState(false);
  const [id, setId] = React.useState(null);
  const [spinner, setSpinner] = React.useState(false);
  const [alert, setAlert] = React.useState({
    open: false,
    type: null,
    btnText: null,
    cancelText: null,
    heading: null,
    message: null,
  });
  const [password, setPassword] = React.useState(null);
  const [showPasswordVerification, setShowPasswordVerification] =
    React.useState(false);
  const [documentModal, setDocumentModal] = React.useState(false);
  const [editDoc, setEditDoc] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [currentDocument, setCurrentDocument] = React.useState(null);
  const obj = props?.medication?.associated_documents_attributes
    ? props?.medication?.associated_documents_attributes
    : [];
  const [doc, setDoc] = React.useState(
    props?.medication?.documents ? props?.medication?.documents : []
  );
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [other, setOther] = React.useState(false);
  const [reminder, setReminder] = React.useState(
    props?.medication?.alert_attributes?.status === "active"
  );
  const [editWebLogin, setEditWebLogin] = React.useState(false);
  const [webLogin, setWebLogin] = React.useState(
    (props?.medication?.web_logins && props?.medication?.web_logins[0]) || null
  );
  const [webLoginModal, setWebLoginModal] = React.useState(false);
  const [data, setData] = React.useState({
    associated_documents_attributes: obj,
    medicine: props?.medication?.medicine,
    method_taken: props?.medication?.method_taken,
    currently_taking: props?.medication?.currently_taking,
    medication_type: props?.medication?.medication_type,
    clinician: props?.medication?.clinician,
    prescription_number: props?.medication?.prescription_number,
    quantity_amount: props?.medication?.quantity_amount,
    potency_amount: props?.medication?.potency_amount,
    potency_units: props?.medication?.potency_units,
    ...(props?.medication?.alert_attributes && {
      alert_attributes: {
        timing: props?.medication?.alert_attributes?.timing,
        frequency: props?.medication?.alert_attributes?.frequency,
        status: props?.medication?.alert_attributes?.status,
        id: props?.medication?.alert_attributes?.id,
        profile_id: props?.profile?.profileInfo?.profile.id,
      },
    }),
    quantity_units: props?.medication?.quantity_units,
    frequency: props?.medication?.frequency,
    dispense_date: props?.medication?.dispense_date
      ? props?.medication?.dispense_date?.replaceAll("-", "/")
      : null,
    refills_remaining: props?.medication?.refills_remaining,
    start_date: props?.medication?.start_date
      ? props?.medication?.start_date?.replaceAll("-", "/")
      : null,
    expiration_date: props?.medication?.expiration_date
      ? props?.medication?.expiration_date?.replaceAll("-", "/")
      : null,
    notes: props?.medication?.notes,
    associated_logins_attributes: [
      {
        web_login_id:
          props?.medication?.web_logins && props?.medication?.web_logins[0]?.id,
        id:
          props?.medication?.associated_logins &&
          props?.medication?.associated_logins[0]?.id,
        _destroy: 0,
      },
    ],
  });
  const [sureToAdd, setSureToAdd] = React.useState({ open: false, str: null });

  const handleOpenSureToAdd = (str) => {
    setSureToAdd({ open: true, str: str ?? null });
  };
  const handleCloseSureToAdd = () => {
    if (sureToAdd.str !== null) {
      props?.handleClose();
    }
    setSureToAdd({ open: false, str: null });
  };

  const handleSubmit = () => {
    handleCloseSureToAdd();
    // if (
    //   !data.associated_logins_attributes[0].web_login_id &&
    //   data.associated_logins_attributes[0]._destroy === 0
    // ) {
    //   delete data["associated_logins_attributes"];
    // }
    if (!data || !data.associated_logins_attributes || !data.associated_logins_attributes[0]) {
      return;
    }
    
    if (!data.associated_logins_attributes[0].web_login_id && data.associated_logins_attributes[0]._destroy === 0) {
      delete data["associated_logins_attributes"];
    }
    
    const temp = data?.associated_documents_attributes?.filter(
      (e) => e.document_id !== null
    );
    if (props?.wellness?.associated_documents_attributes) {
      setData({ ...data, associated_documents_attributes: temp });
    } else {
      if (temp && temp.length > 0) {
        setData({ ...data, associated_documents_attributes: temp });
      } else {
        let temp2 = delete data["associated_documents_attributes"];
        setData(data);
      }
    }
    console.log({ data });
    // const temp = data?.associated_documents_attributes  ?.filter(
    //   (e) => e.document_id !== null
    // );
    // if (props?.medication?.associated_documents_attributes  ) {
    //   setData({ ...data, associated_documents_attributes: temp });
    // } else {
    //   if (temp && temp.length > 0){
    //     setData({ ...data, associated_documents_attributes: temp });
    //   } else {
    //     let temp2 = delete data["associated_documents_attributes"];
    //     setData(data);
    //   }
    // }

    let payload = { ...data };
    if (payload.start_date) {
      payload = {
        ...payload,
        start_date: getDateIgnoreTimezone(new Date(payload.start_date))
          .toISOString()
          .slice(0, 10),
      };
    }
    if (payload.expiration_date) {
      payload = {
        ...payload,
        expiration_date: getDateIgnoreTimezone(
          new Date(payload.expiration_date)
        )
          .toISOString()
          .slice(0, 10),
      };
    }

    setIsLoading(true);
    healthService
      .editMedications(payload, props?.medication?.id)
      .then((response) => {
        setIsLoading(false);
        props.forceRerender();
        props.handleClose();

        console.log(response.data);
      })
      .catch((err) => {
        errRef.current.scrollIntoView();
        setIsLoading(false);
        if (err.response && err.response.data.message) {
          if (err.response?.data?.message === "Please enter a valid date") {
            setErr([err.response?.data?.message]);
          } else {
            let array = [];
            Object.keys(err.response.data.message).forEach((key) => {
              let temp =
                key === "alert.alert" ? "alert" : upperCaseErrorKeys(key);
              array.push(`${temp} ${err.response.data.message[key]}`);
            });
            setErr(array);
          }
        } else {
          setErr(["Internal Server Error"]);
        }
      });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChange = (event) => {
    if (event.target.name === "frequency1") {
      if (event.target.value !== "Other") {
        setOther(false);
        setData({ ...data, frequency: event.target.value });
      } else {
        setOther(true);
      }
    }else{
      setData({ ...data, [event.target.name]: event.target.value });
    }
  };

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChangeCountry = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const documentService = new DocumentService();

  const show = (docId) => {
    const documentId = docId ?? id;
    setSpinner(true);
    documentService
      .show(documentId, password)
      .then((res) => {
        setSpinner(false);
        setLink({
          attachment: res.data.document.attachment,
          contentType: res.data.document.content_type,
        });
        setShowDoc(true);
        setShowPasswordVerification(false);
        setPassword(null);
      })
      .catch((err) => {
        setSpinner(false);
        setPassword(null);
        setAlert({
          type: "Error",
          open: true,
          cancelText: "CANCEL",
          heading:
            err?.response && err?.response?.status === 422
              ? "Unauthorized!"
              : "Error!",
          message:
            err?.response && err?.response?.status === 422
              ? "Wrong password! please try again."
              : "An unknown error occurred, please try later.",
        });
      });
  };

  const initialRef = useRef(null);
  React.useEffect(() => {
    initialRef.current = data;
  }, []);

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={() => {
          !disableCheck(initialRef, data)
            ? handleOpenSureToAdd("exit")
            : props?.handleClose();
        }}
        width="35%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        {alert.open && (
          <AlertPopup
            btnText={alert.btnText}
            cancelText={alert.cancelText}
            open={alert.open}
            type={alert.type}
            heading={alert.heading}
            message={alert.message}
            isLoading={false}
            handleClose={() => {
              setAlert({
                open: false,
                btnText: null,
                cancelText: null,
                heading: null,
                message: null,
                type: null,
              });
            }}
            handleClick={() => {
              setAlert({
                open: false,
                btnText: null,
                cancelText: null,
                heading: null,
                message: null,
                type: null,
              });
            }}
          />
        )}
        {spinner && <SpinnerModal open={spinner} msg={"Loading"} />}
        {showDoc && (
          <FileViewer
            contentType={link?.contentType}
            link={link?.attachment}
            open={showDoc}
            handleClose={() => setShowDoc(false)}
          />
        )}
        {showPasswordVerification && (
          <PasswordVerification
            open={showPasswordVerification}
            handleClose={() => {
              setShowPasswordVerification(false);
              setId(null);
            }}
            handleChange={(e) => setPassword(e)}
            handleSubmit={() => {
              show();
            }}
          />
        )}
        <ModalHeader
          closeModal={() => {
            !disableCheck(initialRef, data)
              ? handleOpenSureToAdd("exit")
              : props?.handleClose();
          }}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Edit " + data.medicine}
          color={color.white}
        />
        <div className={classes.modalBody} ref={errRef}>
          <div className="w-100 d-flex flex-row mb-4">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              {"Health > Medications > Edit"}
            </Typography>
          </div>
          {err?.length > 0 && (
            <div className="w-100 d-flex flex-row mb-4">
              {err?.map((val, key) => {
                return (
                  <Typography
                    key={key}
                    variant="p"
                    component="p"
                    align="left"
                    style={{
                      color: "red",
                      fontWeight: "normal",
                      marginBottom: "2px",
                    }}
                  >
                    {val.charAt(0).toUpperCase() + val.slice(1)}
                  </Typography>
                );
              })}
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            {editDoc && (
              <EditDocument
                password_protected={currentDocument?.password_protected}
                docs={doc}
                category={currentDocument?.category}
                id={currentDocument.id}
                title={currentDocument.title}
                open={editDoc}
                setDocs={setDoc}
                handleClose={() => setEditDoc(false)}
              />
            )}
            {documentModal && (
              <AddDocument
                setData={setData}
                setDoc={setDoc}
                data={data}
                associated_documents_attributes={
                  props?.medication?.associated_documents_attributes
                    ? true
                    : false
                }
                doc={doc}
                open={documentModal}
                handleClose={() => setDocumentModal(false)}
                closeModal={() => setDocumentModal(false)}
                category={"Personal"}
              />
            )}
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Medication *"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="medicine"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                value={data?.medicine}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Status"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="currently_taking"
                  value={data?.currently_taking}
                  onChange={(e) =>
                    setData({
                      ...data,
                      currently_taking:
                        e.target.value === "false" ? false : true,
                      ...(e.target.value === 'false' && {
                        alert_attributes: {
                          ...data.alert_attributes,
                          status: "inactive",
                        },
                      }),
                      ...(e.target.value === 'true' && {
                        alert_attributes: {
                          ...data.alert_attributes,
                          status: "active",
                        },
                      })
                    })
                  }
                  className="flex-row"
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Currently Taking"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="No Longer Taking"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Medication Type"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="medication_type"
                  value={data?.medication_type}
                  onChange={handleChange}
                  className="flex-row"
                >
                  <FormControlLabel
                    value="over_the_counter"
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Over the Counter"
                  />
                  <FormControlLabel
                    value="prescription"
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Prescription"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Clinician"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="clinician"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                value={data?.clinician}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Prescription Number"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="prescription_number"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                value={data?.prescription_number}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Dosage"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="potency_amount"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                value={data?.potency_amount}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Dosage Unit"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  id="state"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  value={data?.potency_units || "Select Dosage Units"}
                  onChange={handleChange}
                  name="potency_units"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  <MenuItem value={"Select Dosage Units"} disabled>
                    Select Dosage Units
                  </MenuItem>
                  {dosageUnit?.map((state, index) => {
                    return (
                      <MenuItem
                        value={state}
                        key={index}
                        style={getStyles(name, personName, theme)}
                      >
                        {state}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Quantity"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="quantity_amount"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                value={data?.quantity_amount}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Quantity Units"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  id="state"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  value={data?.quantity_units || "Select Quantity Units"}
                  onChange={handleChange}
                  name="quantity_units"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  <MenuItem value={"Select Quantity Units"} disabled>
                    Select Quantity Units
                  </MenuItem>
                  {quantityUnits?.map((state, index) => {
                    return (
                      <MenuItem
                        value={state}
                        key={index}
                        style={getStyles(name, personName, theme)}
                      >
                        {state}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Frequency"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  id="state"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  value={
                    frequency?.includes(data?.frequency)
                      ? data?.frequency
                      : "Other"
                  }
                  onChange={handleChange}
                  name="frequency1"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  <MenuItem disabled>Select Frequency</MenuItem>
                  {frequency?.map((state, index) => {
                    return (
                      <MenuItem
                        value={state}
                        key={index}
                        style={getStyles(name, personName, theme)}
                      >
                        {state}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {other ? (
                <FormInput
                  name="frequency"
                  onChange={handleChange}
                  label=""
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  value={data?.frequency}
                />
              ) : null}
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Method Taken"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  id="state"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  value={data?.method_taken}
                  onChange={handleChange}
                  name="method_taken"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  <MenuItem disabled>Select Method Taken</MenuItem>
                  {methodTaken?.map((state, index) => {
                    return (
                      <MenuItem
                        value={state}
                        key={index}
                        style={getStyles(name, personName, theme)}
                      >
                        {state}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Dispense Date"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  inputVariant="outlined"
                  format={dateFormat()}
                  id="start_date"
                  placeholder={dateFormat()?.toUpperCase()}
                  value={data?.dispense_date}
                  onChange={(date) => {
                    setData({
                      ...data,
                      dispense_date: date,
                    });
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Refills Remaining"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="refills_remaining"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                value={data?.refills_remaining}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Start Date"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  inputVariant="outlined"
                  format={dateFormat()}
                  id="start_date"
                  placeholder={dateFormat()?.toUpperCase()}
                  value={data?.start_date}
                  onChange={(date) => {
                    setData({
                      ...data,
                      start_date: date,
                    });
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"End Date"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  inputVariant="outlined"
                  format={dateFormat()}
                  id="start_date"
                  placeholder={dateFormat()?.toUpperCase()}
                  value={data?.expiration_date}
                  onChange={(date) => {
                    setData({
                      ...data,
                      expiration_date: date,
                    });
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
              {props?.preference?.is_active && (
                <>
                  {!reminder && data?.currently_taking === true &&(
                    <CustomButton
                      btnText={"Add Reminder"}
                      disabled={!data?.expiration_date}
                      textAlign="center"
                      display={"block"}
                      background="transparent"
                      hoverBg="#0042a8"
                      border="1px solid #0042a8"
                      borderRadius="8px"
                      color="#0042a8"
                      onClick={() => {
                        setReminder(true);
                        setData({
                          ...data,
                          alert_attributes: {
                            ...(data.alert_attributes
                              ? {
                                  ...data.alert_attributes,
                                }
                              : {
                                  timing: "one_week",
                                  frequency: "daily",

                                  profile_id:
                                    props?.profile?.profileInfo?.profile.id,
                                }),
                            status: "active",
                          },
                        });
                      }}
                    />
                  )}
                </>
              )}
            </Grid>
            {reminder && data?.currently_taking === true  && (
              <div className={"w-75 m-2 border m-5"}>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Start Date"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8} sm={8}>
                  <FormControl id="security-question" fullWidth={true}>
                    <Select
                      id="id_type"
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      value={
                        timing.filter(
                          (i) => i.value === data?.alert_attributes?.timing
                        )[0]?.value
                      }
                      required={true}
                      onChange={(e) => {
                        setData({
                          ...data,
                          alert_attributes: {
                            ...data.alert_attributes,
                            timing: e.target.value,
                          },
                        });
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled>Select Start Date</MenuItem>
                      {timing.map((t) => {
                        return <MenuItem value={t.value}>{t.show}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Frequency"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8} sm={8}>
                  <FormControl id="security-question" fullWidth={true}>
                    <Select
                      id="id_type"
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      value={
                        frequancy.filter(
                          (i) => i.value === data?.alert_attributes?.frequency
                        )[0]?.value
                      }
                      required={true}
                      onChange={(e) => {
                        setData({
                          ...data,
                          alert_attributes: {
                            ...data.alert_attributes,
                            frequency: e.target.value,
                          },
                        });
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled>Select Frequency</MenuItem>
                      {frequancy.map((f) => {
                        return <MenuItem value={f.value}>{f.show}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <CustomButton
                    textAlign="center"
                    display={"block"}
                    padding="8px 25px"
                    margin={"5px"}
                    background="#0042a8"
                    hoverBg="#0042a8"
                    border="0"
                    borderRadius="8px"
                    color="#fff"
                    btnText={"Remove"}
                    onClick={() => {
                      setData({
                        ...data,
                        alert_attributes: {
                          ...data.alert_attributes,
                          status: "inactive",
                        },
                      });
                      setReminder(false);
                    }}
                  />
                </Grid>
              </div>
            )}
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Notes"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="notes"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                value={data?.notes}
              />
            </Grid>
            {webLoginModal && (
              <ChooseWebLogins
                setData={setData}
                setWebLogin={setWebLogin}
                data={data}
                open={webLoginModal}
                webLogin={data?.associated_logins_attributes[0]?.web_login_id}
                handleClose={() => setWebLoginModal(false)}
                closeModal={() => setWebLoginModal(false)}
                category={"Personal"}
              />
            )}
            {editWebLogin && (
              <EditWebLogin
                open={editWebLogin}
                handleClose={() => setEditWebLogin(false)}
                webLogin={webLogin}
              />
            )}
            {editWebLogin && (
              <EditWebLogin
                open={editWebLogin}
                handleClose={() => setEditWebLogin(false)}
                webLogin={webLogin}
              />
            )}
            {webLogin && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Web Login"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {webLogin?.user_name}
                  </Typography>
                  <CustomButton
                    btnText={"Remove"}
                    textAlign="center"
                    display={"block"}
                    background="transparent"
                    hoverBg="#0042a8"
                    border="1px solid #0042a8"
                    borderRadius="8px"
                    color="#0042a8"
                    onClick={() => {
                      setWebLogin(null);
                      setData({
                        ...data,
                        associated_logins_attributes: [
                          {
                            web_login_id:
                              data.associated_logins_attributes[0].web_login_id,
                            id: data.associated_logins_attributes[0].id,
                            _destroy: 1,
                          },
                        ],
                      });
                    }}
                  />
                  <CustomButton
                    btnText={"Edit"}
                    textAlign="center"
                    marginLeft="8px"
                    display={"block"}
                    background="transparent"
                    hoverBg="#0042a8"
                    border="1px solid #0042a8"
                    borderRadius="8px"
                    color="#0042a8"
                    onClick={() => {
                      setEditWebLogin(true);
                    }}
                  />
                </Grid>
              </>
            )}
            {doc.length > 0 && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Document"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                  {doc.map((e, k) => (
                    <>
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: color.themeColorNew }}
                        key={k}
                      >
                        {e?.title}
                      </Typography>
                      <CustomButton
                        btnText={"Remove"}
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          let temp = data.associated_documents_attributes?.map(
                            (d) => {
                              if (d.document_id === e.id) {
                                return {
                                  ...d,
                                  _destroy: 1,
                                };
                              } else {
                                return d;
                              }
                            }
                          );
                          let newDoc = doc?.filter((d) => d.id !== e.id);
                          setDoc(newDoc);
                          setData({
                            ...data,
                            associated_documents_attributes: temp,
                          });
                        }}
                      />

                      <CustomButton
                        btnText={"Edit"}
                        className="ms-2"
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          setCurrentDocument(e);
                          setEditDoc(true);
                        }}
                      />
                      <CustomButton
                        btnText={"View"}
                        className="ms-2"
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          if (!props?.medication?.documents) {
                            show(e?.id);
                          } else {
                            if (e?.attachment) {
                              setLink({
                                attachment: _default.BASE_URL + e.attachment,
                                contentType: e.content_type,
                              });
                              setShowDoc(true);
                            } else {
                              setId(e?.id);
                              setShowPasswordVerification(true);
                            }
                          }
                        }}
                      />
                    </>
                  ))}
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              <CustomButton
                btnText="Add Document"
                className="mb-sm-2"
                textAlign="center"
                display={"block"}
                padding="8px 25px"
                background="#0042a8"
                hoverBg="#0042a8"
                border="0"
                borderRadius="8px"
                color="#fff"
                marginRight={"10px"}
                onClick={() => setDocumentModal(true)}
              />

              {!webLogin && (
                <CustomButton
                  className="mb-sm-2"
                  btnText="Add Web Login"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  marginRight={"10px"}
                  onClick={() => setWebLoginModal(true)}
                />
              )}

              {!isLoading ? (
                <CustomButton
                  type="submit"
                  btnText="Save"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  disabled={disableCheck(initialRef, data)}
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  onClick={() => handleOpenSureToAdd(null)}
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
      <SureToAdd
        open={sureToAdd.open}
        handleClose={handleCloseSureToAdd}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
    profile: state.profile,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditMedication);
