import W2FormImg from "../../../img/W2Form.png"
const AccountSettingTabs = (theme) => ({
  w25Per: { width: "25%" },
  w35Per: { width: "35%" },
  w50Per: { width: "50%" },
  w75Per: { width: "75%" },
  w100Per: { width: "100%" },
  calanderIcon : {
    width: 'auto',
    "& input": {
      display: 'none',
    },
    "& .MuiInput-underline:before": {
      display: 'none',
    },
    "& button": {
      padding: '0',
    },
    "& button:hover": {
      background: 'transparent',
    }
  },
  marginBottom18px: {
    marginBottom: "18px",
    "& .PrivateNotchedOutline-root-159": {
      top: "0",
    },
  },
  marginBottom14px: {
    marginBottom: "14px",
  },
  marginTop18px: {
    marginTop: "18px",
  },
  normalFontSize: {
    fontSize: "17px",
  },
  FontSize14: {
    fontSize: "14px",
  },
  tabsMain: {
    marginBottom: "50px",
    "& .MuiTabs-flexContainer": {
      overflow: "auto",
    },
    "& ::-webkit-scrollbar": {
      height: "0",
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: "#0042a8",
      color: "white",
    },
    "& Button": {
      background: "#fff",
      borderRadius: "10px",
      marginRight: "15px",
      backgroundColor: "#fff",
      minWidth: "160px",
      maxWidth: "250px",
      width: "max-content",
      color: "black",
      fontFamily: "Urbane!important",
      fontSize: "13px",
      opacity: 1,
      textTransform: "capitalize",
      "&:hover, &:active, &:focus": {
        backgroundColor: "#0042a8",
        color: "white",
        opacity: 1,
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
  root: {
    flexGrow: 1,
    backgroundColor: "transparent",
    padding: "0",
    borderRadius: "8px",
    "& .MuiBox-root": {
      padding: "0px",
    },
    "&>fieldset": {
      top: "0",
    },
  },
  accountCard: {
    padding: "45px 55px",
    marginTop: "0",
    marginBottom: "40px",
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: "45px 25px",
    },
  },
  profileInputs: {
    background: "#fff",
    borderRadius: "8px",
    boxShadow: "0px 0px 9px 1px rgba(0,0,0,0.08);",
    // minHeight: "50px",
    "& .MuiInputBase-input": {
      height: "37px",
    },
  },
  avatar75px: {
    width: "75px",
    height: "75px",
  },
  avatar150px: {
    width: "150px",
    height: "150px",
    border: "4px solid #0042a8",
  },
  modalBody: {
    padding: theme.spacing(4),
    "& .PrivateNotchedOutline-root-281": {
      top: "0",
    },
  },
  modalBodyHeight: {
    maxHeight: "70vh",
    overflowY: "scroll",
  },
  centerCenter: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
  },
  administratorTableResponsiveMain: {
    height: "250px",
    overflow: "auto",
    overflowX: "auto",
  },
  administratorTableResponsive: {
    minWidth: "600px",
  },
  w_30: {
    width: "33.333%",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  profileHere: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // marginRight: '30px',
    minWidth: "16%",
    maxWidth: "85%",
    margin: " 0 auto",
  },
  selectGender: {
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      height: "37px",
    },
  },
  W2FormDoc: {
    width: "100%",
    height: "470px",
    background: `url(${W2FormImg})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    position: "relative",
    "& div.click-to-expand": {
      display: "none",
      // transition: "transform 2s",
      transitionDuration: "2s",
    },
    "&:hover div.click-to-expand": {
      display: "flex",
      background: "#1a364aa1",
    },
  },
});

export default AccountSettingTabs;
