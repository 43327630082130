import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  InputAdornment,
  List,
  ListItemText,
  MenuItem,
  Paper,
  Popper,
  TextField,
  Typography,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { PersonPinCircleOutlined } from "@material-ui/icons";
import { CustomButton } from "../CustomButton/Button";
import { connect } from "react-redux";
// import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import { color } from "@material-ui/system";
import _default from "../../config/default";
import man from "../../assets/img/man.png";
import user from "../../assets/img/user.png";
import { PersonalService } from "../../services/personal.service";

const AddCollaboratorDropdown = (props) => {
  console.log("Add Collaborator props", props);
  const personalService = new PersonalService();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [err, setErr] = React.useState([]);
  const [inputValue, setInputValue] = useState("");

  let date = new Date(); // Now
  date.setDate(date.getDate() + 30);

  // State for the data object (for API submission)
  const [apiData, setApiData] = useState({
    category_type: "Personal",
    subcategory_type: props?.data?.subcategory,
    user: {
      email: "",
    },
    access_type: "read-only",
    end_date: date,
    profile_id: props?.profile?.profileInfo?.profile?.id,
    subcategory_id: props?.data?.data?.id
  });

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prevOpen) => !prevOpen);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Handles input change and updates the state
  const handleInputChange = (event) => {
    const { value } = event.target;
    setInputValue(value);
    setApiData((prevData) => ({
      ...prevData,
      user: {
        ...prevData.user,
        email: value,
      },
    }));
  };

  // Handles key down event for submitting the data on pressing Enter
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      personalService
        .addUserPermission(apiData)
        .then((response) => {
          console.log("Response data:", response.data);
          setInputValue("");
          setApiData((prevData) => ({
            ...prevData,
            user: {
              ...prevData.user,
              email: "",
            },
          }));
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errors) {
            let errors = [];
            err.response.data.errors.forEach((error) => {
              errors.push(error);
            });
            setErr(errors);
            console.log("errrrrrrrrr", errors)
            setInputValue("");
            setApiData((prevData) => ({
              ...prevData,
              user: {
                ...prevData.user,
                email: "",
              },
            }));
          } else {
            setErr(["Internal Server Error"]);
          }
        });
    }
  };
  return (
    <Box
      sx={{
        width: { xs: 300, sm: 350 },
        borderRadius: "15px",
        backgroundColor: "#ffffff",
        overflow: "hidden",
        boxShadow: 3,
        height:350,
        display: "flex",
    flexDirection: "column",
      }}
    >
      {/* Header */}
      <div
        style={{
          background: "#0042a8",
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px 0",
          margin: "0",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            color: "white",
            textAlign: "center",
            font: "16px",
          }}
          style={{ fontSize: "0.8rem", fontWeight: "bold" }}
        >
          Add Collaborator
        </Typography>
      </div>

      {/* Input Field */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          color: "rgb(6, 13, 92)",
          padding: "10px 20px",
          gap: "10px",
        }}
      >
        <Typography
          color="rgb(6, 13, 92)"
          sx={{
            textAlign: "center",
            mb: 2,
            font: "14px",
          }}
          style={{ fontSize: "0.8rem" }}
        >
          Add by name or email address
        </Typography>

        <div style={{ position: "relative", width: "100%" }}>
          <input
            type="text"
            placeholder="Add by name or email"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            style={{
              width: "100%",
              padding: "8px 8px 8px 50px",
              border: "1px solid rgb(6, 13, 92)",
              borderRadius: "8px",
              fontSize: "0.8rem",
              color: "rgb(6, 13, 92)",
            }}
            className="custom-placeholder"
          />

          <PersonOutlineOutlinedIcon
            style={{
              width: "20px",
              position: "absolute",
              top: "-10px",
              left: "3px",
              pointerEvents: "none",
              color: "rgb(6, 13, 92)",
            }}
          />
        </div>
        {err?.length > 0 && (
          <div className="w-100 d-flex flex-row">
            {err?.map((val, key) => {
              return (
                <Typography
                  key={key}
                  variant="p"
                  component="p"
                  align="left"
                  style={{
                    color: "red",
                    fontWeight: "normal",
                    marginBottom: "-25px",
                  }}
                >
                  {val.charAt(0).toUpperCase() + val.slice(1)}
                </Typography>
              );
            })}
          </div>
        )}
      </div>

      {/* Collaborators List */}
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          color: "#6c757d",
          padding: "0 20px 5px 20px",
        }}
      >
        <Typography
          sx={{ mt: 2, mb: 1, color: "rgb(226, 226, 226)" }}
          style={{ color: "darkgray", fontSize: "0.8rem", marginTop: "20px" }}
        >
          {props?.data?.data?.collaborators?.collaborators?.length} project members
        </Typography>
      </div>
      <hr style={{ margin: "0" }} />

      <List style={{ overflowY: "auto",overflowX: "hidden",flex: 1, paddingBottom:"20px" }}>
        {props?.data?.data?.collaborators?.collaborators?.map((collaborator, index) => (
          <Grid
            item
            container
            wrap="wrap"
            direction="row"
            spacing={1}
            style={{
              padding: "0 20px",
            }}
          >
            <Grid
              item
              xs={2}
              md={2}
              style={{
                textAlign: "left",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              {/* <Avatar alt={collaborator.name} src={collaborator.profile_image}/> */}
              <>
                {collaborator?.profile_image ? (
                  <Avatar
                    src={_default.BASE_URL + collaborator?.profile_image}
                    data-tooltip="Owner"
                    data-tooltip-location="top"
                    width={35}
                    style={{
                      marginLeft: "-12px",
                      borderRadius: 50,
                      zIndex: "auto",
                    }}
                  />
                ) : (
                  <Avatar
                    src={collaborator?.gender == "female" ? user : man}
                    data-tooltip="Owner"
                    data-tooltip-location="top"
                    width={35}
                    style={{
                      marginLeft: "-12px",
                      borderRadius: 50,
                      zIndex: "auto",
                    }}
                  />
                )}
              </>
            </Grid>
            <Grid item xs={6} md={6} style={{ textAlign: "left" }}>
              <ListItemText
                primary={collaborator.name}
                secondary={collaborator.email}
                primaryTypographyProps={{
                  fontWeight: 600,
                  style: {
                    color: "rgb(6, 13, 92)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                  },
                }}
                secondaryTypographyProps={{
                  style: { color: "darkgray" },
                }}
              />
            </Grid>
            <Grid
              item
              xs={4}
              md={4}
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CustomButton
                btnText={collaborator.permission.replace("-", " ")}
                style={{
                  padding: "6px 6px",
                  fontSize: "0.6rem",
                  backgroundColor: "#0042a8",
                  borderRadius: "25px",
                  color: "#fff",
                  textAlign: "center",
                  display: "inline-block",
                  border: "0",
                  "&:hover": {
                    backgroundColor: "#0042a8",
                  },
                }}
              />
            </Grid>
            {/* <Grid
              item
              xs={1}
              md={1}
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MoreVertIcon
                onClick={handleMenuClick}
                sx={{ fontSize: "30px", color: "#ff0000", cursor: "pointer" }}
                style={{
                  cursor: "pointer",
                  position: "relative",
                  margin: "0",
                  color: "#0042a8",
                  width:"25px"
                }}
              />
              <Popper
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                style={{
                  position: "absolute",
                  top: "45px",
                  right: "0",
                  zIndex: 1,
                  width: "120px",
                }}
              >
                <Paper>
                  <MenuItem onClick={handleMenuClose}>Share Profile</MenuItem>
                </Paper>
              </Popper>
            </Grid> */}
          </Grid>
        ))}
      </List>
    </Box>
  );
};


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
    profile: state.profile,
  };
};
// export default AddCollaboratorDropdown;
export default connect(mapStateToProps, mapDispatchToProps)(AddCollaboratorDropdown);

const style = document.createElement("style");
style.innerHTML = `
  input::placeholder {
    color: darkgray;
    font-size: 0.75rem;
  }
`;
document.head.appendChild(style);
