import "date-fns";
import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import debounce from "lodash.debounce";
import {
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  useTheme,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import SureToAdd from "../Alerts/SureToAdd/SureToAdd.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { FinanceService } from "../../../services/finance.service.js";
import ChooseWebLogins from "../ChooseWebLogins/ChooseWebLogins.js";
import EditWebLogin from "../EditWebLogin/EditWebLogin.js";
import AddDocument from "../AddDocument/AddDocument.js";
import EditDocument from "../EditDocument/EditDocument.js";
import disableCheck from "../../../Utils/disableCheck.js";
import PasswordVerification from "../PasswordVerification/PasswordVerification.js";
import FileViewer from "../../../components/FileViewer/FileViewer.js";
import SpinnerModal from "../Alerts/SpinnerModal/SpinnerModal.js";
import AlertPopup from "../Alerts/Generic/GenericAlert.js";
import { DocumentService } from "../../../services/document.service.js";

const taxYear = [
  "2027",
  "2026",
  "2025",
  "2024",
  "2023",
  "2022",
  "2021",
  "2020",
  "2019",
  "2018",
  "2017",
  "2016",
  "2015",
  "2014",
  "2013",
  "2012",
  "2011",
  "2010",
  "2009",
  "2008",
  "2007",
  "2006",
  "2005",
  "2004",
  "2003",
  "2002",
  "2000",
  "1999",
  "1998",
  "1997",
  "1996",
  "1995",
  "1994",
  "1993",
  "1992",
  "1991",
  "1990",
  "1989",
  "1988",
  "1987",
  "1986",
  "1985",
  "1984",
  "1983",
  "1982",
  "1981",
  "1980",
];
const stateArray = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Federated States of Micronesia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Marshall Islands",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palau",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Island",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles(styles);

function EditTaxReturns(props) {
  const classes = useStyles();
  const errRef = useRef(null);
  const financeService = new FinanceService();
  const [err, setErr] = React.useState([]);
  const [link, setLink] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showDoc, setShowDoc] = React.useState(false);
  const [id, setId] = React.useState(null);
  const [spinner, setSpinner] = React.useState(false);
  const [alert, setAlert] = React.useState({
    open: false,
    type: null,
    btnText: null,
    cancelText: null,
    heading: null,
    message: null,
  });
  const [password, setPassword] = React.useState(null);
  const [showPasswordVerification, setShowPasswordVerification] =
    React.useState(false);
  const [documentModal, setDocumentModal] = React.useState(false);
  const [editDoc, setEditDoc] = React.useState(false);
  const [currentDocument, setCurrentDocument] = React.useState(null);
  const obj = props?.taxReturn?.associated_documents_attributes
    ? props?.taxReturn?.associated_documents_attributes
    : [];
  const [doc, setDoc] = React.useState(
    props?.taxReturn?.documents ? props?.taxReturn?.documents : []
  );
  const [chooseState, setChooseState] = useState(
    props?.taxReturn?.federal === true ? "federal" : "state"
  );
  const [chooseActual, setChooseActual] = useState(
    props?.taxReturn?.actual === true ? "actual" : "estimate"
  );
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [editWebLogin, setEditWebLogin] = React.useState(false);
  const [webLogin, setWebLogin] = React.useState(
    (props?.taxReturn?.web_logins && props?.taxReturn?.web_logins[0]) || null
  );
  const [webLoginModal, setWebLoginModal] = React.useState(false);
  const [data, setData] = React.useState({
    associated_documents_attributes: obj,
    federal: props?.taxReturn?.federal,
    actual: props?.taxReturn?.actual,
    quarter: props?.taxReturn?.quarter,
    tax_year: props?.taxReturn?.tax_year,
    state: props?.taxReturn?.state,
    year: props?.taxReturn?.year,
    amount: props?.taxReturn?.amount?.replaceAll("$", "")?.replaceAll(",", ""),
    notes: props?.taxReturn?.notes,
    associated_logins_attributes: [
      {
        web_login_id:
          props?.taxReturn?.web_logins && props?.taxReturn?.web_logins[0]?.id,
        id:
          props?.taxReturn?.associated_logins &&
          props?.taxReturn?.associated_logins[0]?.id,
        _destroy: 0,
      },
    ],
  });
  const [sureToAdd, setSureToAdd] = React.useState({ open: false, str: null });

  const handleOpenSureToAdd = (str) => {
    setSureToAdd({ open: true, str: str ?? null });
  };
  const handleCloseSureToAdd = () => {
    if (sureToAdd.str !== null) {
      props?.handleClose();
    }
    setSureToAdd({ open: false, str: null });
  };

  const handleSubmit = () => {
    handleCloseSureToAdd();
    if (
      !data.associated_logins_attributes[0].web_login_id &&
      data.associated_logins_attributes[0]._destroy === 0
    ) {
      delete data["associated_logins_attributes"];
    }
    const temp = data?.associated_documents_attributes?.filter(
      (e) => e.document_id !== null
    );
    if (props?.wellness?.associated_documents_attributes) {
      setData({ ...data, associated_documents_attributes: temp });
    } else {
      if (temp && temp.length > 0) {
        setData({ ...data, associated_documents_attributes: temp });
      } else {
        let temp2 = delete data["associated_documents_attributes"];
        setData(data);
      }
    }
    // const temp = data?.associated_documents_attributes  ?.filter(
    //   (e) => e.document_id !== null
    // );
    // if (props?.taxReturn?.associated_documents_attributes  ) {
    //   setData({ ...data, associated_documents_attributes: temp });
    // } else {
    //   if (temp && temp.length > 0){
    //     setData({ ...data, associated_documents_attributes: temp });
    //   } else {
    //     let temp2 = delete data["associated_documents_attributes"];
    //     setData(data);
    //   }
    // }
    setIsLoading(true);
    financeService
      .editTaxReturns(data, props?.taxReturn?.id)
      .then((response) => {
        setIsLoading(false);
        props.forceRerender();
        props.handleClose();

        console.log(response.data);
      })
      .catch((err) => {
        errRef.current.scrollIntoView();
        setIsLoading(false);
        let str = [];
        if (err.response) {
          Object.keys(err?.response?.data?.message).forEach((key) => {
            let value = err?.response?.data?.message[key];
            str.push(key?.replace("_", " ") + " " + value);
          });
          setErr(str);
        } else {
          setErr(["Error occurred while editing Tax Returns"]);
        }
      });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChange = debounce((event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  }, 300);

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChangeCountry = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const initialRef = useRef(null);
  const documentService = new DocumentService();

  const show = (docId) => {
    const documentId = docId ?? id;
    setSpinner(true);
    documentService
      .show(documentId, password)
      .then((res) => {
        setSpinner(false);
        setLink({
          attachment: res.data.document.attachment,
          contentType: res.data.document.content_type,
        });
        setShowDoc(true);
        setShowPasswordVerification(false);
        setPassword(null);
      })
      .catch((err) => {
        setSpinner(false);
        setPassword(null);
        setAlert({
          type: "Error",
          open: true,
          cancelText: "CANCEL",
          heading:
            err?.response && err?.response?.status === 422
              ? "Unauthorized!"
              : "Error!",
          message:
            err?.response && err?.response?.status === 422
              ? "Wrong password! please try again."
              : "An unknown error occurred, please try later.",
        });
      });
  };
  React.useEffect(() => {
    initialRef.current = data;
  }, []);
  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={() => {
          !disableCheck(initialRef, data)
            ? handleOpenSureToAdd("exit")
            : props?.handleClose();
        }}
        width="35%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        {alert.open && (
          <AlertPopup
            btnText={alert.btnText}
            cancelText={alert.cancelText}
            open={alert.open}
            type={alert.type}
            heading={alert.heading}
            message={alert.message}
            isLoading={false}
            handleClose={() => {
              setAlert({
                open: false,
                btnText: null,
                cancelText: null,
                heading: null,
                message: null,
                type: null,
              });
            }}
            handleClick={() => {
              setAlert({
                open: false,
                btnText: null,
                cancelText: null,
                heading: null,
                message: null,
                type: null,
              });
            }}
          />
        )}
        {spinner && <SpinnerModal open={spinner} msg={"Loading"} />}
        {showDoc && (
          <FileViewer
            contentType={link?.contentType}
            link={link?.attachment}
            open={showDoc}
            handleClose={() => setShowDoc(false)}
          />
        )}
        {showPasswordVerification && (
          <PasswordVerification
            open={showPasswordVerification}
            handleClose={() => {
              setShowPasswordVerification(false);
              setId(null);
            }}
            handleChange={(e) => setPassword(e)}
            handleSubmit={() => {
              show();
            }}
          />
        )}
        <ModalHeader
          closeModal={() => {
            !disableCheck(initialRef, data)
              ? handleOpenSureToAdd("exit")
              : props?.handleClose();
          }}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={`Edit ${data.amount || "Tax Return"}`}
          color={color.white}
        />
        <div className={classes.modalBody} ref={errRef}>
          <div className="w-100 d-flex flex-row mb-4">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              {"Finance > Tax Returns > Edit"}
            </Typography>
          </div>
          {err.length > 0 && (
            <div className="w-100 d-flex flex-row mb-4">
              {err.map((e) => {
                return (
                  <Typography
                    variant="p"
                    component="p"
                    align="left"
                    style={{ color: "red", fontWeight: "normal" }}
                  >
                    {e}
                  </Typography>
                );
              })}
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            {editDoc && (
              <EditDocument
                password_protected={currentDocument?.password_protected}
                docs={doc}
                category={currentDocument?.category}
                id={currentDocument.id}
                title={currentDocument.title}
                open={editDoc}
                setDocs={setDoc}
                handleClose={() => setEditDoc(false)}
              />
            )}
            {documentModal && (
              <AddDocument
                setData={setData}
                setDoc={setDoc}
                data={data}
                associated_documents_attributes={
                  props?.taxReturn?.associated_documents_attributes
                    ? true
                    : false
                }
                doc={doc}
                open={documentModal}
                handleClose={() => setDocumentModal(false)}
                closeModal={() => setDocumentModal(false)}
                category={"Personal"}
              />
            )}
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Federal or State *"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="federal"
                  name="federal"
                  value={chooseState}
                  onChange={(e) => {
                    setChooseState(e.target.value);
                    if (e.target.value === "federal") {
                      setData({ ...data, federal: true });
                    } else {
                      setData({ ...data, federal: false });
                    }
                  }}
                  className="flex-row"
                >
                  <FormControlLabel
                    value="federal"
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Federal"
                  />
                  <FormControlLabel
                    value="state"
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="State"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {chooseState === "state" && (
              <>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"State *"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <FormControl id="security-question" fullWidth={true}>
                    <Select
                      id="state"
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      value={data.state !== "false" && data.state}
                      required={true}
                      onChange={(e) => {
                        setData({
                          ...data,
                          state: e.target.value,
                          federal: false,
                        });
                      }}
                      name="license_state"
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      MenuProps={MenuProps}
                    >
                      <MenuItem disabled>Please Select</MenuItem>
                      {stateArray?.map((state, index) => {
                        return (
                          <MenuItem
                            value={state}
                            key={index}
                            style={getStyles(name, personName, theme)}
                          >
                            {state}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {chooseActual === "actual" ? "Tax year" : "Year"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  id="state"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  value={data?.tax_year || data?.year}
                  required={true}
                  onChange={(e) => {
                    if (chooseActual === "actual") {
                      setData({ ...data, tax_year: e.target.value });
                    } else {
                      setData({ ...data, year: e.target.value });
                    }
                  }}
                  name="tax_year"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  {taxYear?.map((state, index) => {
                    return (
                      <MenuItem
                        value={state}
                        key={index}
                        style={getStyles(name, personName, theme)}
                      >
                        {state}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Precision"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="actual"
                  name="actual"
                  value={chooseActual}
                  onChange={(e) => {
                    setChooseActual(e.target.value);
                    if (e.target.value === "estimate") {
                      setData({ ...data, actual: false });
                    } else {
                      setData({ ...data, actual: true });
                    }
                  }}
                  className="flex-row"
                >
                  <FormControlLabel
                    value="actual"
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Actual"
                  />
                  <FormControlLabel
                    value="estimate"
                    control={
                      <Radio style={{ color: "#ffb722", marginLeft: "7px" }} />
                    }
                    label="Estimate"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {chooseActual === "estimate" && (
              <>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Quarter"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <FormControl id="security-question" fullWidth={true}>
                    <Select
                      id="state"
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      value={data.quarter ? data?.quarter : "Please Select"}
                      required={true}
                      onChange={(e) => {
                        setData({
                          ...data,
                          quarter: e.target.value,
                        });
                      }}
                      name="license_state"
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value={"Please Select"} disabled>
                        Please Select
                      </MenuItem>
                      <MenuItem
                        value={1}
                        style={getStyles(name, personName, theme)}
                      >
                        {1}
                      </MenuItem>
                      <MenuItem
                        value={2}
                        style={getStyles(name, personName, theme)}
                      >
                        {2}
                      </MenuItem>
                      <MenuItem
                        value={3}
                        style={getStyles(name, personName, theme)}
                      >
                        {3}
                      </MenuItem>
                      <MenuItem
                        value={4}
                        style={getStyles(name, personName, theme)}
                      >
                        {4}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )} */}

            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Amount Due"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                id="class"
                name="amount"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                inputProps={{
                  maxlength: 8,
                  step: 1,
                }}
                margin="dense"
                value={data.amount?.replace("$", "")?.replace(",", "")}
                required={true}
                type="number"
                placeholder={"Amount"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Notes"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                id="class"
                name="notes"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                value={data.notes}
                placeholder={"Notes"}
              />
            </Grid>
            {webLoginModal && (
              <ChooseWebLogins
                setData={setData}
                setWebLogin={setWebLogin}
                data={data}
                open={webLoginModal}
                webLogin={data?.associated_logins_attributes[0]?.web_login_id}
                handleClose={() => setWebLoginModal(false)}
                closeModal={() => setWebLoginModal(false)}
                category={"Personal"}
              />
            )}
            {editWebLogin && (
              <EditWebLogin
                open={editWebLogin}
                handleClose={() => setEditWebLogin(false)}
                webLogin={webLogin}
              />
            )}
            {editWebLogin && (
              <EditWebLogin
                open={editWebLogin}
                handleClose={() => setEditWebLogin(false)}
                webLogin={webLogin}
              />
            )}
            {webLogin && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Web Login"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {webLogin?.user_name}
                  </Typography>
                  <CustomButton
                    btnText={"Remove"}
                    textAlign="center"
                    display={"block"}
                    background="transparent"
                    hoverBg="#0042a8"
                    border="1px solid #0042a8"
                    borderRadius="8px"
                    color="#0042a8"
                    onClick={() => {
                      setWebLogin(null);
                      setData({
                        ...data,
                        associated_logins_attributes: [
                          {
                            web_login_id:
                              data.associated_logins_attributes[0].web_login_id,
                            id: data.associated_logins_attributes[0].id,
                            _destroy: 1,
                          },
                        ],
                      });
                    }}
                  />
                  <CustomButton
                    btnText={"Edit"}
                    textAlign="center"
                    marginLeft="8px"
                    display={"block"}
                    background="transparent"
                    hoverBg="#0042a8"
                    border="1px solid #0042a8"
                    borderRadius="8px"
                    color="#0042a8"
                    onClick={() => {
                      setEditWebLogin(true);
                    }}
                  />
                </Grid>
              </>
            )}
            {doc.length > 0 && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Document"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                  {doc.map((e, k) => (
                    <>
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: color.themeColorNew }}
                        key={k}
                      >
                        {e?.title}
                      </Typography>
                      <CustomButton
                        btnText={"Remove"}
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          let temp = data.associated_documents_attributes?.map(
                            (d) => {
                              if (d.document_id === e.id) {
                                return {
                                  ...d,
                                  _destroy: 1,
                                };
                              } else {
                                return d;
                              }
                            }
                          );
                          let newDoc = doc?.filter((d) => d.id !== e.id);
                          setDoc(newDoc);
                          setData({
                            ...data,
                            associated_documents_attributes: temp,
                          });
                        }}
                      />

                      <CustomButton
                        btnText={"Edit"}
                        className="ms-2"
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          setCurrentDocument(e);
                          setEditDoc(true);
                        }}
                      />
                      <CustomButton
                        btnText={"View"}
                        className="ms-2"
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          if (!props?.taxReturn?.documents) {
                            show(e?.id);
                          } else {
                            if (e?.attachment) {
                              setLink({
                                attachment: _default.BASE_URL + e.attachment,
                                contentType: e.content_type,
                              });
                              setShowDoc(true);
                            } else {
                              setId(e?.id);
                              setShowPasswordVerification(true);
                            }
                          }
                        }}
                      />
                    </>
                  ))}
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              <CustomButton
                btnText="Add Document"
                className="mb-sm-2"
                textAlign="center"
                display={"block"}
                padding="8px 25px"
                background="#0042a8"
                hoverBg="#0042a8"
                border="0"
                borderRadius="8px"
                color="#fff"
                marginRight={"10px"}
                onClick={() => setDocumentModal(true)}
              />

              {!webLogin && (
                <CustomButton
                  className="mb-sm-2"
                  btnText="Add Web Login"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  marginRight={"10px"}
                  onClick={() => setWebLoginModal(true)}
                />
              )}

              {!isLoading ? (
                <CustomButton
                  type="submit"
                  btnText="Save"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  onClick={handleSubmit}
                  disabled={disableCheck(initialRef, data)}
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
      <SureToAdd
        open={sureToAdd.open}
        handleClose={handleCloseSureToAdd}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTaxReturns);
