import "date-fns";
import React, { useRef, useState } from "react";
import debounce from "lodash.debounce";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  MenuItem,
  Grid,
  TextField,
  Typography,
  Select,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import { dateFormat } from "../../../Utils/dateFormate.js";

import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import { EducationService } from "../../../services/education.service.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import AddDocument from "../AddDocument/AddDocument";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { getDateIgnoreTimezone } from "../../../Utils/dateHelper.js";
import { upperCaseErrorKeys } from "../../../Utils/stringManipulation.js";

const useStyles = makeStyles(styles);

function AddCertificate(props) {
  const classes = useStyles();
  const errRef = useRef(null);
  const educationService = new EducationService();
  const [err, setErr] = React.useState([]);
  const [doc, setDoc] = React.useState([]);
  const [reminder, setReminder] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [data, setData] = React.useState({
    certificate_name: "",
    organization: "",
    issue_date: null,
    expiration_date: null,
    type_name: "Certificate",
    notes: "",
    attachments: "",
    associated_documents_attributes: [],
    alert_attributes: {
      timing: props?.preference?.timing,
      frequency: props?.preference?.frequency,
      status: "inactive",
      profile_id: props?.profile?.profileInfo?.profile.id,
    },
  });
  const [documentModal, setDocumentModal] = React.useState(false);

  const handleDocumentModal = () => {
    setDocumentModal(true);
  };
  const timing = [
    { show: "1 week from expiration", value: "one_week" },
    { show: "2 weeks from expiration", value: "two_weeks" },
    { show: "1 month from expiration", value: "one_month" },
    { show: "3 months from expiration", value: "three_months" },
    { show: "6 months from expiration", value: "six_months" },
  ];

  const frequancy = [
    { show: "Daily", value: "daily" },
    { show: "Every other Day", value: "every_other_day" },
    { show: "Weekly", value: "weekly" },
    { show: "Biweekly", value: "bi_weekly" },
  ];
  const handleSubmit = () => {
    let formData = new FormData();
    formData.append("certificate_name", data.certificate_name);
    formData.append("type_name", data.type_name);
    formData.append("organization", data.organization);
    data.issue_date &&
      formData.append(
        "issue_date",
        getDateIgnoreTimezone(data.issue_date).toISOString().slice(0, 10)
      );
    data.expiration_date &&
      formData.append(
        "expiration_date",
        getDateIgnoreTimezone(data.expiration_date).toISOString().slice(0, 10)
      );
    if (data.alert_attributes.status === "active") {
      formData.append("alert_attributes[timing]", data.alert_attributes.timing);
      formData.append(
        "alert_attributes[frequency]",
        data.alert_attributes.frequency
      );
      formData.append("alert_attributes[status]", data.alert_attributes.status);
      formData.append(
        "alert_attributes[profile_id]",
        data.alert_attributes.profile_id
      );
    }
    formData.append("notes", data.notes);

    if (data.associated_documents_attributes?.length > 0) {
      for (let i = 0; i < data.associated_documents_attributes?.length; i++) {
        formData.append(
          "associated_documents_attributes[][document_id]",
          data.associated_documents_attributes[i]?.document_id ||
            data.associated_documents_attributes[i]?.id
        );
      }
    }
    setIsLoading(true);
    educationService
      .createCertificate(formData)
      .then((response) => {
        setIsLoading(false);
        props.forceRerender();
        props.handleClose();

        console.log(response.data);
      })
      .catch((err) => {
        errRef.current.scrollIntoView();
        setIsLoading(false);
        if (err.response && err.response.data.message) {
          if (err.response?.data?.message === "Please enter a valid date") {
            setErr([err.response?.data?.message]);
          } else {
            let array = [];
            Object.keys(err.response.data.message).forEach((key) => {
              let temp =
                key === "alert.alert" ? "alert" : upperCaseErrorKeys(key);
              array.push(`${temp} ${err.response.data.message[key]}`);
            });
            setErr(array);
          }
        } else {
          setErr(["Internal Server Error"]);
        }
      });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChange = debounce((event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  }, 300);

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          // rightIcon={
          //   <EditOutlined
          //     style={{
          //       color: color.white,
          //       marginLeft: "auto",
          //       fontSize: "36px",
          //       cursor: "pointer",
          //     }}
          //   />
          // }
          heading={"Add New Certificate"}
          color={color.white}
        />
        <div className={classes.modalBody} ref={errRef}>
          <div className="w-100 d-flex flex-row mb-4">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              {"Education > Certificate > New"}
            </Typography>
          </div>
          {err?.length > 0 && (
            <div className="mb-4">
              {err?.map((val, key) => {
                return (
                  <div className="w-100 d-flex flex-row mb-1">
                    <Typography
                      key={key}
                      variant="p"
                      component="p"
                      align="left"
                      style={{
                        color: "red",
                        fontWeight: "normal",
                        marginBottom: "2px",
                      }}
                    >
                      {val.charAt(0).toUpperCase() + val.slice(1)}
                    </Typography>
                  </div>
                );
              })}
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            {documentModal && (
              <AddDocument
                setData={setData}
                setDoc={setDoc}
                associated_documents_attributes={false}
                doc={doc}
                data={data}
                open={documentModal}
                handleClose={() => setDocumentModal(false)}
                closeModal={() => setDocumentModal(false)}
                category={"Education"}
              />
            )}
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Type"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={data.type_name}
                  onChange={(e) => {
                    if (e.target.value === "Certificate") {
                      setData({
                        ...data,
                        expiration_date: null,
                        type_name: e.target.value,
                      });
                    } else {
                      setData({
                        ...data,
                        type_name: e.target.value,
                      });
                    }
                  }}
                  className="flex-row"
                >
                  <FormControlLabel
                    value={"Certificate"}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Certificate"
                  />
                  <FormControlLabel
                    value={"License"}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="License"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {data.type_name === "License" ? "License*" : "Certificate*"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                id="certificate_name"
                name="certificate_name"
                onChange={handleChange}
                type="text"
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={
                  data.type_name === "License" ? "License" : "Certificate"
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Organization"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                id="organization"
                name="organization"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Organization"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Date Issued"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth={true}
                  margin="dense"
                  // placeholderColor={!data.issue_date ? "colorLightGrey" : ""}
                  required={true}
                  inputVariant="outlined"
                  format={dateFormat()}
                  id="start_date"
                  placeholder={dateFormat()?.toUpperCase()}
                  value={data?.issue_date}
                  onChange={(date) => {
                    setData({
                      ...data,
                      issue_date: date,
                    });
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            {data.type_name === "License" && (
              <>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Renewal Date"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth={true}
                      margin="dense"
                      required={true}
                      inputVariant="outlined"
                      format={dateFormat()}
                      id="start_date"
                      placeholder={dateFormat()?.toUpperCase()}
                      value={data?.expiration_date}
                      onChange={(date) => {
                        setData({
                          ...data,
                          expiration_date: date,
                        });
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {props?.preference?.is_active && (
                    <>
                      {!reminder && (
                        <CustomButton
                          btnText={"Add Reminder"}
                          textAlign="center"
                          display={"block"}
                          background="transparent"
                          hoverBg="#0042a8"
                          border="1px solid #0042a8"
                          borderRadius="8px"
                          color="#0042a8"
                          onClick={() => {
                            setReminder(true);
                            setData({
                              ...data,
                              alert_attributes: {
                                ...data.alert_attributes,
                                status: "active",
                              },
                            });
                          }}
                        />
                      )}
                    </>
                  )}
                  {reminder && (
                    <div className={"w-75 m-2 border m-5"}>
                      <Grid item xs={12} sm={4} md={4}>
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: color.themeColorNew }}
                        >
                          {"Start Date"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={8} sm={8}>
                        <FormControl id="security-question" fullWidth={true}>
                          <Select
                            id="id_type"
                            variant="outlined"
                            fullWidth={true}
                            margin="dense"
                            required={true}
                            defaultValue={props?.preference?.timing}
                            onChange={(e) => {
                              setData({
                                ...data,
                                alert_attributes: {
                                  ...data.alert_attributes,
                                  timing: e.target.value,
                                },
                              });
                            }}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem disabled>Select Timing</MenuItem>
                            {timing.map((t) => {
                              return (
                                <MenuItem value={t.value}>{t.show}</MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: color.themeColorNew }}
                        >
                          {"Frequency"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={8} sm={8}>
                        <FormControl id="security-question" fullWidth={true}>
                          <Select
                            id="id_type"
                            variant="outlined"
                            fullWidth={true}
                            margin="dense"
                            required={true}
                            defaultValue={props?.preference?.frequency}
                            onChange={(e) => {
                              setData({
                                ...data,
                                alert_attributes: {
                                  ...data.alert_attributes,
                                  frequency: e.target.value,
                                },
                              });
                            }}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem disabled>Select Frequency</MenuItem>
                            {frequancy.map((f) => {
                              return (
                                <MenuItem value={f.value}>{f.show}</MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <CustomButton
                          textAlign="center"
                          display={"block"}
                          padding="8px 25px"
                          margin={"5px"}
                          background="#0042a8"
                          hoverBg="#0042a8"
                          border="0"
                          borderRadius="8px"
                          color="#fff"
                          btnText={"Remove"}
                          onClick={() => {
                            setData({
                              ...data,
                              alert_attributes: {
                                ...data.alert_attributes,
                                status: "inactive",
                              },
                            });
                            setReminder(false);
                          }}
                        />
                      </Grid>
                    </div>
                  )}
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Notes"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                name="notes"
                fullWidth={true}
                onChange={handleChange}
                id="outlined-multiline-static"
                label=""
                multiline
                rows={3}
                placeholder={"Notes"}
                variant="outlined"
              />
            </Grid>
            {doc.length > 0 && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Document"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                  {doc.map((e, k) => (
                    <>
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: color.themeColorNew }}
                        key={k}
                      >
                        {e?.title}
                      </Typography>
                      <CustomButton
                        btnText={"Remove"}
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          let newDoc = doc?.filter((d) => d.id !== e.id);
                          setDoc(newDoc);
                          setData({
                            ...data,
                            associated_documents_attributes: newDoc,
                          });
                        }}
                      />
                    </>
                  ))}
                </Grid>
              </>
            )}

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              <CustomButton
                btnText="Add Document"
                className="mb-sm-2"
                textAlign="center"
                display={"block"}
                padding="8px 25px"
                background="#0042a8"
                hoverBg="#0042a8"
                border="0"
                borderRadius="8px"
                color="#fff"
                marginRight={"10px"}
                onClick={handleDocumentModal}
              />

              {!isLoading ? (
                <CustomButton
                  type="submit"
                  btnText="Save"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  onClick={handleSubmit}
                  disabled={
                    data.certificate_name === "" ||
                    !data.certificate_name?.trim()
                      ? true
                      : false
                  }
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
    profile: state.profile,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCertificate);
