import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close, EditOutlined, MoreVert } from "@material-ui/icons";
import { Avatar } from "@material-ui/core";
// import AvatarGroup from '@mui/material/AvatarGroup';
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import jpegImage from "../../../assets/img/jpegImage.png";
import pdfImage from "../../../assets/img/pdfImage.png";
import GridContainer from "../../../components/Grid/GridContainer.js";
import { EducationService } from "../../../services/education.service.js";
import { dateFormat } from "../../../Utils/dateFormate.js";
import man from "../../../assets/img/man.png";
import female from "../../../assets/img/user.png";

import moment from "moment";
import _default from "../../../config/default.js";

const useStyles = makeStyles(styles);
export default function CertificateInformation(props) {
  const classes = useStyles();
  const educationService = new EducationService();

  useEffect(() => {
    educationService
      .certificateShow(props?.certificate[0]?.id)
      .then(() => {})
      .catch(() => {});
  }, []);

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%"
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          rightIcon={
            <EditOutlined
              onClick={() => props?.handleOpenEdit(props?.certificate[0])}
              style={{
                color: color.white,
                marginLeft: "auto",
                fontSize: "36px",
                cursor: "pointer",
              }}
            />
          }
          heading={
            props?.certificate[0]?.certificate_namee
              ? props?.certificate[0]?.certificate_namee
              : "Certificate"
          }
          color={color.white}
        />
        <div className={classes.modalBody}>
          <div className="w-100 d-flex flex-row mb-5">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              {"Education > Certificate > Information"}
            </Typography>
          </div>
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {props?.certificate[0]?.type_name === "License"
                  ? "License"
                  : "Certificate"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.certificate[0]?.certificate_name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Organization"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.certificate[0]?.organization ? (
                  props?.certificate[0]?.organization
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Date Issued"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.certificate[0]?.issue_date ? (
                  moment(
                    props?.certificate[0]?.issue_date?.replaceAll("-", "/")
                  ).format(dateFormat()?.toUpperCase())
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            {props?.certificate[0]?.type_name === "License" && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Renewal Date"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew, fontSize: "18px" }}
                  >
                    {props?.certificate[0]?.expiration_date ? (
                      moment(
                        props?.certificate[0]?.expiration_date?.replaceAll(
                          "-",
                          "/"
                        )
                      ).format(dateFormat()?.toUpperCase())
                    ) : (
                      <small>-</small>
                    )}
                  </Typography>
                </Grid>
              </>
            )}

            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Notes"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.certificate[0]?.notes ? (
                  props?.certificate[0]?.notes
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            wrap="wrap"
            direction="row"
            spacing={3}
            className="mt-5"
          >
            <Grid item xs={12} sm={6} md={6}>
              {props?.certificate[0]?.documents && (
                <>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Linked Items"}
                  </Typography>
                  <div className="d-flex flex-row align-items-center mt-2">
                    <img src={jpegImage} height={40} className="me-2" />
                    <img src={pdfImage} height={40} className="me-2" />
                    <Typography
                      onClick={props.handleOpenLinkedItems}
                      variant="h6"
                      component="h3"
                      align="right"
                      className="text-decoration-underline"
                      style={{
                        color: color.themeColorNew,
                        cursor: "pointer",
                      }}
                    >
                      {"See all"}
                    </Typography>
                  </div>
                </>
              )}
            </Grid>
            {!props?.certificate[0]?.collaborated_record && (
  <Grid item xs={12} sm={6} md={6}>
    <Typography
      variant="h6"
      component="h3"
      align="right"
      style={{ color: color.themeColorNew }}
    >
      {"Collaborators"}
    </Typography>
    <div className="d-flex flex-row align-items-center justify-content-end mt-2">
      <Avatar
        src={
          props?.certificate[0]?.collaborators
            ?.collaborators[0]?.profile_image
            ? _default.BASE_URL +
              props?.certificate[0]?.collaborators
                ?.collaborators[0]?.profile_image
            : props?.certificate[0]?.collaborators
                ?.collaborators[0]?.gender === "male"
            ? man
            : female
        }
        alt={"C"}
        width={35}
        style={{
          marginLeft: "",
          borderRadius: 50,
          zIndex: "auto",
        }}
      />
      {/* {props?.data?.collaborated_records_count > 1 && ( */}
      {props?.certificate[0]?.collaborators &&
        props?.certificate[0]?.collaborators
          ?.collaborators_count > 1 && (
        <Typography
          variant="p"
          component="p"
          align="left"
          style={{
            color: "#000",
            fontWeight: "bold",
            margin: "0 5px",
          }}
        >
          {`+ ${
            props?.certificate[0]?.collaborators
              ?.collaborators_count - 1
          }`}
        </Typography>
      )}
      {/* )} */}
      <Typography
        onClick={props.handleOpenCollaborators}
        variant="h6"
        component="h3"
        align="right"
        className="text-decoration-underline"
        style={{ color: color.themeColorNew, cursor: "pointer" }}
      >
        {"See all"}
      </Typography>
    </div>
  </Grid>
)}

          </Grid>
        </div>
      </CustomModal>
    </>
  );
}
