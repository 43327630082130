import "date-fns";
import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import { PersonalService } from "../../../services/personal.service.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import AddDocument from "../AddDocument/AddDocument";

const stateArray = ["Personal", "Work"];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles(styles);

function AddEmailAccount(props) {
  const classes = useStyles();
  const errRef = useRef(null);
  const personalService = new PersonalService();
  const [err, setErr] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [doc, setDoc] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [data, setData] = React.useState({
    email: "",
    email_type: "",
    description: "",
    password: "",
    attachments: "",
    associated_documents_attributes: [],
  });
  const [documentModal, setDocumentModal] = React.useState(false);

  const handleDocumentModal = () => {
    setDocumentModal(true);
  };

  const handleSubmit = () => {
    let formData = new FormData();
    formData.append("email", data.email);
    formData.append("email_type", data.email_type);
    formData.append("description", data.description);
    formData.append("password", data.password);

    if (data.associated_documents_attributes?.length > 0) {
      for (let i = 0; i < data.associated_documents_attributes?.length; i++) {
        formData.append(
          "associated_documents_attributes[][document_id]",
          data.associated_documents_attributes[i]?.document_id ||
            data.associated_documents_attributes[i]?.id
        );
      }
    }
    setIsLoading(true);
    personalService
      .createEmailAccount(formData)
      .then((response) => {
        setIsLoading(false);
        props.forceRerender();
        props.handleClose();
        console.log(response.data);
      })
      .catch((err) => {
        errRef.current.scrollIntoView();
        setIsLoading(false);
        if (err.response && err.response.data.message) {
          if (err.response?.data?.message === "Please enter a valid date") {
            setErr([err.response?.data?.message]);
          } else {
            let array = [];
            Object.keys(err.response.data.message).forEach((key) =>
              array.push(`${key} ${err.response.data.message[key]}`)
            );
            setErr(array);
          }
        } else {
          setErr(["Internal Server Error"]);
        }
      });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChangeCountry = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Add New Email Account"}
          color={color.white}
        />
        <div className={classes.modalBody} ref={errRef}>
          <div className="w-100 d-flex flex-row mb-4">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              {"Personal > Email Account > New"}
            </Typography>
          </div>
          {err?.length > 0 && (
            <div className="w-100 d-flex flex-row mb-4">
              {err?.map((val, key) => {
                return (
                  <Typography
                    key={key}
                    variant="p"
                    component="p"
                    align="left"
                    style={{
                      color: "red",
                      fontWeight: "normal",
                      marginBottom: "2px",
                    }}
                  >
                    {val.charAt(0).toUpperCase() + val.slice(1)}
                  </Typography>
                );
              })}
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Type *"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  id="type"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  onChange={handleChange}
                  name="email_type"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  <MenuItem disabled>Select Type</MenuItem>
                  {stateArray?.map((state, index) => {
                    return (
                      <MenuItem
                        value={state}
                        key={index}
                        style={getStyles(name, personName, theme)}
                      >
                        {state}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Email Address *"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                id="class"
                name="email"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Email Address"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Password"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                // id="class"
                name="password"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                type="text"
                id="txtPassword"
                required={true}
                placeholder={"Password"}
              />
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Description"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                id="id_number"
                name="description"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Description"}
              />
            </Grid>
            {doc.length > 0 && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Document"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                  {doc.map((e, k) => (
                    <>
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: color.themeColorNew }}
                        key={k}
                      >
                        {e?.title}
                      </Typography>
                      <CustomButton
                        btnText={"Remove"}
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          let newDoc = doc?.filter((d) => d.id !== e.id);
                          setDoc(newDoc);
                          setData({
                            ...data,
                            associated_documents_attributes: newDoc,
                          });
                        }}
                      />
                    </>
                  ))}
                </Grid>
              </>
            )}
              {documentModal && (
                <AddDocument
                  setData={setData}
                  setDoc={setDoc}
                  associated_documents_attributes={false}
                  doc={doc}
                  data={data}
                  open={documentModal}
                  handleClose={() => setDocumentModal(false)}
                  closeModal={() => setDocumentModal(false)}
                  category={"Personal"}
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              <CustomButton
                btnText="Add Document"
                className="mb-sm-2"
                textAlign="center"
                display={"block"}
                padding="8px 25px"
                background="#0042a8"
                hoverBg="#0042a8"
                border="0"
                borderRadius="8px"
                color="#fff"
                marginRight={"10px"}
                onClick={handleDocumentModal}
              />

              {!isLoading ? (
                <CustomButton
                  type="submit"
                  btnText="Save"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  onClick={handleSubmit}
                  disabled={
                    data.email_type === "" || data?.email === "" ? true : false
                  }
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEmailAccount);
