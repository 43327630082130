import React from 'react'
import { CustomButton } from '../../components/CustomButton/Button';

function ShareProfile() {
  return (
    <>
      <div className="position-relative my-5">
        <figure className="org-chart cf">
          <div className="board">
            <ul className="columnOne">
              <li>
                <span className="lvl-b p-3 main-box">
                  <div className="profile-image-main" />
                  <h5 className="m-0" style={{ margin: 0 }}>
                    Roger Garfeild
                  </h5>
                </span>
              </li>
            </ul>
          </div>
          <ul className="departments">
            <li className="department">
              <span className="lvl-b p-3 main-box">
                <h6 className="m-0" style={{ fontSize: 14 }}>
                  Employment
                </h6>
              </span>
              <ul className="sections">
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>


                  <ul className="departments">
            <li className="department">
              <span className="lvl-b p-3 main-box">
                <h6 className="m-0" style={{ fontSize: 14 }}>
                  Employment
                </h6>
              </span>
              <ul className="sections">
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
                </ul>
                </li>
                </ul>
                </li>
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
              </ul>
            </li>
            <li className="department">
              <span className="lvl-b p-3 main-box">
                <h6 className="m-0" style={{ fontSize: 14 }}>
                  Employment
                </h6>
              </span>
              <ul className="sections">
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
              </ul>
            </li>
            <li className="department">
              <span className="lvl-b p-3 main-box">
                <h6 className="m-0" style={{ fontSize: 14 }}>
                  Employment
                </h6>
              </span>
              <ul className="sections">
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
              </ul>
            </li>
            <li className="department central">
              <span className="lvl-b p-3 main-box">
                <h6 className="m-0" style={{ fontSize: 14 }}>
                  Employment
                </h6>
              </span>
              <ul className="sections">
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
              </ul>
            </li>
            <li className="department">
              <span className="lvl-b p-3 main-box">
                <h6 className="m-0" style={{ fontSize: 14 }}>
                  Employment
                </h6>
              </span>
              <ul className="sections">
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
              </ul>
            </li>
            <li className="department">
              <span className="lvl-b p-3 main-box">
                <h6 className="m-0" style={{ fontSize: 14 }}>
                  Employment
                </h6>
              </span>
              <ul className="sections">
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
              </ul>
            </li>
            <li className="department">
              <span className="lvl-b p-3 main-box">
                <h6 className="m-0" style={{ fontSize: 14 }}>
                  Employment
                </h6>
              </span>
              <ul className="sections">
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
                <li className="section">
                  <span className="main-box">
                    <div className="d-flex flex-row justify-content-center p-1 border-bottom">
                      <img src="image.png" style={{}} />
                      <p className="m-0 ms-2">Jerry Inc</p>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center p-2">
                      <CustomButton
                        btnText={"Read Only"}
                        padding="6px"
                        minWidth="113px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="25px"
                      />
                      <div className='ms-3'>icon</div>
                    </div>
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </figure>
      </div>
    </>
  );
}

export default ShareProfile