import "date-fns";
import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import _default from "../../../config/default.js";

import { Close } from "@material-ui/icons";
import TimerOutlinedIcon from "@material-ui/icons/TimerOutlined";
import color from "../../../assets/css/color.js";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction.js";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import { PersonalService } from "../../../services/personal.service.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import AddDocument from "../AddDocument/AddDocument.js";
import { getDateIgnoreTimezone } from "../../../Utils/dateHelper.js";
import { upperCaseErrorKeys } from "../../../Utils/stringManipulation.js";
import userProfileImg from "../../../../src/assets/img/account-settting.png";
import userImg from "../../../assets/img/user.png";
import man from "../../../assets/img/man.png";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { dateFormat } from "../../../Utils/dateFormate.js";

import { format } from "date-fns";

const useStyles = makeStyles(styles);

function EditPermissions(props) {
  const classes = useStyles();
  const personalService = new PersonalService();
  const [err, setErr] = React.useState([]);
  const [doc, setDoc] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const errRef = useRef(null);
  const [reminder, setReminder] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [data, setData] = React.useState({});
  const [other, setOther] = React.useState(false);
  const [documentModal, setDocumentModal] = useState(false);
  const [saveAppear, setSaveAppear] = useState(false);


  const handleDateChange = (date, collabId) => {
    if (date) {
      const formattedDate = format(date, "yyyy-MM-dd"); // Convert to "YYYY-MM-DD"

      // Update state with new date & updated collaborations
      setData((prevData) => ({
        ...prevData,
        date_awarded: formattedDate,
        collabId: collabId,
      }));
      setSaveAppear(true);
    }
  };

  const handleSubmitDate = async (data) => {
    try {
      await personalService.collaborationEditDate(
        data.collabId,
        data.date_awarded
      );
      props.closeModal()
      setSaveAppear(false);
    } catch (error) {
      console.error("Error Edit collaborator status:", error);
  
      if (error.response && error.response.data && error.response.data.error) {
        setErr([error.response.data.error]); 
      } else {
        setErr([error.message]);  
      }
    }
  };
  const handleDocumentModal = () => {
    setDocumentModal(true);
  };

  const timing = [
    { show: "1 week from expiration", value: "one_week" },
    { show: "2 weeks from expiration", value: "two_weeks" },
    { show: "1 month from expiration", value: "one_month" },
    { show: "3 months from expiration", value: "three_months" },
    { show: "6 months from expiration", value: "six_months" },
  ];

  const frequancy = [
    { show: "Daily", value: "daily" },
    { show: "Every other Day", value: "every_other_day" },
    { show: "Weekly", value: "weekly" },
    { show: "Biweekly", value: "bi_weekly" },
  ];

  const handleSubmit = () => {
    let formData = new FormData();
    formData.append("id_type", data.id_type);
    formData.append("identification_number", data.identification_number);
    data.expiration_date &&
      formData.append(
        "expiration_date",
        getDateIgnoreTimezone(data.expiration_date).toISOString().slice(0, 10)
      );
    if (data.alert_attributes.status === "active" && data.expiration_date) {
      formData.append("alert_attributes[timing]", data.alert_attributes.timing);
      formData.append(
        "alert_attributes[frequency]",
        data.alert_attributes.frequency
      );
      formData.append("alert_attributes[status]", data.alert_attributes.status);
      formData.append(
        "alert_attributes[profile_id]",
        data.alert_attributes.profile_id
      );
    }
    if (data.associated_documents_attributes?.length > 0) {
      for (let i = 0; i < data.associated_documents_attributes?.length; i++) {
        formData.append(
          "associated_documents_attributes[][document_id]",
          data.associated_documents_attributes[i]?.document_id ||
            data.associated_documents_attributes[i]?.id
        );
      }
    }

    setIsLoading(true);
    personalService
      .createAlternateId(formData)
      .then((response) => {
        setIsLoading(false);
        props.forceRerender();
        props.handleClose();
        console.log(response.data);
      })
      .catch((err) => {
        errRef.current.scrollIntoView();
        setIsLoading(false);
        if (err.response && err.response.data.message) {
          if (err.response?.data?.message === "Please enter a valid date") {
            setErr([err.response?.data?.message]);
          } else {
            let array = [];
            Object.keys(err.response.data.message).forEach((key) => {
              let temp =
                key === "alert.alert" ? "alert" : upperCaseErrorKeys(key);
              array.push(`${temp} ${err.response.data.message[key]}`);
            });
            setErr(array);
          }
        } else {
          setErr(["Internal Server Error"]);
        }
      });
  };

  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };

  const handleChange = (event) => {
    if (event.target.name === "id_type1") {
      if (event.target.value !== "Other") {
        setOther(false);
        setData({ ...data, id_type: event.target.value });
      } else {
        setOther(true);
      }
    } else {
      setData({ ...data, [event.target.name]: event.target.value });
    }
  };

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Edit Permission"}
          color={color.white}
        />
        <div className={classes.modalBody}>
          {/* <div className="w-100 d-flex flex-row mb-4" id={"err"} ref={errRef}>
              <Typography
                variant="p"
                component="p"
                align="left"
                style={{ color: "#060d5c", fontWeight: "normal" }}
              >
                {"Permission > Individual > Edit Access"}
              </Typography>
            </div> */}
          {err?.length > 0 && (
            <div className="w-100 d-flex flex-row mb-4">
              {err?.map((val, key) => {
                return (
                  <Typography
                    key={key}
                    variant="p"
                    component="p"
                    align="left"
                    style={{
                      color: "red",
                      fontWeight: "normal",
                      marginBottom: "2px",
                    }}
                  >
                    {val.charAt(0).toUpperCase() + val.slice(1)}
                  </Typography>
                );
              })}
            </div>
          )}
          <Grid container wrap="wrap" direction="row" spacing={3}>
            <Grid
              item
              xs={12}
              md={12}
              className="d-flex flex-wrap flex-row align-items-center justify-content-start"
            >
              <img
                // src={userProfileImg}
                src={
                  props.user?.profile_picture_of_user
                    ? _default.BASE_URL + props.user.profile_picture_of_user
                    : props.user?.gender === "male"
                    ? man
                    : userImg
                }
                alt={"User Profile"}
                height={65}
                width={65}
                className="rounded-pill me-3"
                style={{ objectFit: "cover" }}
              />
              <Typography
                variant="p"
                component="p"
                className={
                  classes.FontSize14 + " " + "me-5 mb-2 mb-md-0 mb-lg-0"
                }
                style={{ color: "#060d5c", fontWeight: "bold" }}
              >
                {props.user.name}
              </Typography>
              {/* <CustomButton
                btnText={"View Share Profile"}
                padding="12px 20px"
                minWidth="113px"
                height="50px"
                background="#fff"
                hoverBg="#0042a8"
                color="#0042a8"
                border="2px solid #0042a8"
                borderRadius="8px"
                onClick={() => handleOpenAddNew()}
              /> */}
            </Grid>
            {/* document Start */}
            <Grid
              item
              container
              wrap="wrap"
              direction="row"
              spacing={1}
              style={{
                overflow: "auto",
                overflowX: "auto",
              }}
            >
              <Grid
                container
                className={`${classes.marginBottom18px} ${classes.administratorTableResponsive}`}
              >
                <Grid item xs={4} md={4}>
                  <Typography
                    variant="p"
                    component="p"
                    className={`${classes.FontSize14} ${classes.marginBottom14px}`}
                    style={{ fontWeight: "bold" }}
                  >
                    {"Document"}
                  </Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Typography
                    variant="p"
                    component="p"
                    className={`${classes.FontSize14} ${classes.marginBottom14px}`}
                    style={{ fontWeight: "bold" }}
                  >
                    {"Last Viewed"}
                  </Typography>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Typography
                    variant="p"
                    component="p"
                    className={`${classes.FontSize14} ${classes.marginBottom14px}`}
                    style={{ fontWeight: "bold" }}
                  >
                    {"Access Type"}
                  </Typography>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Typography
                    variant="p"
                    component="p"
                    className={`${classes.FontSize14} ${classes.marginBottom14px}`}
                    style={{ fontWeight: "bold" }}
                  >
                    {"Access Until"}
                  </Typography>
                </Grid>
              </Grid>
              {props.user?.collaborations?.map((collab) => {
                return (
                  <Grid
                    container
                    className={`${classes.marginBottom18px} ${
                      classes.administratorTableResponsive
                    } ${" align-items-center"}`}
                  >
                    <Grid item xs={4} md={4}>
                      <Typography
                        variant="p"
                        component="p"
                        className={`${classes.FontSize14} ${classes.marginBottom14px}`}
                        style={{
                          fontWeight: "normal",
                          color: color.themeYellow,
                          margin: 0,
                        }}
                      >
                        {collab.subcategory_type}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <Typography
                        variant="p"
                        component="p"
                        className={`${classes.FontSize14} ${classes.marginBottom14px}`}
                        style={{ fontWeight: "normal", margin: 0 }}
                      >
                        {/* {"July 2, 2017"} */}
                        {props.user?.last_active}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <CustomButton
                        btnText={collab.access_type.replace("-", " ")}
                        textAlign="center"
                        display={"block"}
                        padding="5px 20px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        border="0"
                        borderRadius="25px"
                        color="#fff"
                        marginLeft="0!important"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      md={3}
                      className="d-flex flex-row justify-content-between align-items-center"
                    >
                      <Typography
                        variant="p"
                        component="p"
                        className={`${classes.FontSize14} ${classes.marginBottom14px}`}
                        style={{
                          fontWeight: "normal",
                          fontStyle: "italic",
                          margin: 0,
                        }}
                      >
                        {collab.end_date ? collab.end_date : "Unlimited"}
                      </Typography>
                      {/* <TimerOutlinedIcon
                        style={{
                          color: color.themeYellow,
                          marginRight: "20px",
                          fontSize: "20px",
                          cursor: "pointer",
                        }}
                      /> */}
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          className={classes.calanderIcon}
                          keyboardIcon={
                            <TimerOutlinedIcon
                              style={{
                                color: color.themeYellow,
                                marginRight: "0",
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                            />
                          }
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          format="yyyy-MM-dd"
                          id="start_date"
                          placeholder="yyyy-MM-dd"
                          value={data?.date_awarded}
                          onChange={(date) => handleDateChange(date, collab.id)}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                );
              })}

              <Grid
                container
                className={`${classes.marginBottom18px} ${
                  classes.administratorTableResponsive
                } ${" align-items-center"}`}
              >
                {/* <Grid item xs={12} md={12}>
                <Typography
                  variant="p"
                  component="p"
                  className={`${classes.FontSize14} ${classes.marginBottom14px}`}
                  style={{
                    fontWeight: "normal",
                    margin: 0,
                    color: color.themeYellow,
                  }}
                >
                  {"Add New"}
                </Typography>
              </Grid> */}
              </Grid>
              {/* <Grid
              container
              className={`${classes.marginBottom18px} ${
                classes.administratorTableResponsive
              } ${" align-items-center"}`}
            >
              <Grid item xs={6} md={6}>
                <Typography
                  variant="p"
                  component="p"
                  className={`${classes.FontSize14} ${classes.marginBottom14px}`}
                  style={{
                    fontWeight: "bold",
                    margin: 0,
                  }}
                >
                  {"Downloads"}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography
                  variant="p"
                  component="p"
                  className={`${classes.FontSize14} ${classes.marginBottom14px}`}
                  style={{
                    fontWeight: "bold",
                    margin: 0,
                  }}
                >
                  {"Document"}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              className={`${classes.marginBottom18px} ${
                classes.administratorTableResponsive
              } ${" align-items-center"}`}
            >
              <Grid item xs={6} md={6}>
                <Typography
                  variant="p"
                  component="p"
                  className={`${classes.FontSize14} ${classes.marginBottom14px}`}
                  style={{
                    fontWeight: "normal",
                    margin: 0,
                    color: color.themeYellow,
                  }}
                >
                  {"Downloads.jpeg"}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography
                  variant="p"
                  component="p"
                  className={`${classes.FontSize14} ${classes.marginBottom14px}`}
                  style={{
                    fontWeight: "normal",
                    margin: 0,
                    color: color.themeYellow,
                  }}
                >
                  {"Document 2016"}
                </Typography>
              </Grid>
            </Grid> */}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{
                textAlign: "right",
                paddingTop: "20px",
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
              }}
            >
              {/*  */}
              {/* {!isLoading ? (
                <CustomButton
                  type="submit"
                  btnText="Save"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  // className="mb-sm-2"
                  marginRight={"10px"}
                  color="#fff"
                  // disabled={
                  //   data.id_type === "" || !data.id_type?.trim() ? true : false
                  // }
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  // className="mb-sm-2"
                  marginRight={"10px"}
                  color="#fff"
                />
              )} */}

              <CustomButton
                type="submit"
                btnText="Save"
                textAlign="center"
                display="block"
                padding="8px 25px"
                background="#0042a8"
                hoverBg="#0042a8"
                border="0"
                borderRadius="8px"
                marginRight="10px"
                color="#fff"
                onClick={saveAppear ? () => handleSubmitDate(data) : undefined}
                disabled={!saveAppear}
              />

              <CustomButton
                btnText="Cancel"
                onClick={() => props.closeModal()}
                textAlign="center"
                display={"block"}
                padding="8px 25px"
                background="#0042a8"
                hoverBg="#0042a8"
                border="0"
                borderRadius="8px"
                color="#fff"
              />
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
    profile: state.profile,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPermissions);
