import "date-fns";
import debounce from "lodash.debounce";
import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import { dateFormat } from "../../../Utils/dateFormate.js";

import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import { PersonalService } from "../../../services/personal.service.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import SureToAdd from "../Alerts/SureToAdd/SureToAdd.js";
import { store } from "../../../store/store";
import RevealPassword from "../RevealPassword/RevealPassword";
import AddDocument from "../AddDocument/AddDocument.js";
import EditDocument from "../EditDocument/EditDocument.js";
import DateFnsUtils from "@date-io/date-fns";
import disableCheck from "../../../Utils/disableCheck.js";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { getDateIgnoreTimezone } from "../../../Utils/dateHelper.js";
import PasswordVerification from "../PasswordVerification/PasswordVerification.js";
import FileViewer from "../../../components/FileViewer/FileViewer.js";
import SpinnerModal from "../Alerts/SpinnerModal/SpinnerModal.js";
import AlertPopup from "../Alerts/Generic/GenericAlert.js";
import { DocumentService } from "../../../services/document.service.js";
import { setProfile } from "../../../store/actions/profileAction.js";
import _default from "../../../config/default.js";

const useStyles = makeStyles(styles);

const relationshipStatus = [
  "Civil Union",
  "Divorced",
  "Domestic Partnership",
  "Married",
  "Single",
  "Undisclosed",
  "Widowed",
];

function EditPersonalInformation(props) {
  const classes = useStyles();
  const errRef = useRef(null);
  const personalService = new PersonalService();
  const [err, setErr] = React.useState(null);
  const [link, setLink] = React.useState(null);
  const [showDoc, setShowDoc] = React.useState(false);
  const [id, setId] = React.useState(null);
  const [spinner, setSpinner] = React.useState(false);
  const ss = useRef(null);
  const [alert, setAlert] = React.useState({
    open: false,
    type: null,
    btnText: null,
    cancelText: null,
    heading: null,
    message: null,
  });
  const [password, setPassword] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showPasswordVerification, setShowPasswordVerification] =
    React.useState(false);
  const [documentModal, setDocumentModal] = React.useState(false);
  const [editDoc, setEditDoc] = React.useState(false);
  const [currentDocument, setCurrentDocument] = React.useState(null);
  const obj = props?.personalInformation?.associated_documents_attributes
    ? props?.personalInformation?.associated_documents_attributes
    : [];
  const [doc, setDoc] = React.useState(
    props?.personalInformation?.documents
      ? props?.personalInformation?.documents
      : []
  );
  const [openReveal, setOpenReveal] = useState(false);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [data, setData] = React.useState({
    associated_documents_attributes: obj,
    first_name: props?.personalInformation?.first_name,
    last_name: props?.personalInformation?.last_name,
    birthdate: props?.personalInformation?.birthdate
      ? props?.personalInformation?.birthdate?.replaceAll("-", "/")
      : null,
    gender: props?.personalInformation?.gender,
    relationship_status: props?.personalInformation?.relationship_status,
    blood_type: props?.personalInformation?.blood_type,
    employment_status: props?.personalInformation?.employment_status,
    sk: props?.personalInformation?.sk ?? null,
    social_security_number:
      props?.personalInformation?.social_security_number_ciphertext ||
      props?.personalInformation?.social_security_number,
  });
  const [sureToAdd, setSureToAdd] = React.useState({ open: false, str: null });

  const handleOpenSureToAdd = (str) => {
    setSureToAdd({ open: true, str: str ?? null });
  };
  const handleCloseSureToAdd = () => {
    if (sureToAdd.str !== null) {
      props?.handleClose();
    }
    setSureToAdd({ open: false, str: null });
  };

  const handleSubmit = () => {
    handleCloseSureToAdd();
    const temp = data?.associated_documents_attributes?.filter(
      (e) => e.document_id !== null
    );
    if (props?.personalInformation?.associated_documents_attributes) {
      setData({ ...data, associated_documents_attributes: temp });
    } else {
      if (temp && temp.length > 0) {
        setData({ ...data, associated_documents_attributes: temp });
      } else {
        let temp2 = delete data["associated_documents_attributes"];
        setData(data);
      }
    }
    setIsLoading(true);
    const info = { ...data, social_security_number: data.sk };
    personalService
      .editPersonalInformation(info)
      .then((response) => {
        const payload = {
          profile: {
            ...props?.profile,
            gender: data.gender,
          },
        };
        props?.setProfile(payload);
        setIsLoading(false);
        props.forceRerender();
        props.handleClose();

        console.log(response.data);
      })
      .catch((err) => {
        errRef.current.scrollIntoView();
        setIsLoading(false);
        setErr("Error occurred while editing personal information");
        if (err.response) {
          console.log(err.response);
        } else {
          console.log("500");
        }
      });
  };
  const documentService = new DocumentService();

  const show = (docId) => {
    const documentId = docId ?? id;
    setSpinner(true);
    documentService
      .show(documentId, password)
      .then((res) => {
        setSpinner(false);
        setLink({
          attachment: res.data.document.attachment,
          contentType: res.data.document.content_type,
        });
        setShowDoc(true);
        setShowPasswordVerification(false);
        setPassword(null);
      })
      .catch((err) => {
        setSpinner(false);
        setPassword(null);
        setAlert({
          type: "Error",
          open: true,
          cancelText: "CANCEL",
          heading:
            err?.response && err?.response?.status === 422
              ? "Unauthorized!"
              : "Error!",
          message:
            err?.response && err?.response?.status === 422
              ? "Wrong password! please try again."
              : "An unknown error occurred, please try later.",
        });
      });
  };

  const handleChange = debounce((event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  }, 300);
  const initialRef = useRef(null);
  React.useEffect(() => {
    initialRef.current = data;
  }, []);

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={() => {
          !disableCheck(initialRef, data)
            ? handleOpenSureToAdd("exit")
            : props?.handleClose();
        }}
        width="35%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        {alert.open && (
          <AlertPopup
            btnText={alert.btnText}
            cancelText={alert.cancelText}
            open={alert.open}
            type={alert.type}
            heading={alert.heading}
            message={alert.message}
            isLoading={false}
            handleClose={() => {
              setAlert({
                open: false,
                btnText: null,
                cancelText: null,
                heading: null,
                message: null,
                type: null,
              });
            }}
            handleClick={() => {
              setAlert({
                open: false,
                btnText: null,
                cancelText: null,
                heading: null,
                message: null,
                type: null,
              });
            }}
          />
        )}
        {spinner && <SpinnerModal open={spinner} msg={"Loading"} />}
        {showDoc && (
          <FileViewer
            contentType={link?.contentType}
            link={link?.attachment}
            open={showDoc}
            handleClose={() => setShowDoc(false)}
          />
        )}
        {showPasswordVerification && (
          <PasswordVerification
            open={showPasswordVerification}
            handleClose={() => {
              setShowPasswordVerification(false);
              setId(null);
            }}
            handleChange={(e) => setPassword(e)}
            handleSubmit={() => {
              show();
            }}
          />
        )}
        <ModalHeader
          closeModal={() => {
            !disableCheck(initialRef, data)
              ? handleOpenSureToAdd("exit")
              : props?.handleClose();
          }}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Edit Personal Information"}
          color={color.white}
        />
        <div className={classes.modalBody} ref={errRef}>
          <div className="w-100 d-flex flex-row mb-4">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              {"Personal > Personal Information > Edit"}
            </Typography>
          </div>
          {err && (
            <div className="w-100 d-flex flex-row mb-4">
              <Typography
                variant="p"
                component="p"
                align="left"
                style={{ color: "red", fontWeight: "normal" }}
              >
                {err}
              </Typography>
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            {editDoc && (
              <EditDocument
                password_protected={currentDocument?.password_protected}
                docs={doc}
                category={currentDocument?.category}
                id={currentDocument.id}
                title={currentDocument.title}
                open={editDoc}
                setDocs={setDoc}
                handleClose={() => setEditDoc(false)}
              />
            )}
            {documentModal && (
              <AddDocument
                setData={setData}
                setDoc={setDoc}
                data={data}
                associated_documents_attributes={
                  props?.personalInformation?.associated_documents_attributes
                    ? true
                    : false
                }
                doc={doc}
                open={documentModal}
                handleClose={() => setDocumentModal(false)}
                closeModal={() => setDocumentModal(false)}
                category={"Personal"}
              />
            )}
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"First Name"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormInput
                id="first_name"
                name="first_name"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                value={data?.first_name}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Last Name"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormInput
                id="last_name"
                name="last_name"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                value={data?.last_name}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Birth Date"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  inputVariant="outlined"
                  format={dateFormat()}
                  id="start_date"
                  placeholder={dateFormat()?.toUpperCase()}
                  value={data?.birthdate}
                  onChange={(date) => {
                    setData({
                      ...data,
                      birthdate: date,
                    });
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Relationship Status"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  id="blood_type"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  value={data?.relationship_status}
                  defaultValue={"Select Relationship Type"}
                  onChange={handleChange}
                  name="relationship_status"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="Select Relationship Type">
                    Select Relationship Status
                  </MenuItem>
                  {relationshipStatus.map((val, key) => {
                    return (
                      <MenuItem value={val} key={key}>
                        {val}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Employment Status"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  id="blood_type"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  value={data?.employment_status}
                  defaultValue={"Select Employment Status"}
                  onChange={handleChange}
                  name="employment_status"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="Select Employment Status">
                    Select Employment Status
                  </MenuItem>
                  <MenuItem value="Employed">Employed</MenuItem>
                  <MenuItem value="Homemaker">Homemaker</MenuItem>
                  <MenuItem value="Retired">Retired</MenuItem>
                  <MenuItem value="Self-Employed">Self-Employed</MenuItem>
                  <MenuItem value="Student">Student</MenuItem>
                  <MenuItem value="Undisclosed">Undisclosed</MenuItem>
                  <MenuItem value="Unemployed">Unemployed</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Blood Type"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  id="blood_type"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  value={data?.blood_type}
                  defaultValue={"Select Blood Type"}
                  onChange={handleChange}
                  name="blood_type"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="Select Blood Type">
                    Select Blood Type
                  </MenuItem>
                  <MenuItem value="O+">O+</MenuItem>
                  <MenuItem value="A+">A+</MenuItem>
                  <MenuItem value="B+">B+</MenuItem>
                  <MenuItem value="AB+">AB+</MenuItem>
                  <MenuItem value="O-">O-</MenuItem>
                  <MenuItem value="A-">A-</MenuItem>
                  <MenuItem value="B-">B-</MenuItem>
                  <MenuItem value="AB-">AB-</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Gender"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="alive"
                  name="gender"
                  value={data?.gender}
                  onChange={handleChange}
                  className="flex-row"
                >
                  <FormControlLabel
                    value={"male"}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Male"
                  />
                  <FormControlLabel
                    value={"female"}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Female"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Social Security Number"}
              </Typography>
              {store.getState().auth.user.masking &&
              data.social_security_number ? (
                <>
                  <CustomButton
                    onClick={() => {
                      setOpenReveal(true);
                      // props.handleClose()
                    }}
                    textAlign="center"
                    display={"block"}
                    background="transparent"
                    hoverBg="#0042a8"
                    border="1px solid #0042a8"
                    borderRadius="8px"
                    color="#0042a8"
                    btnText={"Reveal"}
                  />
                </>
              ) : null}
              {openReveal && (
                <RevealPassword
                  closeParent={props.handleClose}
                  open={openReveal}
                  handleClose={() => setOpenReveal(false)}
                  subcategory_name={"PersonalInformation"}
                  password={data?.social_security_number}
                  id={props?.personalInformation?.id}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {store.getState().auth.user.masking ? (
                <>
                  {props?.personalInformation?.sk ? (
                    <FormInput
                      id="txtPassword"
                      name="sk"
                      onChange={handleChange}
                      label=""
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      type="text"
                      required={true}
                      placeholder={"Social Security Number"}
                      value={data?.sk}
                    />
                  ) : (
                    <FormInput
                      ref={ss}
                      name="sk"
                      onChange={handleChange}
                      label=""
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      type="text"
                      required={true}
                      placeholder={"Social Security Number"}
                      value={data?.sk}
                    />
                  )}
                </>
              ) : (
                <FormInput
                  id="social_security_number"
                  name="sk"
                  onChange={handleChange}
                  label=""
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  value={data?.sk}
                />
              )}
            </Grid>
            {doc.length > 0 && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Document"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                  {doc.map((e, k) => (
                    <>
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: color.themeColorNew }}
                        key={k}
                      >
                        {e?.title}
                      </Typography>
                      <CustomButton
                        btnText={"Remove"}
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          let temp = data.associated_documents_attributes?.map(
                            (d) => {
                              if (d.document_id === e.id) {
                                return {
                                  ...d,
                                  _destroy: 1,
                                };
                              } else {
                                return d;
                              }
                            }
                          );
                          let newDoc = doc?.filter((d) => d.id !== e.id);
                          setDoc(newDoc);
                          setData({
                            ...data,
                            associated_documents_attributes: temp,
                          });
                        }}
                      />

                      <CustomButton
                        btnText={"Edit"}
                        className="ms-2"
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          setCurrentDocument(e);
                          setEditDoc(true);
                        }}
                      />

                      <CustomButton
                        btnText={"View"}
                        className="ms-2"
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          if (!props?.personalInformation?.documents) {
                            show(e?.id);
                          } else {
                            if (e?.attachment) {
                              setLink({
                                attachment: _default.BASE_URL + e.attachment,
                                contentType: e.content_type,
                              });
                              setShowDoc(true);
                            } else {
                              setId(e?.id);
                              setShowPasswordVerification(true);
                            }
                          }
                        }}
                      />
                    </>
                  ))}
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              <CustomButton
                btnText="Add Document"
                className="mb-sm-2"
                textAlign="center"
                display={"block"}
                padding="8px 25px"
                background="#0042a8"
                hoverBg="#0042a8"
                border="0"
                borderRadius="8px"
                color="#fff"
                marginRight={"10px"}
                onClick={() => setDocumentModal(true)}
              />

              {!isLoading ? (
                <CustomButton
                  type="submit"
                  btnText="Save"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  disabled={disableCheck(initialRef, data)}
                  borderRadius="8px"
                  color="#fff"
                  onClick={() => handleOpenSureToAdd(null)}
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
      <SureToAdd
        open={sureToAdd.open}
        handleClose={handleCloseSureToAdd}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
    setProfile: (profile) => {
      dispatch(setProfile(profile));
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
    profile: state.profile?.profileInfo?.profile,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPersonalInformation);
