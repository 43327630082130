const color = {
    themeColorNew: '#060d5c',
    themeColorNewLight: '#0042a8',
    themeYellow: '#FFB722',
    themeOrange: '#E75D33',
    themeSeaBlue: '#00BED6',
    themeDarkGry: '#051C2C',
    themeRed: '#D52900',
    themeGreen: '#4DB500',
    LightGrey: '#EAEAEA',
    black: '#000',
    white: '#fff',
    themeblue:"#0042a8"
}
export default color