import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close, EditOutlined, MoreVert } from '@material-ui/icons';
import { Avatar } from '@material-ui/core';
// import AvatarGroup from '@mui/material/AvatarGroup';
import color from '../../../assets/css/color';
import man from "../../../assets/img/man.png";
import _default from "../../../config/default";
import CustomModal from '../../../components/Modal/Modal.js';
import ModalHeader from '../../../components/Modal/ModalHeader.js';
import collaborators from '../../../assets/img/collaboratorsHistory.png'
import pdfImage from '../../../assets/img/pdfImage.png'
import backMe from '../../../assets/img/back.png'
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import { Link } from 'react-router-dom';
import { PersonalService } from '../../../services/personal.service.js';


const useStyles = makeStyles(styles);
// export default function Collaborators(props) {
  const Collaborators = React.memo((props) => {
  console.log("collaborator1", props)
  const extractedData = useMemo(() => ({
  id: props?.data?.id,
  subcategory: props?.data?.subcategory,
  // subcategory:"AlternateIdentification",
  collaborators: props?.data?.collaborators?.collaborators?.map(collab => ( collab.id)) || []
  // collaborators:[1,2]
  }), [props.data]);

  console.log("extractedData1",extractedData);
    const classes = useStyles();

    const personalService = new PersonalService()

    // State for storing collaborator data
    const [collaboratorsData, setCollaboratorsData] = useState({
      collaborators_actions: [],
    })

    useEffect(() => {
      // if (!extractedData.id) return;
      if (!extractedData.id || extractedData.collaborators.length === 0) return;
      personalService
        .collaboratorGet(extractedData)
        .then((response) => {
          setCollaboratorsData(response.data)
          console.log("collaborator info", response)
        })
        .catch((error) => {
          console.error("Error fetching collaborators:", error)
        })
    }, [extractedData])


    return (
      <>
        <CustomModal
          visible={props.open}
          onClose={props?.handleCloseCollaborators}
          width="35%"
          inset="15px!important"
        >
          <div className='' style={{display:"flex", flexDirection:"column", maxHeight:"95vh", overflow:"hidden"}}>
          <ModalHeader
            onClick={props.closeModal}
            icon={
              <img
                onClick={props.closeModal}
                style={{ cursor: "pointer" }}
                src={backMe}
                className="me-3"
              />
              // <Close
              //
              //     style={{
              //         color: color.white,
              //         marginRight: "20px",
              //         fontSize: "36px",
              //         cursor: "pointer",
              //     }}
              // />
            }
            // heading={"Rebecca Mayfield"}
            heading={props?.data?.id_type}
            color={color.white}
          />
          <div className={classes.modalBody} style={{flexGrow: 1,display:"flex", flexDirection:"column", overflow:"hidden"}}>
            <div className="w-100 d-flex flex-row mb-4">
              <Typography
                variant="h6"
                component="h3"
                align="left"
                style={{ color: color.themeColorNew }}
              >
                {"History"}
              </Typography>
            </div>
            <div className="d-flex flex-column history_main" style={{flexGrow:1, overflowY: "auto"}} >
              {collaboratorsData?.actions?.length === 0 ? (
                <p>No Collaborators actions available</p>
              ) : (
                collaboratorsData?.actions?.map((action, index) => (
                  <div
                    key={index}
                    className="d-flex flex-row align-items-start pb-3 position-relative"
                  >
                    <div
                      className="position-absolute h-100"
                      style={{
                        background: color.themeblue,
                        width: "1px",
                        left: "28.5px",
                        zIndex: "0",
                      }}
                    ></div>
                    <img
                      src={
                        action.profile_image ?
                        _default.BASE_URL + action.profile_image : man
                      }
                      className="me-3"
                      style={{ zIndex: "100",position: "relative",border:"2px solid #0042a8", borderRadius:"100%", width:"60px", background:"white"  }}
                    />
                    <Typography
                      // variant="h3"
                      component="h3"
                      align="left"
                      className="me-2"
                      style={{fontSize:"1rem"}}
                    >
                      <span style={{ color: color.themeblue }}>
                        {action.performer_name}{" "}
                      </span>
                      {`${action?.action_performed} the ${action?.record_name}`}
                      <Typography
                        variant="p"
                        component="p"
                        align="left"
                        className="me-2"
                      >
                        {`${action.date} at ${action.time}` }
                      </Typography>
                    </Typography>
                  </div>
                ))
              )}
            </div>
          </div>
          </div>
        </CustomModal>
      </>
    )
})

export default Collaborators;