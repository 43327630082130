import "date-fns";
import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  useTheme,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { dateFormat } from "../../../Utils/dateFormate.js";

import AddAutoInsurance from "../AddAutoInsurance/AddAutoInsurance";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import { VehicleService } from "../../../services/vehicle.service.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import AddDocument from "../AddDocument/AddDocument";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { getDateIgnoreTimezone } from "../../../Utils/dateHelper.js";
import { InsuranceService } from "../../../services/insurance.service.js";

const vehicleType = [
  "Automobile",
  "Boat",
  "Motorcycle",
  "Snowmobile",
  "Bicycle",
  "Commerical Vehicle",
  "Other",
];

const year = [
  "2022",
  "2021",
  "2020",
  "2019",
  "2018",
  "2017",
  "2016",
  "2015",
  "2014",
  "2013",
  "2012",
  "2011",
  "2010",
  "2009",
  "2008",
  "2007",
  "2006",
  "2005",
  "2004",
  "2003",
  "2002",
  "2000",
  "1999",
  "1998",
  "1997",
  "1996",
  "1995",
  "1994",
  "1993",
  "1992",
  "1991",
  "1990",
  "1989",
  "1988",
  "1987",
  "1986",
  "1985",
  "1984",
  "1983",
  "1982",
  "1981",
  "1980",
  "1979",
  "1978",
  "1977",
  "1976",
  "1975",
  "1974",
  "1973",
  "1972",
  "1971",
  "1970",
  "1969",
  "1968",
  "1967",
  "1966",
  "1965",
  "1964",
  "1963",
  "1962",
  "1961",
  "1960",
  "1959",
  "1959",
  "1958",
  "1957",
  "1956",
  "1955",
  "1954",
  "1953",
  "1952",
  "1951",
  "1950",
  "1949",
  "1948",
  "1947",
  "1946",
  "1945",
  "1944",
  "1943",
  "1942",
  "1941",
  "1940",
  "1939",
  "1938",
  "1937",
  "1936",
  "1935",
  "1934",
  "1933",
  "1932",
  "1931",
  "1930",
  "1929",
  "1928",
  "1927",
  "1926",
  "1925",
  "1924",
  "1923",
  "1922",
  "1921",
  "1920",
  "1919",
  "1918",
  "1917",
  "1916",
  "1915",
  "1914",
  "1913",
  "1912",
  "1911",
  "1910",
  "1909",
  "1908",
];

const registrationState = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Federated States of Micronesia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Marshall Islands",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palau",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Island",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles(styles);

function AddVehicle(props) {
  const classes = useStyles();
  const errRef = useRef(null);
  const insuranceService = new InsuranceService();
  const vehicleService = new VehicleService();
  const [openAddNew, setOpenAddNew] = React.useState(false);
  const [err, setErr] = React.useState([]);
  const [doc, setDoc] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [autoInsurances, setAutoInsurances] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [other, setOther] = React.useState(false);
  const [data, setData] = React.useState({
    vehicle_type: "",
    make: "",
    model: "",
    year: "",
    cash: true,
    description: "",
    title_holder: "",
    monthly_payment: "",
    loan_account_number: "",
    auto_insurance_id: null,
    lease_holder: "",
    lease_account_number: "",
    lease_base_payment: "",
    premium_frequency: "",
    lease_tax_payment: "",
    lease_expiration_date: null,
    lease_termination_fee: "",
    balance: "",
    value: "",
    owner: "",
    ownership_description: "",
    tag_number: "",
    registration_county: "",
    registration_state: "",
    registration_expiration: null,
    color: "",
    vin_number: "",
    mileage: "",
    date_of_mileage: null,
    notes: "",
    attachments: "",
    associated_documents_attributes: [],
  });

  const [documentModal, setDocumentModal] = React.useState(false);

  const handleDocumentModal = () => {
    setDocumentModal(true);
  };

  const handleSubmit = () => {
    let formData = new FormData();
    formData.append("vehicle_type", data.vehicle_type);
    formData.append("make", data.make);
    formData.append("model", data.model);
    formData.append("year", data.year);
    formData.append("cash", data.cash);
    formData.append("description", data.description);
    formData.append("title_holder", data.title_holder);
    data.auto_insurance_id &&
      formData.append("auto_insurance_id", data.auto_insurance_id);
    formData.append("monthly_payment", data.monthly_payment);
    formData.append("loan_account_number", data.loan_account_number);
    formData.append("lease_holder", data.lease_holder);
    formData.append("lease_account_number", data.lease_account_number);
    formData.append("lease_base_payment", data.lease_base_payment);
    formData.append("premium_frequency", data.premium_frequency);
    formData.append("lease_tax_payment", data.lease_tax_payment);
    data.lease_expiration_date &&
      formData.append(
        "lease_expiration_date",
        getDateIgnoreTimezone(data.lease_expiration_date)
          .toISOString()
          .slice(0, 10)
      );
    formData.append("lease_termination_fee", data.lease_termination_fee);
    formData.append("balance", data.balance);
    formData.append("value", data.value);
    formData.append("owner", data.owner);
    formData.append("ownership_description", data.ownership_description);
    formData.append("tag_number", data.tag_number);
    formData.append("registration_county", data.registration_county);
    formData.append("registration_state", data.registration_state);
    formData.append("registration_expiration", data.registration_expiration);
    formData.append("color", data.color);
    formData.append("vin_number", data.vin_number);
    formData.append("mileage", data.mileage);
    data.date_of_mileage &&
      formData.append(
        "date_of_mileage",
        getDateIgnoreTimezone(data.date_of_mileage).toISOString().slice(0, 10)
      );
    formData.append("notes", data.notes);

    if (data.associated_documents_attributes?.length > 0) {
      for (let i = 0; i < data.associated_documents_attributes?.length; i++) {
        formData.append(
          "associated_documents_attributes[][document_id]",
          data.associated_documents_attributes[i]?.document_id ||
            data.associated_documents_attributes[i]?.id
        );
      }
    }
    setIsLoading(true);
    vehicleService
      .createVehicle(formData)
      .then((response) => {
        setIsLoading(false);
        props.forceRerender();
        props.handleClose();

        console.log(response.data);
      })
      .catch((err) => {
        errRef.current.scrollIntoView();
        setIsLoading(false);
        if (err.response && err.response.data.message) {
          if (err.response?.data?.message === "Please enter a valid date") {
            setErr([err.response?.data?.message]);
          } else {
            let array = [];
            Object.keys(err.response.data.message).forEach((key) =>
              array.push(`${key} ${err.response.data.message[key]}`)
            );
            setErr(array);
          }
        } else {
          setErr(["Internal Server Error"]);
        }
      });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChange = (event) => {
    if (event.target.name === "vehicle_type1") {
      if (event.target.value !== "Other") {
        setOther(false);
        setData({ ...data, vehicle_type: event.target.value });
      } else {
        setOther(true);
      }
    } else {
      setData({ ...data, [event.target.name]: event.target.value });
    }
  };

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChangeCountry = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  React.useEffect(() => {
    insuranceService
      .autoInsuranceIndex()
      .then((res) => {
        if (res.data && res.data?.auto_insurances) {
          let array = res.data?.auto_insurances;
          setAutoInsurances(array);
        }
      })
      .catch((e) => {
        setAutoInsurances([]);
      });
  }, [openAddNew]);

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%"
        inset="15px!important"
      >
        {openAddNew && (
          <AddAutoInsurance
            open={openAddNew}
            handleClose={() => setOpenAddNew(false)}
            closeModal={() => setOpenAddNew(false)}
          />
        )}
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Add New Vehicle"}
          color={color.white}
        />
        <div className={classes.modalBody} ref={errRef}>
          <div className="w-100 d-flex flex-row mb-4">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              {"Vehicle > Vehicle > New"}
            </Typography>
          </div>
          {err?.length > 0 && (
            <div className="w-100 d-flex flex-row mb-4">
              {err?.map((val, key) => {
                return (
                  <Typography
                    key={key}
                    variant="p"
                    component="p"
                    align="left"
                    style={{
                      color: "red",
                      fontWeight: "normal",
                      marginBottom: "2px",
                    }}
                  >
                    {val.charAt(0).toUpperCase() + val.slice(1)}
                  </Typography>
                );
              })}
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Vehicle Type *"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  onChange={handleChange}
                  name="vehicle_type1"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  <MenuItem disabled>Select Vehicle Type</MenuItem>
                  {vehicleType?.map((state, index) => {
                    return (
                      <MenuItem
                        value={state}
                        key={index}
                        style={getStyles(name, personName, theme)}
                      >
                        {state}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {other ? (
                <FormInput
                  name="vehicle_type"
                  onChange={handleChange}
                  label=""
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                />
              ) : null}
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Make *"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="make"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Make"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Model *"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="model"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Model"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Year"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  // value={data?.premium_frequency}
                  onChange={handleChange}
                  name="year"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  <MenuItem disabled>Select Year</MenuItem>
                  {year?.map((state, index) => {
                    return (
                      <MenuItem
                        value={state}
                        key={index}
                        style={getStyles(name, personName, theme)}
                      >
                        {state}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Auto Insurance"}
              </Typography>
              <CustomButton
                btnText={"Add Auto Insurance"}
                textAlign="center"
                display={"block"}
                background="transparent"
                hoverBg="#0042a8"
                border="1px solid #0042a8"
                borderRadius="8px"
                color="#0042a8"
                onClick={() => setOpenAddNew(true)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  onChange={handleChange}
                  name="auto_insurance_id"
                  defaultValue={"Select Auto Insurance"}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  <MenuItem value={"Select Auto Insurance"} disabled>
                    Select Auto Insurance
                  </MenuItem>
                  {autoInsurances?.map((state, index) => {
                    return (
                      <MenuItem
                        value={state.id}
                        key={index}
                        style={getStyles(name, personName, theme)}
                      >
                        {state?.insurance_provider}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Description"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="description"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Description"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Title Holder"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="title_holder"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Title Holder"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Cash or Loan"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="cash"
                  name="cash"
                  value={data.cash}
                  onChange={(e) => {
                    if (e.target.value === "false") {
                      setData({ ...data, cash: false });
                    } else {
                      setData({ ...data, cash: true });
                    }
                  }}
                  className="flex-row"
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Cash"
                  />
                  <FormControlLabel
                    value={false}
                    control={
                      <Radio style={{ color: "#ffb722", marginLeft: "7px" }} />
                    }
                    label="Loan"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {!data.cash && (
              <>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Monthly Payment"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormInput
                    name="monthly_payment"
                    onChange={handleChange}
                    label=""
                    variant="outlined"
                    type="number"
                    fullWidth={true}
                    margin="dense"
                    required={true}
                    placeholder={"Mothly Payment"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Loan Account Number"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormInput
                    name="loan_account_number"
                    onChange={handleChange}
                    label=""
                    variant="outlined"
                    fullWidth={true}
                    margin="dense"
                    required={true}
                    placeholder={"Loan Account Number"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Lease Holder"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormInput
                    name="lease_holder"
                    onChange={handleChange}
                    label=""
                    variant="outlined"
                    fullWidth={true}
                    margin="dense"
                    required={true}
                    placeholder={"Lease Holder"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Lease Account Number"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormInput
                    name="lease_account_number"
                    onChange={handleChange}
                    label=""
                    variant="outlined"
                    fullWidth={true}
                    margin="dense"
                    required={true}
                    placeholder={"Lease Account Number"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Lease Base Payment"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormInput
                    name="lease_base_payment"
                    onChange={handleChange}
                    label=""
                    variant="outlined"
                    type="number"
                    fullWidth={true}
                    margin="dense"
                    required={true}
                    placeholder={"Lease Base Payment"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Lease Tax Payment"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormInput
                    name="lease_tax_payment"
                    onChange={handleChange}
                    label=""
                    variant="outlined"
                    type="number"
                    fullWidth={true}
                    margin="dense"
                    required={true}
                    placeholder={"Lease Tax Payment"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Lease Expiration Date"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth={true}
                      margin="dense"
                      required={true}
                      inputVariant="outlined"
                      format={dateFormat()}
                      id="start_date"
                      placeholder={dateFormat()?.toUpperCase()}
                      value={data?.lease_expiration_date}
                      onChange={(date) => {
                        setData({
                          ...data,
                          lease_expiration_date: getDateIgnoreTimezone(date)
                            .toISOString()
                            .slice(0, 10),
                        });
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Lease Termination Fee"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormInput
                    name="lease_termination_fee"
                    onChange={handleChange}
                    label=""
                    variant="outlined"
                    type="number"
                    fullWidth={true}
                    margin="dense"
                    required={true}
                    placeholder={"Lease Termination Fee"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Balance"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormInput
                    name="balance"
                    onChange={handleChange}
                    label=""
                    variant="outlined"
                    type="number"
                    fullWidth={true}
                    margin="dense"
                    required={true}
                    placeholder={"Balance"}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Value"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="value"
                onChange={handleChange}
                label=""
                variant="outlined"
                type="number"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Value"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Owner"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="owner"
                  name="owner"
                  value={data?.owner}
                  onChange={handleChange}
                  className="flex-row"
                >
                  <FormControlLabel
                    value="self"
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Self"
                  />
                  <FormControlLabel
                    value="joint"
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Joint"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Ownership Description"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="ownership_description"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Ownership Description"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"TagData Number"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="tag_number"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"TagData Number"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Registration County"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="registration_county"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Registration County"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Registration State"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  // value={data?.premium_frequency}
                  onChange={handleChange}
                  name="registration_state"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  <MenuItem disabled>Select Registration State</MenuItem>
                  {registrationState?.map((state, index) => {
                    return (
                      <MenuItem
                        value={state}
                        key={index}
                        style={getStyles(name, personName, theme)}
                      >
                        {state}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Registration Expiration"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  inputVariant="outlined"
                  format={dateFormat()}
                  id="start_date"
                  placeholder={dateFormat()?.toUpperCase()}
                  value={data?.registration_expiration}
                  onChange={(date) => {
                    setData({
                      ...data,
                      registration_expiration: date,
                    });
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Color"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="color"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Color"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"VIN Number"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="vin_number"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"VIN Number"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Mileage"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="mileage"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                type="number"
                margin="dense"
                required={true}
                placeholder={"Mileage"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Date Of Mileage"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  inputVariant="outlined"
                  format={dateFormat()}
                  id="start_date"
                  placeholder={dateFormat()?.toUpperCase()}
                  value={data?.date_of_mileage}
                  onChange={(date) => {
                    setData({
                      ...data,
                      date_of_mileage: date,
                    });
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Notes"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                id="class"
                name="notes"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Notes"}
              />
              {documentModal && (
                <AddDocument
                  setData={setData}
                  setDoc={setDoc}
                  associated_documents_attributes={false}
                  doc={doc}
                  data={data}
                  open={documentModal}
                  handleClose={() => setDocumentModal(false)}
                  closeModal={() => setDocumentModal(false)}
                  category={"Vehicle"}
                />
              )}
            </Grid>
            {doc.length > 0 && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Document"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                  {doc.map((e, k) => (
                    <>
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: color.themeColorNew }}
                        key={k}
                      >
                        {e?.title}
                      </Typography>
                      <CustomButton
                        btnText={"Remove"}
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          let newDoc = doc?.filter((d) => d.id !== e.id);
                          setDoc(newDoc);
                          setData({
                            ...data,
                            associated_documents_attributes: newDoc,
                          });
                        }}
                      />
                    </>
                  ))}
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              <CustomButton
                btnText="Add Document"
                className="mb-sm-2"
                textAlign="center"
                display={"block"}
                padding="8px 25px"
                background="#0042a8"
                hoverBg="#0042a8"
                border="0"
                borderRadius="8px"
                color="#fff"
                marginRight={"10px"}
                onClick={handleDocumentModal}
              />
              <a
                ref={errRef}
                href={"#err"}
                style={{
                  display: "none",
                }}
              >
                click
              </a>
              {!isLoading ? (
                <CustomButton
                  type="submit"
                  btnText="Save"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  onClick={handleSubmit}
                  disabled={
                    data.vehicle_type === "" ||
                    !data.vehicle_type?.trim() ||
                    data.make === "" ||
                    !data.make?.trim() ||
                    data.model === "" ||
                    !data.model?.trim()
                      ? true
                      : false
                  }
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddVehicle);
